import React, { useEffect } from 'react'
import {
  AlertWarning,
  ButtonDanger,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@papertrail/styleguide'
import { Filter } from '../../types'
import { useApiDelete } from '@papertrail/web3-utils'
import { useTranslation } from 'react-i18next'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  onCancel: () => void
  onComplete: () => void
}

export default function DeleteDialogue(props: Props) {
  const { show, onCancel, onComplete } = props

  const [deleteState, deleteFilter] = useApiDelete<any>(
    `/accounts/${props.accountid}/filters/${props.filter.id}`,
    (data) => data
  )

  useEffect(() => {
    if (deleteState.isLoaded) {
      onComplete()
    }
  }, [deleteState, onComplete])

  const { t } = useTranslation(['tools', 'global'])

  return (
    <Dialog open={show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t('deleteFilter')}</DialogTitle>
      <DialogContent>
        {deleteState.isError && <AlertWarning>{t('errorDeleteFilter')}</AlertWarning>}
        <DialogContentText id="alert-dialog-description">{t('confirmDeleteFilter')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={onCancel}>{t('global:cancel')}</ButtonSecondary>
        <ButtonDanger data-testid="confirm_delete" onClick={() => deleteFilter({})} loading={deleteState.isLoading}>
          {t('global:confirmDelete')}
        </ButtonDanger>
      </DialogActions>
    </Dialog>
  )
}
