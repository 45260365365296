import React, { useEffect, useState } from 'react'
import { Filter, State, Rule, Tag, Blueprint, Field } from '../../types'
import { useApiPost } from '@papertrail/web3-utils'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import {
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  FormFieldText,
  Formik,
  FormFieldSelectFormik,
  FormFieldCheckbox,
  Box,
  FlexRow,
  Divider,
  Chip,
  AlertWarning,
  IconButton,
  CloseIcon,
  WarningIcon,
  CheckIcon,
  ErrorIcon,
  InfoIcon,
  ToolTip
} from '@papertrail/styleguide'
import InspectionRule from './InspectionRule'
import StateRule from './StateRule'
import TagRule from './TagRule'
import FieldFilter from './FieldFilter'
import ComparisonFilter from './ComparisonFilter'
import ConfirmDialog from './ConfirmDialog'
import DeleteRuleDialogue from './DeleteRuleDialog'
import TaskRule from './TaskRule'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  rule?: Rule
  blueprint: Blueprint
  field: Field[]
  allStates: State[]
  allTags: Tag[]
  onCancel: () => void
  onComplete: () => void
}

export default function AddNewFilter(props: Props) {
  const { field, allStates, allTags } = props
  const [editState, editFilter] = useApiPost<any>(`/accounts/${props.accountid}/filters`, (data) => data)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [localRules, setLocalRules] = useState<Rule[]>([])
  const [starred, setStarred] = useState(true)
  const [archived, setArchived] = useState(false)
  const [showRuleComponent, setShowRuleComponent] = useState(false)
  const [error, setError] = useState('')
  const [showExitConfirm, setShowExitConfirm] = useState<boolean>(false)
  const [formChanged, setFormChanged] = useState(false)
  const [isRuleEdit, setIsRuleEdit] = useState(false)
  const [savedRule, setSavedRule] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [deleteRule, setDeleteRule] = useState<string>('')
  const [tooltipTitle, setTooltipTitle] = useState<string>('')

  useEffect(() => {
    if (editState.isLoaded) {
      props.onComplete()
      setFormChanged(false)
      setSelectedFilter('')
      setSavedRule(false) // Reset savedRule to false
      setIsRuleEdit(false) // Reset isRuleEdit to false
      setLocalRules([])
    }
  }, [editState, savedRule])

  const { t } = useTranslation(['tools', 'global'])

  const initialValues = {
    filterName: '',
    profile: 'default',
    showCount: false,
    starred: true,
    filterType: null,
    rules: [],
    archived: false
  }

  const validationSchema = yup.object({
    filterName: yup.string().required('Filter name required'),
    profile: yup.string().required('Filter profile required'),
    rules: yup.array().required('Filter rules required'),
    filterType: yup.string().required('Rule must be added')
  })

  function closeDialog() {
    if (formChanged) {
      // Show confirm dialog before canceling
      setShowExitConfirm(true)
    } else {
      cancelOption()
      setSelectedFilter('')
      setShowRuleComponent(false)
    }
  }

  function cancelOption() {
    setShowExitConfirm(false)
    props.onCancel()
    setSelectedFilter('')
    setShowRuleComponent(false)
    setFormChanged(false)
  }

  function closeRule() {
    setShowRuleComponent(false)
    setIsRuleEdit(false)
  }

  function setFilter(event) {
    if (event.target && event.target.value && event.target.value !== selectedFilter) {
      const chosenFilter = event.target.value
      setTooltip(event.target.value)
      setSelectedFilter(chosenFilter)
      setShowRuleComponent(true)
      setFormChanged(true)
      setIsRuleEdit(true)
    }
  }

  const setTooltip = (title: string) => {
    switch (title) {
      case 'inspection':
        setTooltipTitle('Create a filter based on whether records are due or overdue an inspection')
        break
      case 'task_active':
        setTooltipTitle('Create a filter based on whether records have open tasks attached to them')
        break
      case 'state':
        setTooltipTitle('Create a filter based on the inspection state of a record')
        break
      case 'tags':
        setTooltipTitle('Create a filter based on a specific tag')
        break
      case 'field':
        setTooltipTitle('Create a filter based on comparing two record fields')
        break
      case 'field_comparison':
        setTooltipTitle(
          'Create a filter based on comparing a record field to a given input eg a date, number, name etc'
        )
        break
      default:
        setTooltipTitle('')
        break
    }
  }

  const closeNewRule = (rule, formikProps?) => {
    const newRules = localRules || []
    newRules.push(rule)
    setLocalRules(newRules)
    setSelectedFilter('')
    setIsRuleEdit(false)
    resetDropdowns(formikProps)
  }

  function openDeleteDialog(id: string) {
    setDeleteRule(id)
    setShowDelete(true)
  }
  function onDelete() {
    setShowDelete(false)
  }

  function onDeleteRule(ruleId: string) {
    const updatedRules = localRules.filter((rule) => rule.id !== ruleId)
    setLocalRules(updatedRules)
    setDeleteRule('')
    setShowDelete(false)
  }

  // Render component based on selected filter
  function renderFilterComponent(formikProps) {
    if (showRuleComponent) {
      switch (selectedFilter) {
        case 'inspection':
          return (
            <InspectionRule
              show={true}
              accountid={''}
              filter={props.filter}
              onCancel={closeRule}
              onComplete={(values) => closeNewRule(values, formikProps)}
              rule={{
                id: '',
                key: '',
                value: '',
                active: false,
                label: '',
                sentiment: '',
                type: 'rule',
                operator: ''
              }}
            />
          )
        case 'state':
          return (
            <StateRule
              accountid={''}
              filter={props.filter}
              onCancel={closeRule}
              onComplete={(values) => closeNewRule(values, formikProps)}
              allStates={allStates}
              show={false}
              rule={{
                id: '',
                key: '',
                value: '',
                active: false,
                label: '',
                sentiment: '',
                type: 'rule',
                operator: ''
              }}
            />
          )
        case 'tags':
          return (
            <TagRule
              allTags={allTags}
              onCancel={closeRule}
              onComplete={(values) => closeNewRule(values, formikProps)}
              show={false}
              accountid={''}
              filter={props.filter}
              rule={{
                id: '',
                key: '',
                value: '',
                active: false,
                label: '',
                sentiment: '',
                type: 'rule',
                operator: ''
              }}
            />
          )

        case 'field':
          return (
            <FieldFilter
              accountid={''}
              show={false}
              filter={props.filter}
              blueprint={props.blueprint}
              field={field}
              allStates={allStates}
              onCancel={closeRule}
              onComplete={(values) => closeNewRule(values, formikProps)}
              rule={{
                id: '',
                key: '',
                value: '',
                active: false,
                label: '',
                sentiment: '',
                type: 'rule',
                operator: ''
              }}
            />
          )

        case 'field_comparison':
          return (
            <ComparisonFilter
              accountid={''}
              filter={props.filter}
              blueprint={props.blueprint}
              field={field}
              allStates={allStates}
              show={false}
              onCancel={closeRule}
              onComplete={(values) => closeNewRule(values, formikProps)}
              rule={{
                id: '',
                key: '',
                value: '',
                active: false,
                label: '',
                sentiment: '',
                operator: '',
                type: 'rule'
              }}
            />
          )
        case 'task_active':
          return (
            <TaskRule
              show={true}
              accountid={''}
              filter={props.filter}
              onCancel={closeRule}
              onComplete={(values) => closeNewRule(values, formikProps)}
              rule={{
                id: '',
                key: '',
                value: '',
                active: false,
                label: '',
                sentiment: '',
                type: 'rule',
                operator: ''
              }}
            />
          )
        default:
          return null
      }
    }
  }

  const submitForm = (values) => {
    const hasValidRules =
      !localRules ||
      localRules.length === 0 ||
      localRules.every((rule) => rule.key === 'starred' || rule.key === 'include')
    if (hasValidRules) {
      setError('Please add a filter rule.')
      return
    } else {
      setError('')
    }

    const newRules = localRules.map((r: Rule) => {
      if (r.key === 'starred' && values.starred === 'true') {
        return {
          id: r.id,
          key: r.key,
          value: 'true'
        }
      } else if (r.key === 'starred' && values.starred === 'false') {
        return null // Remove the starred rule if values.starred is 'false'
      } else if (r.key === 'include' && r.value === 'archived') {
        if (values.archived) {
          return r // Keep the rule if it's selected (checked)
        } else {
          return null // Skip the rule if it's deselected (unchecked)
        }
      } else {
        return {
          id: r.id,
          key: r.key,
          value: r.value
        }
      }
    })

    const filteredRules = newRules.filter((r) => r !== null)
    const hasStarredRule = filteredRules.some((r) => r.key === 'starred')
    const hasArchivedRule = filteredRules.some((r) => r.key === 'include' && r.value === 'archived')

    if (!hasStarredRule && starred === true) {
      filteredRules.push({
        id: '',
        key: 'starred',
        value: 'true'
      })
    }
    if (!hasArchivedRule && values.archived) {
      filteredRules.push({
        id: '',
        key: 'include',
        value: 'archived'
      })
    }

    const newMeta = [
      {
        // id: '',
        key: 'show_count',
        value: true
      }
    ]

    if (filteredRules.length > 0) {
      editFilter({
        name: values.filterName,
        profile: values.profile,
        rules: filteredRules,
        meta: newMeta
      })
    }
  }

  function handleStarredCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    setStarred(event.target.checked)
  }

  function handleArchivedCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    setArchived(event.target.checked)
  }

  const resetDropdowns = (formikProps) => {
    formikProps.setFieldValue('filterType', '')
    setTooltip('')
  }

  const getChipValue = (rule) => {
    return rule.key
  }

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
        onReset={() => setFormChanged(false)}>
        {(formikProps) => (
          <Form>
            <FlexRow justifyContent="space-between">
              <DialogTitle id="alert-dialog-title">{'Add a new filter'}</DialogTitle>
              <IconButton id="basic-button" size="large" onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </FlexRow>
            <DialogContent sx={{ width: '550px' }}>
              {editState.isError && (
                <Box paddingBottom={1}>
                  <AlertWarning>{'Sorry, there was a problem saving the filter'}</AlertWarning>
                </Box>
              )}
              <DialogContentText id="alert-dialog-description">
                {error && (
                  <Box paddingBottom={1}>
                    <AlertWarning>{error}</AlertWarning>
                  </Box>
                )}

                <FormFieldText label={'Filter Name'} placeholder={'Filter Name'} name="filterName" />
                <FormFieldSelectFormik
                  options={[
                    {
                      value: 'default',
                      label: 'Default',
                      icon: <CheckIcon />,
                      borderBottom: true,
                      borderTop: true,
                      borderBottomColor: '#e4e7eb',
                      borderTopColor: '#e4e7eb'
                    },
                    {
                      value: 'warn',
                      label: 'Warning',
                      icon: <WarningIcon />,
                      backgroundColour: '#fde4b3',
                      fontColour: '#ad7302',
                      borderBottom: true,
                      borderBottomColor: '#e4e7eb'
                    },
                    {
                      value: 'alert',
                      label: 'Alert',
                      icon: <ErrorIcon />,
                      backgroundColour: '#fdebec',
                      fontColour: '#d41927',
                      borderBottom: true,
                      borderBottomColor: '#e4e7eb'
                    }
                  ]}
                  name="profile"
                  label="Choose filter colour"
                  placeholder=""
                  value={formikProps.values.profile}
                  onChange={(event) => {
                    formikProps.handleChange(event)
                  }}
                />
                <FormFieldCheckbox
                  name={'starred'}
                  label={'Starred only'}
                  checked={starred}
                  onChange={handleStarredCheckbox}
                  tooltip={'Check this box if you want to search though starred folders'}
                />
                <FormFieldCheckbox
                  name={'archived'}
                  label={'Include archived'}
                  checked={archived}
                  onChange={handleArchivedCheckbox}
                  tooltip={'Check this box to include archived records in the filter'}
                />
                <Box sx={{ color: '#343741', fontWeight: '600', paddingBottom: '20px' }}>Filter Types</Box>
                <Divider sx={{ position: 'absolute', left: '0', right: '0' }}></Divider>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  flexWrap={'wrap'}
                  paddingBottom={2}
                  sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
                  {localRules &&
                    localRules.map((rule: Rule) => (
                      <>
                        <Box padding={'8px'} key={rule.id}>
                          {rule.label !== 'Starred only' && (
                            <Chip
                              id={rule.key}
                              key={getChipValue(rule)}
                              label={rule.label}
                              variant="outlined"
                              onDelete={() => openDeleteDialog(rule.id)}
                              data-testid="Chip"></Chip>
                          )}
                        </Box>
                      </>
                    ))}
                </Box>
                <FlexRow justifyContent={'flex-start'} sx={{ paddingTop: '8px' }}>
                  <Box flex={0.2}>
                    <FormFieldSelectFormik
                      options={[
                        { label: 'Inspection', value: 'inspection' },
                        { label: 'State', value: 'state' },
                        { label: 'Tags', value: 'tags' },
                        { label: 'Tasks', value: 'task_active' },
                        {
                          label: 'Field Comparison Filter',
                          value: 'field_comparison'
                        },
                        {
                          label: 'Field Filter',
                          value: 'field'
                        }
                      ]}
                      name="filterType"
                      label="Filter Types"
                      placeholder="Select Filter Type"
                      value={null}
                      onChange={(event) => {
                        formikProps.handleChange(event)
                        setFilter(event)
                      }}
                    />
                  </Box>
                  <Box flex={0.1} marginLeft={'8px'}>
                    {tooltipTitle && (
                      <ToolTip title={tooltipTitle}>
                        <span style={{ cursor: 'pointer' }}>
                          <InfoIcon />
                        </span>
                      </ToolTip>
                    )}
                  </Box>
                </FlexRow>

                {formikProps.values.filterType && renderFilterComponent(formikProps)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonSecondary onClick={closeDialog} disabled={isRuleEdit || savedRule}>
                {t('global:cancel')}
              </ButtonSecondary>
              <ButtonPrimary
                data-testid="save_changes"
                isSubmit
                loading={editState.isLoading}
                disabled={isRuleEdit || savedRule}>
                {t('global:saveChanges')}
              </ButtonPrimary>
            </DialogActions>
          </Form>
        )}
      </Formik>
      <DeleteRuleDialogue
        show={showDelete}
        filter={props.filter}
        accountid={props.accountid}
        ruleId={deleteRule}
        onCancel={() => setShowDelete(false)}
        onComplete={() => onDelete()}
        onDeleteRule={onDeleteRule}
      />
      {showExitConfirm && (
        <ConfirmDialog onCancel={() => setShowExitConfirm(false)} onConfirm={cancelOption}></ConfirmDialog>
      )}
    </Dialog>
  )
}
