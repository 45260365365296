import React, { useState, useEffect } from 'react'
import { useApiPatch } from '@papertrail/web3-utils'
import { RRule, rrulestr } from 'rrule'
import { Frequency } from '../../types'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import {
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  FormFieldText,
  Formik,
  Box,
  FlexRow,
  FormFieldSelect,
  CloseIcon,
  IconButton,
  FormFieldGroup,
  Divider,
  FormFieldSearch,
  InfoIcon,
  ToolTip,
  AlertWarning,
  ToggleButton,
  ToggleButtonGroup,
  FormFieldSelectFormik,
  FormFieldTextFormik,
  AlertError
} from '@papertrail/styleguide'
import FrequencyPreview from './Preview'
import './EditFrequency.css'
import ConfirmDialog from './ConfirmDialog'
import { frequencyConstructionSteps, notifyInfo, inspectionDates, offsetInfo, freqInfo } from './TooltipText'

type Props = {
  show: boolean
  accountid: string
  frequency: Frequency
  onCancel: () => void
  onComplete: () => void
}

const EditFrequency = (props: Props) => {
  const [frequencyState, updateFrequency] = useApiPatch(
    `/accounts/${props.accountid}/frequencies/${props.frequency && props.frequency.id}`,
    (data) => data
  )
  const { frequency } = props
  const [isFrequencyRuleSelected, setIsFrequencyRuleSelected] = useState(false)
  const [ruleSelectedMessage, setRuleSelectedMessage] = useState('')
  const [showPreview, setShowPreview] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const [showExitConfirm, setShowExitConfirm] = useState(false)
  const [earlyInspection, setEarlyInspection] = useState('')
  const [lateInspection, setLateInspection] = useState('')
  const [dueInspection, setDueInspection] = useState('')
  const [isFrequencyNameComplete, setIsFrequencyNameComplete] = useState(true)
  const [isError, setIsError] = useState(false)

  const checkOptions = () => {
    if (props.frequency && props.frequency.options) {
      const hasTrueValue = Object.keys(props.frequency.options).some((optionKey) =>
        Object.values(props.frequency.options[optionKey]).some((value) => value === true)
      )
      return hasTrueValue ? 'new' : 'legacy'
    } else {
      return 'legacy'
    }
  }

  const [activeState, setActiveState] = useState(checkOptions())

  const { t } = useTranslation(['tools', 'global'])

  const setNotifyEdit = (type, data) => {
    if (data) {
      const regex = /(\d+)([DWMY])$/
      const match = data.match(regex)

      if (match) {
        const [, number, dateIndicator] = match

        if (type === 'Date') {
          return dateIndicator
        } else {
          return parseInt(number, 10)
        }
      }
    }
    return null
  }

  const initialValues = {
    frequencyName: frequency && frequency.name,
    frequencyRule: frequency && frequency.rule,
    notifyDate: frequency && setNotifyEdit('Date', frequency.notify),
    notify: frequency && setNotifyEdit('Interval', frequency.notify),
    offset: frequency && setNotifyEdit('Inteval', frequency.dateStartOffset),
    offsetType: (frequency && setNotifyEdit('Date', frequency.dateStartOffset)) || 'selectPeriod',
    interval: '',
    count: '',
    byday: '',
    defaultOptions: frequency && frequency.defaultOptions,
    options: frequency && frequency.options,
    earlyInspection: (frequency && findTrueOption(frequency.options, 'early')) || 'inspection_date',
    lateInspection: (frequency && findTrueOption(frequency.options, 'late')) || 'next_check_at',
    dueInspection: (frequency && findTrueOption(frequency.options, 'due')) || 'next_check_at'
  }

  function findTrueOption(options, optionKey) {
    if (options && options[optionKey]) {
      const innerOptions = options[optionKey]
      for (const key in innerOptions) {
        if (innerOptions[key] === true) {
          return key
        }
      }
    }
    return ''
  }

  const validationSchema = yup.object({
    frequencyName: yup.string().required('Frequency name is required'),
    frequencyRule: yup.string().required('Frequency rule is required'),
    earlyInspection: yup.string().required('Select an option'),
    lateInspection: yup.string().required('Select an option'),
    dueInspection: yup.string().required('Select an option'),
    notify: yup.string().required('Notify field is required'),
    notifyDate: yup.string().required('Select field is required')
  })

  const [suggestedRules, setSuggestedRules] = useState([
    { id: 'FREQ=DAILY', label: 'Every day' },
    { id: 'FREQ=WEEKLY', label: 'Every week' },
    { id: 'FREQ=MONTHLY', label: 'Every month' },
    { id: 'FREQ=YEARLY', label: 'Every year' },
    { id: 'FREQ=MONTHLY;INTERVAL=3', label: 'Quarterly' },
    { id: 'FREQ=YEARLY;INTERVAL=2', label: 'Every 2 years' },
    { id: 'FREQ=YEARLY;INTERVAL=3', label: 'Every 3 years' },
    { id: 'FREQ=WEEKLY;INTERVAL=2;BYDAY=FR', label: 'Every 2 weeks on Friday' },
    { id: 'FREQ', label: '' }
  ])

  const clearPreview = () => {
    setIsFrequencyRuleSelected(false)
    setSuggestedRules([
      { id: 'FREQ=DAILY', label: 'Every day' },
      { id: 'FREQ=WEEKLY', label: 'Every week' },
      { id: 'FREQ=MONTHLY', label: 'Every month' },
      { id: 'FREQ=YEARLY', label: 'Every year' },
      { id: 'FREQ=MONTHLY;INTERVAL=3', label: 'Quarterly' },
      { id: 'FREQ=YEARLY;INTERVAL=2', label: 'Every 2 years' },
      { id: 'FREQ=YEARLY;INTERVAL=3', label: 'Every 3 years' },
      { id: 'FREQ=WEEKLY;INTERVAL=2;BYDAY=FR', label: 'Every 2 weeks on Friday' },
      { id: 'FREQ', label: '' }
    ])
  }

  useEffect(() => {
    if (frequencyState.isLoaded) {
      props.onComplete()
    }
  }, [frequencyState])

  useEffect(() => {
    if (frequency && frequency.rule) {
      const exists = suggestedRules.some((rule) => rule.id === frequency.rule)
      const rule = rrulestr(frequency.rule)
      const ruleText = rule.toText()
      if (!exists) {
        setSuggestedRules([
          ...suggestedRules,
          {
            id: frequency.rule,
            label: ruleText
          }
        ])
      }
      setIsFrequencyRuleSelected(true)
    } else {
      // Handle the case when the frequency rule is empty or "None"
      setIsFrequencyRuleSelected(false)
    }
  }, [frequency])

  const mapValueToBoolean = (selectedValue, options) => {
    const result = {}
    for (const option of options) {
      result[option] = option === selectedValue
    }
    return result
  }

  const submitForm = (values) => {
    try {
      const rruleString = values.frequencyRule.trim()

      const ruleComponents = rruleString.split(';').map((component) => component.trim())

      let freq = ''
      let interval = ''
      let count = ''
      let byday = ''
      let bymonthday = ''

      ruleComponents.forEach((component) => {
        const [key, value] = component.split('=')
        if (key === 'FREQ') {
          freq = value
        } else if (key === 'INTERVAL') {
          interval = value
        } else if (key === 'COUNT') {
          count = value
        } else if (key === 'BYDAY') {
          byday = value
        } else if (key === 'BYMONTHDAY') {
          bymonthday = value
        }
      })

      const availableOptions = ['inspection_date', 'due_date', 'next_check_at']

      // Map earlyInspection, lateInspection, and dueInspection to objects
      const earlyInspectionValue = mapValueToBoolean(values.earlyInspection, availableOptions)
      const lateInspectionValue = mapValueToBoolean(values.lateInspection, availableOptions)
      const dueInspectionValue = mapValueToBoolean(values.dueInspection, availableOptions)

      let options = null

      if (activeState === 'new') {
        options = {}

        // Check if any values in options are true and populate the options object
        if (Object.values(earlyInspectionValue).some((value) => value === true)) {
          options.early = earlyInspectionValue
        }

        if (Object.values(lateInspectionValue).some((value) => value === true)) {
          options.late = lateInspectionValue
        }

        if (Object.values(dueInspectionValue).some((value) => value === true)) {
          options.due = dueInspectionValue
        }
      }

      updateFrequency({
        id: frequency.id,
        name: values.frequencyName,
        rule: `FREQ=${freq}${interval ? `;INTERVAL=${interval}` : ''}${count ? `;COUNT=${count}` : ''}${
          byday ? `;BYDAY=${byday}` : ''
        }${bymonthday ? `;BYMONTHDAY=${bymonthday}` : ''}`,
        notify: 'P' + values.notify + values.notifyDate,
        date_start_offset: values.offset && values.offsetType ? 'P' + values.offset + values.offsetType : null,
        options: options
      })

      setShowPreview(false)
      setFrequencyRule('')
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  const setFrequencyRule = (value, formikProps?) => {
    if (value === 'x') {
      clearPreview()
      return
    }
    setIsFrequencyRuleSelected(false)

    if (value === 'FREQ') {
      const tempR = suggestedRules.find((rule) => rule.id === value)

      if (tempR && tempR.label && tempR.label.trim() !== '') {
        let rule = null

        try {
          rule = RRule.fromText(tempR.label.trim())
        } catch (error) {
          setRuleSelectedMessage('Invalid frequency rule')
          return
        }

        if (rule) {
          const ruleString = rule.toString()
          const cleanRulestring = ruleString.slice(ruleString.indexOf(':') + 1)

          if (formikProps && cleanRulestring && cleanRulestring.length > 0) {
            formikProps.setFieldValue('frequencyRule', cleanRulestring)
            const nextRules = suggestedRules.map((rule) => {
              if (rule.id === 'FREQ') {
                return { ...rule, id: cleanRulestring }
              } else {
                return rule
              }
            })
            setSuggestedRules(nextRules)

            setIsFrequencyRuleSelected(true)
            setFormChanged(true)
            setRuleSelectedMessage('')
          } else {
            setRuleSelectedMessage('Invalid frequency rule')
          }
        } else {
          setRuleSelectedMessage('Invalid frequency rule')
        }
      } else {
        setRuleSelectedMessage('Invalid frequency rule')
      }

      return
    }
    const tempRule = value.trim()
    if (tempRule !== '') {
      const rruleString = tempRule
      const rule = rrulestr(rruleString)

      const limitedDates = []

      let currentDate = new Date()

      for (let i = 1; i < 4; i++) {
        currentDate = rule.after(currentDate)
        if (currentDate) {
          limitedDates.push(currentDate)
        } else {
          break
        }
      }
      setIsFrequencyRuleSelected(true)
      setRuleSelectedMessage('')
    } else {
      setIsFrequencyRuleSelected(false)
      setRuleSelectedMessage('')
    }
  }

  const handleFieldChange = (value) => {
    if (value === 'x') {
      setIsFrequencyRuleSelected(false)
      setRuleSelectedMessage('')
      return
    }
    const nextRules = suggestedRules.map((rule) => {
      if (rule.id === 'FREQ') {
        return { ...rule, label: value }
      } else {
        return rule
      }
    })
    setSuggestedRules(nextRules)
  }

  const toggleActiveState = (stateToSet) => {
    setActiveState(stateToSet)
    setFormChanged(true)
  }

  const openPreview = (event) => {
    event.preventDefault()

    if (isFrequencyRuleSelected) {
      setShowPreview(true)
    } else {
      setRuleSelectedMessage('Please select a frequency rule')
    }
  }

  function cancelOption() {
    setShowExitConfirm(false)
    props.onCancel()
    setIsError(false)
    setFormChanged(false)
    setShowPreview(false)
    setFrequencyRule('')
  }

  const closeDialog = () => {
    if (formChanged) {
      // Show confirm dialog before canceling
      setShowExitConfirm(true)
    } else {
      props.onCancel()
      clearPreview()
      setIsError(false)
      setShowPreview(false)
      setEarlyInspection('')
      setLateInspection('')
      setDueInspection('')
      setFrequencyRule('')
    }
  }

  const backButton = () => {
    setShowPreview(false)
  }

  useEffect(() => {
    if (frequencyState.isError) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }, [frequencyState])

  useEffect(() => {
    if (props.frequency) {
      if (props.frequency.options) {
        const hasTrueValue = Object.keys(props.frequency.options).some((optionKey) =>
          Object.values(props.frequency.options[optionKey]).some((value) => value === true)
        )

        if (hasTrueValue) {
          setActiveState('new')
        } else {
          setActiveState('legacy')
        }
      } else if (props.frequency.options === null) {
        setActiveState('legacy')
      }
    }
  }, [props.frequency])

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values)
        }}>
        {(formikProps) => {
          // Add an event handler to track the completion of the frequency name
          const handleFrequencyNameChange = (event) => {
            const { value } = event.target
            setIsFrequencyNameComplete(value)
            formikProps.handleChange(event)
          }
          return (
            <Form>
              <FlexRow justifyContent="space-between">
                <DialogTitle id="alert-dialog-title">{'Edit Frequency'}</DialogTitle>
                <IconButton id="basic-button" size="large" onClick={closeDialog}>
                  <CloseIcon />
                </IconButton>
              </FlexRow>
              {isError && (
                <AlertWarning>
                  Sorry there was an error creating the frequency. Please note all frequencies must have a unique name.
                </AlertWarning>
              )}
              {ruleSelectedMessage && <AlertError>{ruleSelectedMessage}</AlertError>}
              <Divider></Divider>
              {showPreview ? (
                isFrequencyRuleSelected && (
                  <>
                    <Divider></Divider>
                    <DialogContent sx={{ width: '550px', height: '60vh', overflow: 'auto' }}>
                      <DialogContentText id="alert-dialog-description" style={{ width: '300px' }}>
                        <FrequencyPreview
                          accountid={props.accountid}
                          frequencyRule={formikProps.values.frequencyRule}
                        />
                      </DialogContentText>
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions>
                      <ButtonSecondary onClick={backButton}> Back </ButtonSecondary>
                      <ButtonPrimary
                        data-testid="save_changes"
                        isSubmit
                        fixedWidth={false}
                        loading={frequencyState.isLoading}>
                        Save changes
                      </ButtonPrimary>
                    </DialogActions>
                  </>
                )
              ) : (
                <>
                  <DialogContent sx={{ width: '550px', height: '60vh', overflow: 'auto' }}>
                    <DialogContentText id="alert-dialog-description">
                      <Box style={{ width: '300px' }}>
                        <ToggleButtonGroup
                          sx={{ paddingBottom: '15px' }}
                          value={activeState}
                          exclusive
                          onChange={(event, newValue) => toggleActiveState(newValue)}
                          aria-label="text alignment">
                          <ToggleButton
                            value="legacy"
                            aria-label="left aligned"
                            // disabled={activeState === 'new'}
                            onClick={() => toggleActiveState('legacy')}>
                            <span className="toggle-text">Legacy</span>
                            <ToolTip title={freqInfo.legacy}>
                              <InfoIcon />
                            </ToolTip>
                          </ToggleButton>
                          <ToggleButton value="new" aria-label="centered" onClick={() => toggleActiveState('new')}>
                            <span className="toggle-text">New</span>
                            <ToolTip title={freqInfo.new}>
                              <InfoIcon />
                            </ToolTip>
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <FormFieldTextFormik
                          label={'Frequency name'}
                          placeholder={'Frequency name'}
                          name="frequencyName"
                          onChange={handleFrequencyNameChange}
                        />
                        <FlexRow justifyContent={'center'}>
                          <FormFieldSearch
                            options={suggestedRules}
                            name="frequencyRule"
                            mandatory={true}
                            label={'Frequency'}
                            placeholder="Search frequencies"
                            onFieldChange={(value) => handleFieldChange(value)}
                            onValueChange={(value) => setFrequencyRule(value, formikProps)}
                          />
                          <div style={{ display: 'flex', marginLeft: '7px', alignItems: 'center' }}>
                            <ToolTip title={frequencyConstructionSteps.steps}>
                              <InfoIcon />
                            </ToolTip>
                          </div>
                        </FlexRow>
                        <FormFieldGroup>
                          <Box flex={1} paddingTop={'18px'}>
                            <FormFieldText
                              label={'Notify period'}
                              placeholder={'Enter number'}
                              name="notify"
                              type="number"
                            />
                          </Box>
                          <Box flex={1} paddingTop={'18px'}>
                            <FlexRow justifyContent={''}>
                              <FormFieldSelect
                                options={[
                                  { label: 'Day(s)', value: 'D' },
                                  { label: 'Week(s)', value: 'W' },
                                  { label: 'Month(s)', value: 'M' },
                                  { label: 'Year(s)', value: 'Y' }
                                ]}
                                name={'notifyDate'}
                                placeholder="Select Period"
                                label={''}
                                mandatory={true}
                              />
                              <ToolTip title={notifyInfo.notify}>
                                <InfoIcon />
                              </ToolTip>
                            </FlexRow>
                          </Box>
                        </FormFieldGroup>
                      </Box>

                      {activeState === 'legacy' && (
                        <FormFieldGroup>
                          <Box sx={{ flex: 1, paddingTop: 1 }}>
                            <FormFieldText
                              label={'Set offset period'}
                              placeholder={'Set offset period'}
                              name="offset"
                              type="number"
                            />
                          </Box>

                          <Box sx={{ flex: 1, paddingTop: 1 }}>
                            <FlexRow justifyContent={''}>
                              <FormFieldSelect
                                options={[
                                  { label: 'Select Period', value: 'selectPeriod', disabled: true },
                                  { label: 'Day(s)', value: 'D' },
                                  { label: 'Week(s)', value: 'W' },
                                  { label: 'Month(s)', value: 'M' },
                                  { label: 'Year(s)', value: 'Y' }
                                ]}
                                name={'offsetType'}
                                placeholder=""
                                label={''}
                                mandatory={true}
                              />
                              <ToolTip title={offsetInfo.offset}>
                                <InfoIcon />
                              </ToolTip>
                            </FlexRow>
                          </Box>
                        </FormFieldGroup>
                      )}

                      {activeState === 'new' ? (
                        <>
                          <Divider></Divider>
                          <Box
                            sx={{
                              display: 'flex',
                              paddingLeft: '0px',
                              paddingBottom: '5px',
                              paddingTop: '15px',
                              fontStyle: 'bold',
                              alignItems: 'center',
                              fontSize: 'Medium',
                              color: 'black'
                            }}>
                            {' '}
                            Inspection dates
                          </Box>
                          <Box sx={{ width: '500px' }}>
                            You can use these options to decide when the next inspection date should be calculated from,
                            depending on whether the inspection was early, late, or on time. These options are set as
                            defaults.
                          </Box>
                          <FormFieldGroup>
                            <Box flex={1} paddingTop={'18px'}>
                              <FlexRow justifyContent={''}>
                                <FormFieldSelectFormik
                                  options={[
                                    { label: 'Inspection Date', value: 'inspection_date' },
                                    { label: 'Due Date', value: 'due_date' },
                                    { label: 'Next check date', value: 'next_check_at' }
                                  ]}
                                  name={'earlyInspection'}
                                  placeholder="Select option"
                                  label={'Early Inspection Date'}
                                  mandatory={true}
                                  value={earlyInspection}
                                  width={'275px'}
                                  onChange={(event) => {
                                    formikProps.handleChange(event)
                                  }}
                                />
                                <ToolTip title={inspectionDates.early}>
                                  <InfoIcon />
                                </ToolTip>
                              </FlexRow>
                            </Box>
                          </FormFieldGroup>
                          <FormFieldGroup>
                            <Box flex={1} paddingTop={'18px'}>
                              <FlexRow justifyContent={''}>
                                <FormFieldSelectFormik
                                  options={[
                                    { label: 'Inspection Date', value: 'inspection_date' },
                                    { label: 'Due Date', value: 'due_date' },
                                    { label: 'Next check date', value: 'next_check_at' }
                                  ]}
                                  name={'lateInspection'}
                                  placeholder="Select option"
                                  label={'Late Inspection Date'}
                                  mandatory={true}
                                  value={lateInspection}
                                  width={'275px'}
                                  onChange={(event) => {
                                    formikProps.handleChange(event)
                                  }}
                                />
                                <ToolTip title={inspectionDates.late}>
                                  <InfoIcon />
                                </ToolTip>
                              </FlexRow>
                            </Box>
                          </FormFieldGroup>
                          <FormFieldGroup>
                            <Box flex={1} paddingTop={'18px'}>
                              <FlexRow justifyContent={''}>
                                <FormFieldSelectFormik
                                  options={[
                                    { label: 'Inspection Date', value: 'inspection_date' },
                                    { label: 'Due Date', value: 'due_date' },
                                    { label: 'Next check date', value: 'next_check_at' }
                                  ]}
                                  name={'dueInspection'}
                                  placeholder="Select option"
                                  label={'Inspect on time'}
                                  mandatory={true}
                                  value={dueInspection}
                                  width={'275px'}
                                  onChange={(event) => {
                                    formikProps.handleChange(event)
                                  }}
                                />
                                <ToolTip title={inspectionDates.due}>
                                  <InfoIcon />
                                </ToolTip>
                              </FlexRow>
                            </Box>
                          </FormFieldGroup>
                        </>
                      ) : null}
                    </DialogContentText>
                  </DialogContent>
                  <Divider></Divider>
                  <DialogActions sx={{ position: '-webkit-sticky' }}>
                    <Box sx={{ marginRight: '8px' }}>
                      {' '}
                      <ButtonSecondary onClick={() => closeDialog()}>{t('global:cancel')}</ButtonSecondary>{' '}
                    </Box>
                    <ButtonPrimary
                      data-testid="next"
                      onClick={() => openPreview(event)}
                      disabled={!isFrequencyRuleSelected || !isFrequencyNameComplete}>
                      Next
                    </ButtonPrimary>
                  </DialogActions>
                </>
              )}
            </Form>
          )
        }}
      </Formik>
      {showExitConfirm && (
        <ConfirmDialog onCancel={() => setShowExitConfirm(false)} onConfirm={cancelOption}></ConfirmDialog>
      )}
    </Dialog>
  )
}

export default EditFrequency
