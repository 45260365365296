import {
  AlertWarning,
  ButtonSecondary,
  ButtonDanger,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CloseIcon,
  FlexRow,
  IconButton
} from '@papertrail/styleguide'
import React, { useEffect } from 'react'
import { State } from 'src/types'
import { useApiDelete } from '@papertrail/web3-utils'

type Props = {
  show: boolean
  accountid: string
  state: State
  onCancel: () => void
  onComplete: () => void
}
export default function DeleteDialogue(props: Props) {
  const [deleteState, getDeleteState] = useApiDelete<any>(
    `/accounts/${props.accountid}/states/${props.state.id}`,
    (data) => data
  )
  useEffect(() => {
    if (deleteState.isLoaded) {
      props.onComplete()
    }
  }, [deleteState])

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <FlexRow justifyContent="space-between">
        <DialogTitle id="alert-dialog-title">{'Delete State'}</DialogTitle>
        <IconButton id="basic-button" size="large" onClick={() => props.onCancel()}>
          <CloseIcon />
        </IconButton>
      </FlexRow>
      <DialogContent>
        {deleteState.isError && (
          <AlertWarning>A state cannot be deleted if it is the default state or used in a filter.</AlertWarning>
        )}
        <DialogContentText id="alert-dialog-description">Are you sure you want to delete this state?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={() => props.onCancel()}>Cancel</ButtonSecondary>
        <ButtonDanger
          data-testid="confirm_delete"
          onClick={() => getDeleteState({})}
          isSubmit
          loading={deleteState.isLoading}>
          Yes, Delete
        </ButtonDanger>
      </DialogActions>
    </Dialog>
  )
}
