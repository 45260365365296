import React from 'react'
import { Filter, Rule } from '../../types'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'

import {
  Form,
  Formik,
  Box,
  CloseIcon,
  CheckIcon,
  IconButton,
  FormFieldRadioGroup,
  FlexRow
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  rule?: Rule
  onCancel: () => void
  onComplete: (values) => void
}

export default function TaskRule(props: Props) {
  const initialValues = {
    id: props.rule.id || uuid(),
    key: props.rule.key || 'task_active',
    value: props.rule.value,
    active: props.rule.active || false,
    label: props.rule.label || '',
    sentiment: props.rule.sentiment || ''
  }

  function closeDialog() {
    props.onCancel()
  }

  const validationSchema = yup.object({
    rule: yup
      .object({
        value: yup.string().required('Value Required')
      })
      .required('Rule must be updated')
  })

  function submitForm(values) {
    const newValues = values
    newValues.label = values.value === 'true' ? 'Tasks: active tasks' : 'Tasks: no active tasks'
    newValues.value = values.value
    newValues.sentiment = values.label
    newValues.active = false
    newValues.key = values.key
    if (values.value) {
      props.onComplete(newValues)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => console.log(values)}>
      {(formikProps) => (
        <Form>
          <Box padding={2}>
            <FlexRow justifyContent="space-between">
              <FormFieldRadioGroup
                row
                name={'value'}
                values={[
                  { label: 'Active Tasks', value: 'true' },
                  { label: 'No Active Tasks', value: 'false' }
                ]}
              />

              <IconButton
                id="basic-button"
                size="large"
                onClick={closeDialog}
                color={'error'}
                sx={{
                  backgroundColor: '#fdebec',
                  borderRadius: '0px',
                  margin: '0 8px'
                }}>
                <CloseIcon />
              </IconButton>
              <IconButton
                id="basic-button"
                size="large"
                onClick={() => submitForm(formikProps.values)}
                color={'primary'}
                sx={{
                  backgroundColor: '#e5f2ff',
                  borderRadius: '0px',
                  margin: '0 8px'
                }}>
                <CheckIcon />
              </IconButton>
            </FlexRow>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
