import React, { useEffect } from 'react'
import { Filter } from 'src/types'
import { useApiPost } from '@papertrail/web3-utils'
import {
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  onCancel: () => void
  onComplete: () => void
}
export default function SetDefaultDialogue(props: Props) {
  const [defaultState, getDefaultState] = useApiPost<any>(
    `/accounts/${props.accountid}/filters/${props.filter.id}/actions/default`,
    (data) => data
  )

  useEffect(() => {
    if (defaultState.isLoaded) {
      props.onComplete()
    }
  }, [defaultState])

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-dialog">{'Set Default Filter'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to set this as the default filter?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={() => props.onCancel()}>Cancel</ButtonSecondary>
        <ButtonPrimary
          data-testid="confirm_default"
          onClick={() => getDefaultState({})}
          isSubmit
          loading={defaultState.isLoading}>
          Yes, set default filter
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}
