import React, { useEffect } from 'react'
import { Tag } from '../../types'
import { useApiPatch } from '@papertrail/web3-utils'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import {
  AlertWarning,
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  FormFieldText,
  Formik
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  tags: Tag[]
  onCancel: () => void
  onComplete: () => void
}

export default function MergeDialogue(props: Props) {
  const [mergeState, mergeTag, reset] = useApiPatch(`/accounts/${props.accountid}/tags/replace`, (data) => data)
  const { t } = useTranslation(['tools', 'global'])

  function closeDialog() {
    reset()
    props.onCancel()
  }

  useEffect(() => {
    if (mergeState.isLoaded) {
      props.onComplete()
    }
  }, [mergeState])

  const validationSchema = yup.object({
    tagName: yup.string().required(t('errorTagRequired'))
  })
  const initialValues = { tagName: '' }

  function getErrorText() {
    if (mergeState.isError) {
      if (mergeState.error.errorCode === 'tag_missing') {
        return t('errorTagExists')
      } else {
        return t('errorMergeTag')
      }
    }
  }

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const tagNames = props.tags.map((tag) => tag.name)
          mergeTag({ replace_tags: tagNames, with_tag: values.tagName })
        }}>
        <Form>
          <DialogTitle id="alert-dialog-title">{t('mergeTag')}</DialogTitle>
          <DialogContent sx={{ width: '300px' }}>
            {mergeState.isError && (
              <Box paddingBottom={1}>
                <AlertWarning>{getErrorText()}</AlertWarning>
              </Box>
            )}
            <DialogContentText id="alert-dialog-description">
              <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} paddingBottom={2}>
                {props.tags.map((tag) => (
                  <Box key={tag.id} padding={'2px'}>
                    <Chip key={tag.id} label={tag.name} variant="outlined" />
                  </Box>
                ))}{' '}
              </Box>

              <FormFieldText label={t('tagName')} placeholder={t('tagName')} name="tagName" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonSecondary onClick={closeDialog}>{t('global:cancel')}</ButtonSecondary>
            <ButtonPrimary data-testid="save_changes" isSubmit loading={mergeState.isLoading}>
              {t('global:saveChanges')}
            </ButtonPrimary>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}
