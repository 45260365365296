import React from 'react'

export const frequencyConstructionSteps = {
  steps: (
    <div>
      <p>
        For a simple event every day, week or month, you can write:
        <ul>
          <li>Every day, or Every weekday</li>
          <li>Every week</li>
          <li>Every week on Wednesday</li>
          <li>Every 2 weeks</li>
          <li>Every months</li>
          <li>Every 2 months</li>
        </ul>
      </p>
      <p>
        You can build up more complicated rules that specify multiple days or dates:
        <ul>
          <li> Every Monday and Wednesday</li>
          <li> Every month on the 1st, 15th</li>
          <li> Every month on the last day</li>
          <li> Every month on the 2nd last Friday</li>
        </ul>
      </p>
    </div>
  )
}

export const freqInfo = {
  legacy: (
    <div>
      <p>Simplified legacy settings for frequencies - Default</p>
    </div>
  ),
  new: (
    <div>
      <p>A new frequency rule can be created using custom rules as required.</p>
    </div>
  )
}

export const notifyInfo = {
  notify: <p>You should add a notify period to indicate when the record will become due for inspection.</p>
}
export const offsetInfo = {
  offset: (
    <div>
      <p>
        The offset is used for Monthly or Yearly frequencies, and applies the date start offset interval to the current
        system date. The resulting date serves as the starting point for determining the next check.
      </p>
    </div>
  )
}

export const inspectionDates = {
  early: (
    <p>
      Use these options to determine when the next inspection date should occur if the inspection is carried out early.
    </p>
  ),
  late: (
    <p>
      Use these options to determine when the next inspection date should occur if the inspection is carried out late.
    </p>
  ),
  due: (
    <p>
      Use these options to determine when the next inspection date should occur if the inspection is carried out on
      time.
    </p>
  )
}
