import React, { useEffect } from 'react'
import {
  AlertWarning,
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  FormFieldText,
  Formik,
  Box,
  FormFieldSelect
} from '@papertrail/styleguide'
import { Contact } from '../../types'
import { useApiGet, useApiPatch } from '@papertrail/web3-utils'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

type Props = {
  show: boolean
  accountid: string
  onCancel: () => void
  onComplete: () => void
  contact: Contact
}

const contactTypeMapper = (data) => {
  const contactType = data.data.map((type) => {
    return {
      value: type.id,
      label: type.value
    }
  })
  return contactType
}

const EditDialogue = (props: Props) => {
  const [contactTypes, getContactTypes] = useApiGet(
    `/accounts/${props.accountid}/lookups/contact_type`,
    contactTypeMapper
  )
  const [contactState, editContact, reset] = useApiPatch(
    `/accounts/${props.accountid}/contacts/${props.contact.id}`,
    (data) => data
  )
  const { t } = useTranslation(['tools', 'global'])

  useEffect(() => {
    if (props.accountid) {
      getContactTypes({})
    }
  }, [props.accountid])

  useEffect(() => {
    if (contactState.isLoaded) {
      props.onComplete()
    }
  }, [contactState])

  const closeDialog = () => {
    reset()
    props.onCancel()
  }

  const getErrorText = () => {
    if (contactState.isError) {
      if (contactState.error.errorCode === 'validation_error') {
        return 'There has been a validation error'
      } else return 'There has been a problem. please try again!'
    }
  }

  const validationSchema = yup.object({
    type_id: yup.string().notOneOf(['null'], 'Contact Type Required'),
    name: yup.string().required('Contact Name Required'),
    email: yup
      .string()
      .required('Email Required')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Please enter a valid email'),
    mobile: yup
      .string()
      .min(8, 'Please enter your full number including country code')
      .matches(/[0-9()-+,]+$/, 'Please enter a valid phone number'),
    phone: yup
      .string()
      .min(8, 'Please enter your full number including country code')
      .matches(/[0-9()-+,]+$/, 'Please enter a valid phone number')
  })

  const initialValues = {
    name: props.contact.name,
    type_id: props.contact.type_id,
    email: props.contact.email ? props.contact.email : '',
    mobile: props.contact.mobile ? props.contact.mobile : '',
    phone: props.contact.phone ? props.contact.phone : ''
  }
  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          editContact(values)
        }}>
        {contactTypes.isLoaded && (
          <Form>
            <DialogTitle id="alert-dialog-title">{t('Edit Account Contact')}</DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
              {contactState.isError && (
                <Box paddingBottom={1}>
                  <AlertWarning>{getErrorText()}</AlertWarning>
                </Box>
              )}
              <DialogContentText id="alert-dialog-description">
                <FormFieldSelect
                  label="Contact type*"
                  placeholder="Select type"
                  options={contactTypes.data}
                  name="type_id"
                  fullWidth
                />
                <FormFieldText label="Contact Name*" placeholder="Full Name" name="name" />
                <FormFieldText label="Email*" placeholder="name@mail.com" name="email" />
                <FormFieldText label="Mobile" placeholder="Enter mobile number" name="mobile" />
                <FormFieldText label="Phone" placeholder="Enter phone number" name="phone" />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonSecondary onClick={closeDialog}>{t('global:cancel')}</ButtonSecondary>
              <ButtonPrimary isSubmit loading={contactState.isLoading}>
                Save Changes
              </ButtonPrimary>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default EditDialogue
