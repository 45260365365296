import * as React from 'react'
import { ActionMenu, IconButton, Menu, MenuItem, ToolTip } from '@papertrail/styleguide'
import { useCallback, useEffect, useState } from 'react'
import { State } from '../../types'
import DeleteDialogue from '../state/DeleteDialogue'
import SetDefaultDialogue from '../state/SetDefaultDialogue'
import EditDialogue from '../state/EditDialogue'

type Props = {
  accountid: string
  state: State
  onComplete: () => void
}

export default function StateActions(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [showDelete, setShowDelete] = useState(false)
  const [showDefault, setShowDefault] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const handleEscapeKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowDelete(false)
      setShowDefault(false)
      setShowEdit(false)
    }
  }, [])

  useEffect(() => {
    if (showDelete || showDefault || showEdit) {
      document.addEventListener('keydown', handleEscapeKeyPress)
    } else {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [showDefault, showDelete, showEdit, handleEscapeKeyPress])

  function openDeleteDialogue(state) {
    handleClose()
    setShowDelete(true)
  }
  function onDelete() {
    setShowDelete(false)
    props.onComplete()
  }
  function openDefaultState(state) {
    handleClose()
    setShowDefault(true)
  }
  function openEditDialogue(state) {
    setShowEdit(true)
    handleClose()
  }
  function onEditComplete() {
    setShowEdit(false)
    props.onComplete()
  }

  return (
    <div>
      <IconButton
        data-testid="actions-button"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <ActionMenu htmlColor="black" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <ToolTip
          title={
            props.state.default
              ? 'Current default state'
              : props.state.shouldArchive
              ? 'You cannot set states to default that will archive records'
              : 'Set as default state'
          }>
          <span>
            <MenuItem
              disabled={props.state.default || props.state.shouldArchive}
              onClick={openDefaultState}
              data-testid="default_button">
              Set as default state
            </MenuItem>
          </span>
        </ToolTip>

        <ToolTip title={props.state.editable ? 'Edit' : 'You cannot edit states which are used in records'}>
          <span>
            <MenuItem disabled={!props.state.editable} onClick={openEditDialogue} data-testid="edit_button">
              Edit state
            </MenuItem>
          </span>
        </ToolTip>

        <ToolTip title={props.state.deletable ? 'Delete' : 'You cannot delete the default state'}>
          <span>
            <MenuItem disabled={!props.state.deletable} onClick={openDeleteDialogue} data-testid="delete_button">
              Delete state
            </MenuItem>
          </span>
        </ToolTip>
      </Menu>
      <DeleteDialogue
        show={showDelete}
        state={props.state}
        accountid={props.accountid}
        onCancel={() => setShowDelete(false)}
        onComplete={() => onDelete()}
      />
      <SetDefaultDialogue
        show={showDefault}
        state={props.state}
        accountid={props.accountid}
        onCancel={() => setShowDefault(false)}
        onComplete={() => onDelete()}
      />
      <EditDialogue
        show={showEdit}
        state={props.state}
        accountid={props.accountid}
        onCancel={() => setShowEdit(false)}
        onComplete={() => onEditComplete()}
      />
    </div>
  )
}
