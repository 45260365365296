import React from 'react'
import { Filter, Rule, State } from '../../types'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'

import {
  Form,
  Formik,
  Box,
  CloseIcon,
  CheckIcon,
  IconButton,
  FormFieldSelectFormik,
  FormFieldRadioGroup,
  FlexRow
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  rule: Rule
  allStates: State[]
  onCancel: () => void
  onComplete: (values) => void
}

export default function StateRule(props: Props) {
  const { rule, allStates, onCancel } = props

  const initialValues = {
    id: props.rule.id || uuid(),
    key: props.rule.key || 'state',
    value: props.rule.value || null,
    active: props.rule.active || false,
    label: props.rule.label || 'Select a State',
    sentiment: props.rule.sentiment || ''
  }

  function closeDialog() {
    onCancel()
  }

  const validationSchema = yup.object({
    rule: yup
      .object({
        label: yup.string().required('Rule must be updated'),
        value: yup.string().required('Rule must be updated')
      })
      .required('Rule must be updated')
  })

  function submitForm(values) {
    const newValues = values
    newValues.label =
      values.value === '@is_positive=false' || values.value === '@is_positive=true'
        ? 'State is ' + values.sentiment
        : 'State: ' + allStates.find((s) => s.id === values.value).name
    newValues.value = values.value
    newValues.sentiment = values.sentiment
    newValues.active = false
    newValues.id = values.id
    newValues.key = values.key
    props.onComplete(newValues)
  }

  function renderState() {
    switch (rule.value) {
      case '@is_positive=true':
      case '@is_positive=false': {
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => console.log(values)}>
            {(formikProps) => (
              <Form>
                <Box>
                  Sentiment
                  <FlexRow justifyContent="space-between">
                    <FormFieldRadioGroup
                      key={props.rule.id}
                      name={'sentiment'}
                      values={[{ label: 'Positive', value: 'positive' }]}
                    />
                    <FormFieldRadioGroup
                      key={props.rule.id}
                      name={'sentiment'}
                      values={[{ label: 'Negative', value: 'negative' }]}
                    />

                    <IconButton
                      id="basic-button"
                      size="large"
                      onClick={closeDialog}
                      color={'error'}
                      sx={{
                        backgroundColor: '#fdebec',
                        borderRadius: '0px',
                        margin: '0 8px'
                      }}>
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      id="basic-button"
                      size="large"
                      onClick={() => submitForm(formikProps.values)}
                      color={'primary'}
                      sx={{
                        backgroundColor: '#e5f2ff',
                        borderRadius: '0px',
                        margin: '0 8px'
                      }}>
                      <CheckIcon />
                    </IconButton>
                  </FlexRow>
                </Box>
              </Form>
            )}
          </Formik>
        )
      }

      default: {
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => console.log(values)}>
            {(formikProps) => (
              <Form>
                <FlexRow justifyContent="space-between">
                  <FormFieldSelectFormik
                    options={allStates.map((state) => ({
                      label: state.name,
                      value: state.id
                    }))}
                    name={'value'}
                    label={'States'}
                    placeholder={'Select a state'}
                    mandatory={true}
                    value={null}
                    onChange={(event) => {
                      formikProps.handleChange(event)
                    }}
                  />
                  <IconButton
                    id="basic-button"
                    size="large"
                    onClick={closeDialog}
                    color={'error'}
                    sx={{
                      backgroundColor: '#fdebec',
                      borderRadius: '0px',
                      margin: '0 8px'
                    }}>
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    size="large"
                    onClick={() => submitForm(formikProps.values)}
                    color={'primary'}
                    sx={{
                      backgroundColor: '#e5f2ff',
                      borderRadius: '0px',
                      margin: '0 8px'
                    }}>
                    <CheckIcon />
                  </IconButton>
                </FlexRow>
              </Form>
            )}
          </Formik>
        )
      }
    }
  }

  return <Box padding={2}>{renderState()}</Box>
}
