import React, { useEffect, useState } from 'react'
import { Filter, Rule, State, Tag, Blueprint, Field } from '../../types'
import { useApiPut } from '@papertrail/web3-utils'
import EditRule from './EditRule'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import LongMenu from '@papertrail/styleguide/src/menu/LongMenu'
import InspectionRule from './InspectionRule'
import TagRule from './TagRule'
import StateRule from './StateRule'
import FieldFilter from './FieldFilter'
import ComparisonFilter from './ComparisonFilter'

import {
  AlertWarning,
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  FormFieldText,
  Formik,
  Box,
  FormFieldSelectFormik,
  FormFieldCheckbox,
  Chip,
  Divider,
  ToolTip,
  InfoIcon,
  Stack,
  FlexRow,
  IconButton,
  CloseIcon,
  CheckIcon,
  ErrorIcon,
  WarningIcon
} from '@papertrail/styleguide'
import DeleteRuleDialogue from './DeleteRuleDialog'
import ConfirmDialog from './ConfirmDialog'
import './EditDialogue.css'
import TaskRule from './TaskRule'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  blueprint: Blueprint
  field: Field[]
  allTags: Tag[]
  allStates: State[]
  rule?: Rule
  onCancel: () => void
  onComplete: () => void
}

export default function EditDialogue(props: Props) {
  const { field, allStates, allTags } = props
  const [localRules, setLocalRules] = useState<Rule[]>(props.filter.rules || [])
  const [addNewInspec, setAddNewInspec] = useState(false)
  const [addNewTags, setAddNewTags] = useState(false)
  const [addNewTasks, setAddNewTasks] = useState(false)
  const [addNewState, setAddNewState] = useState(false)
  const [addNewField, setAddNewField] = useState(false)
  const [addNewComparisonField, setAddNewComparisonField] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [deleteRule, setDeleteRule] = useState<string>('')
  const [starred, setStarred] = useState(false)
  const [archived, setArchived] = useState(false)
  const [error, setError] = useState('')
  const [showExitConfirm, setShowExitConfirm] = useState<boolean>(false)
  const [formChanged, setFormChanged] = useState(false)

  const { t } = useTranslation(['tools', 'global'])

  const [editState, editFilter] = useApiPut<any>(
    `/accounts/${props.accountid}/filters/${props.filter.id}`,
    (data) => data
  )
  const initialValues = {
    filterName: props.filter.name,
    profile: props.filter.profile,
    showCount: props.filter.showCount,
    rules: props.filter.rules,
    starred: props.filter.rules.find((rule: Rule) => rule.key === 'starred') !== undefined,
    archived: props.filter.rules.find((rule: Rule) => rule.key === 'include') !== undefined
  }

  useEffect(() => {
    if (editState.isLoaded) {
      props.onComplete()
    }
  }, [editState])

  useEffect(() => {
    if (initialValues) {
      if (initialValues.starred) {
        setStarred(initialValues.starred)
      }
      if (initialValues.archived) {
        setArchived(true)
      }
    }
  }, [])

  useEffect(() => {
    setError('') // Reset the error message
  }, [props.show, localRules])

  const closeDialog = () => {
    if (formChanged) {
      // Show confirm dialog before canceling
      setShowExitConfirm(true)
    } else {
      props.onCancel()
      setArchived(false)
      setError('')
    }
  }

  function cancelOption() {
    setShowExitConfirm(false)
    props.onCancel()
    setFormChanged(false)
  }

  const closeRuleEdit = (rule: Rule, index?) => {
    const newRules = localRules.map((r: Rule) => {
      if (r.id === rule.id) {
        return {
          ...r,
          active: false
        }
      } else {
        return r
      }
    })

    newRules[index] = rule
    const chip = document.getElementById(rule.key)
    chip.style.backgroundColor = '#F6F7F8'
    chip.style.color = '#213854'
    setLocalRules(newRules)
  }

  const handleEditChip = (event, rule) => {
    event.currentTarget.style.backgroundColor = 'darkgrey'
    event.currentTarget.style.color = 'white'
    const newRules = localRules.map((r: Rule) => {
      if (r.id === rule.id) {
        return {
          ...r,
          active: true
        }
      } else {
        return r
      }
    })

    setLocalRules(newRules)
  }

  const closeNewRule = (rule) => {
    const newRules = localRules || []
    newRules.push(rule)
    setLocalRules(newRules)
    setAddNewInspec(false)
    setAddNewTags(false)
    setAddNewTasks(false)
    setAddNewState(false)
    setAddNewField(false)
    setAddNewComparisonField(false)
  }

  const handleInput = (value: string) => {
    switch (value) {
      case 'Inspection':
        setAddNewInspec(true)
        setFormChanged(true)
        break
      case 'Tags':
        setAddNewTags(true)
        setFormChanged(true)
        break
      case 'Tasks':
        setAddNewTasks(true)
        setFormChanged(true)
        break
      case 'State':
        setAddNewState(true)
        setFormChanged(true)
        break
      case 'Field Filter':
        setAddNewField(true)
        setFormChanged(true)
        break
      case 'Field Comparison Filter':
        setAddNewComparisonField(true)
        setFormChanged(true)
        break
    }
  }

  function openDeleteDialog(id: string) {
    setDeleteRule(id)
    setShowDelete(true)
  }
  function onDelete() {
    setShowDelete(false)
  }

  function onDeleteRule(ruleId: string) {
    const updatedRules = localRules.filter((rule) => rule.id !== ruleId)
    setLocalRules(updatedRules)
    setDeleteRule('')
    setShowDelete(false)
  }

  const validationSchema = yup.object({
    filterName: yup.string().required('Filter name required'),
    profile: yup.string().required('Filter profile required'),
    rules: yup.array().required('Filter rules required')
  })

  useEffect(() => {
    setLocalRules(props.filter.rules || [])
  }, [props.filter.rules])

  const submitForm = (values) => {
    const hasValidRules =
      !localRules ||
      localRules.length === 0 ||
      localRules.every((rule) => rule.key === 'starred' || rule.key === 'include')
    if (hasValidRules) {
      setError('Please add a filter rule.')
      return
    } else {
      setError('')
    }

    const newRules = localRules.map((r: Rule) => {
      if (r.key === 'starred' && values.starred === true) {
        return {
          id: r.id,
          key: r.key,
          value: 'true'
        }
      } else if (r.key === 'starred' && values.starred === false) {
        return null // Remove the starred rule if values.starred is 'false'
      } else if (r.key === 'include' && r.value === 'archived') {
        if (values.archived) {
          return r // Keep the rule if it's selected (checked)
        } else {
          return null // Skip the rule if it's deselected (unchecked)
        }
      } else {
        return {
          id: r.id,
          key: r.key,
          value: r.value
        }
      }
    })
    const filteredRules = newRules.filter((r) => r !== null)
    const hasStarredRule = filteredRules.some((r) => r.key === 'starred')
    const hasArchivedRule = filteredRules.some((r) => r.key === 'include' && r.value === 'archived')

    if (!hasStarredRule && starred === true) {
      filteredRules.push({
        id: '',
        key: 'starred',
        value: 'true'
      })
    }
    if (!hasArchivedRule && values.archived) {
      filteredRules.push({
        id: '',
        key: 'include',
        value: 'archived'
      })
    }

    const newMeta = [
      {
        // id: '',
        key: 'show_count',
        value: true
      }
    ]
    if (filteredRules.length > 0) {
      editFilter({
        name: values.filterName,
        profile: values.profile,
        rules: filteredRules,
        meta: newMeta
      })
    }
  }

  function handleStarredCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    setStarred(event.target.checked)
  }

  function handleArchivedCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    setArchived(event.target.checked)
  }

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
        onReset={() => setFormChanged(false)}>
        {(formikProps) => (
          <Form>
            <FlexRow justifyContent="space-between">
              <DialogTitle id="alert-dialog-title">{'Edit Filters'}</DialogTitle>
              <IconButton id="basic-button" size="large" onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </FlexRow>
            <DialogContent sx={{ width: '550px' }}>
              {editState.isError && (
                <Box paddingBottom={1}>
                  <AlertWarning>{'Sorry, there was a problem saving the filter'}</AlertWarning>
                </Box>
              )}
              <DialogContentText id="alert-dialog-description">
                {error && (
                  <Box paddingBottom={1}>
                    <AlertWarning>{error}</AlertWarning>
                  </Box>
                )}
                <FormFieldText label={'Filter Name'} placeholder={'Filter Name'} name="filterName" />
                <FormFieldSelectFormik
                  options={[
                    {
                      value: 'default',
                      label: 'Default',
                      icon: <CheckIcon />,
                      borderBottom: true,
                      borderTop: true,
                      borderBottomColor: '#e4e7eb',
                      borderTopColor: '#e4e7eb'
                    },
                    {
                      value: 'warn',
                      label: 'Warning',
                      icon: <WarningIcon />,
                      backgroundColour: '#fde4b3',
                      fontColour: '#ad7302',
                      borderBottom: true,
                      borderBottomColor: '#e4e7eb'
                    },
                    {
                      value: 'alert',
                      label: 'Alert',
                      icon: <ErrorIcon />,
                      backgroundColour: '#fdebec',
                      fontColour: '#d41927',
                      borderBottom: true,
                      borderBottomColor: '#e4e7eb'
                    }
                  ]}
                  name="profile"
                  label="Choose filter colour"
                  placeholder=""
                  value={formikProps.values.profile}
                  onChange={(event) => {
                    formikProps.handleChange(event)
                  }}
                />
                <FormFieldCheckbox
                  name={'starred'}
                  label={'Starred only'}
                  checked={starred}
                  onChange={handleStarredCheckbox}
                  tooltip={'Check this box if you want to search though starred folders'}
                />
                <FormFieldCheckbox
                  name={'archived'}
                  label={'Include archived'}
                  checked={archived}
                  onChange={handleArchivedCheckbox}
                  tooltip={'Check this box to include archived records in the filter'}
                />
                <Stack direction="row" alignItems="left" spacing={1}>
                  <Box sx={{ color: '#343741', fontWeight: '600', paddingBottom: '20px' }}>Filter Types</Box>
                  <ToolTip title={'To edit a filter click on the name below'}>
                    <InfoIcon />
                  </ToolTip>
                </Stack>
                To edit a filter click on the name.
                <Divider sx={{ position: 'absolute', left: '0', right: '0' }}></Divider>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  flexWrap={'wrap'}
                  paddingBottom={2}
                  sx={{ paddingTop: '20px' }}>
                  {localRules &&
                    localRules.map((rule: Rule) => (
                      <Box padding={'8px'} key={rule.id}>
                        {rule.label !== 'Starred only' && rule.label !== 'Archived' && (
                          <Chip
                            id={rule.key}
                            key={rule.key}
                            label={rule.label}
                            variant="outlined"
                            onClick={(event) => handleEditChip(event, rule)}
                            onDelete={() => openDeleteDialog(rule.id)}
                            data-testid="Chip"></Chip>
                        )}
                      </Box>
                    ))}
                </Box>
                {localRules &&
                  localRules.map((r: Rule, index) => (
                    <EditRule
                      key={r.id}
                      show={r.active}
                      accountid={props.filter.id}
                      filter={props.filter}
                      rule={r}
                      blueprint={props.blueprint}
                      field={field}
                      allStates={allStates}
                      allTags={allTags}
                      onCancel={() => closeRuleEdit(r)}
                      onComplete={(value) => closeRuleEdit(value, index)}
                    />
                  ))}
                <Divider sx={{ position: 'absolute', left: '0', right: '0' }}></Divider>
                <DialogActions>
                  <div className="actions">
                    <LongMenu
                      options={['Inspection', 'Tasks', 'State', 'Tags', 'Field Comparison Filter', 'Field Filter']}
                      valueChangeCallback={handleInput}
                      mode="button"
                      text="Add new rule"
                    />
                  </div>
                </DialogActions>
                {addNewInspec && (
                  <InspectionRule
                    show={true}
                    accountid={props.filter.id}
                    filter={props.filter}
                    onCancel={() => setAddNewInspec(false)}
                    onComplete={(values) => closeNewRule(values)}
                    rule={{
                      id: '',
                      key: '',
                      value: '',
                      active: false,
                      label: '',
                      sentiment: '',
                      type: 'rule',
                      operator: ''
                    }}
                  />
                )}
                {addNewTasks && (
                  <TaskRule
                    show={true}
                    accountid={props.filter.id}
                    filter={props.filter}
                    onCancel={() => setAddNewTasks(false)}
                    onComplete={(values) => closeNewRule(values)}
                    rule={{
                      id: '',
                      key: '',
                      value: '',
                      active: false,
                      label: '',
                      sentiment: '',
                      type: 'rule',
                      operator: ''
                    }}
                  />
                )}
                {addNewTags && (
                  <TagRule
                    allTags={allTags}
                    onCancel={() => setAddNewTags(false)}
                    onComplete={(values) => closeNewRule(values)}
                    show={false}
                    accountid={''}
                    filter={props.filter}
                    rule={{
                      id: '',
                      key: '',
                      value: '',
                      active: false,
                      label: '',
                      sentiment: '',
                      type: 'rule',
                      operator: ''
                    }}
                  />
                )}
                {addNewState && (
                  <StateRule
                    accountid={props.filter.id}
                    filter={props.filter}
                    onCancel={() => setAddNewState(false)}
                    onComplete={(values) => closeNewRule(values)}
                    allStates={allStates}
                    show={false}
                    rule={{
                      id: '',
                      key: '',
                      value: '',
                      active: false,
                      label: '',
                      sentiment: '',
                      type: 'rule',
                      operator: ''
                    }}
                  />
                )}
                {addNewField && (
                  <FieldFilter
                    accountid={props.filter.id}
                    show={false}
                    filter={props.filter}
                    blueprint={props.blueprint}
                    field={field}
                    allStates={allStates}
                    onCancel={() => setAddNewField(false)}
                    onComplete={(values) => closeNewRule(values)}
                    rule={{
                      id: '',
                      key: '',
                      value: '',
                      active: false,
                      label: '',
                      sentiment: '',
                      type: 'rule',
                      operator: ''
                    }}
                  />
                )}
                {addNewComparisonField && (
                  <ComparisonFilter
                    accountid={props.filter.id}
                    filter={props.filter}
                    blueprint={props.blueprint}
                    field={field}
                    allStates={allStates}
                    show={false}
                    onCancel={() => setAddNewComparisonField(false)}
                    onComplete={(values) => closeNewRule(values)}
                    rule={{
                      id: '',
                      key: '',
                      value: '',
                      active: false,
                      label: '',
                      sentiment: '',
                      operator: '',
                      type: 'rule'
                    }}
                  />
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ButtonSecondary onClick={closeDialog} disabled={localRules.some((rule) => rule.active)}>
                {t('global:cancel')}
              </ButtonSecondary>
              <ButtonPrimary
                data-testid="save_changes"
                disabled={localRules.some((rule) => rule.active)}
                isSubmit
                loading={editState.isLoading}>
                {t('global:saveChanges')}
              </ButtonPrimary>
            </DialogActions>
          </Form>
        )}
      </Formik>
      <DeleteRuleDialogue
        show={showDelete}
        filter={props.filter}
        accountid={props.accountid}
        ruleId={deleteRule}
        onCancel={() => setShowDelete(false)}
        onComplete={() => onDelete()}
        onDeleteRule={onDeleteRule}
      />
      {showExitConfirm && (
        <ConfirmDialog onCancel={() => setShowExitConfirm(false)} onConfirm={cancelOption}></ConfirmDialog>
      )}
    </Dialog>
  )
}
