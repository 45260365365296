import { useFormikContext } from '@papertrail/styleguide'
import { useEffect } from 'react'

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key]
      if (!value) return

      const nextKey = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })
    return result
  }

  return transformObjectToDotNotation(formikErrors)
}

type Props = {
  onComplete: (values) => void
}

export const ScrollToFieldError = (props: Props) => {
  const { submitCount, isValid, errors } = useFormikContext()

  useEffect(() => {
    if (isValid) {
      props.onComplete(null)
    }

    const fieldErrorNames = getFieldErrorNames(errors)
    if (fieldErrorNames.length <= 0) {
      props.onComplete(null)
    }
    if (fieldErrorNames.length > 0) {
      props.onComplete(fieldErrorNames)
    }
  }, [submitCount])

  return null
}
