import React, { useEffect } from 'react'
import { Tag } from '../../types'
import { useApiDelete } from '@papertrail/web3-utils'
import { useTranslation } from 'react-i18next'

import {
  AlertWarning,
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  tag: Tag
  onCancel: () => void
  onComplete: () => void
}

export default function DeleteDialogue(props: Props) {
  const [deleteState, deleteTag] = useApiDelete(`/accounts/${props.accountid}/tags/${props.tag.id}`, (data) => data)

  useEffect(() => {
    if (deleteState.isLoaded) {
      props.onComplete()
    }
  }, [deleteState])

  const { t } = useTranslation(['tools', 'global'])
  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t('deleteTag')}</DialogTitle>
      <DialogContent>
        {deleteState.isError && <AlertWarning>{t('errorDeleteTag')}</AlertWarning>}
        <DialogContentText id="alert-dialog-description">{t('confirmDeleteTag')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={() => props.onCancel()}>{t('global:cancel')}</ButtonSecondary>
        <ButtonPrimary data-testid="confirm_delete" onClick={() => deleteTag({})} loading={deleteState.isLoading}>
          {t('global:confirmDelete')}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}
