import React from 'react'
import { Filter, Rule, Tag } from '../../types'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'

import { Form, Formik, Box, CloseIcon, CheckIcon, IconButton, FormFieldSearch, FlexRow } from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  rule: Rule
  allTags: Tag[]
  onCancel: () => void
  onComplete: (values) => void
}

export default function TagRule(props: Props) {
  const { allTags } = props

  const initialValues = [
    {
      id: props.rule.id || uuid,
      key: props.rule.key || 'tag',
      value: props.rule.value || '',
      active: props.rule.active || false,
      label: props.rule.label || ''
    }
  ]

  const validationSchema = yup.object({
    rule: yup
      .object({
        label: yup.string().required('Rule must be updated'),
        value: yup.string().required('Rule must be updated')
      })
      .required('Rule must be updated')
  })

  function submitForm(values) {
    const newValues = values
    newValues.label = 'Tag: ' + allTags.find((t) => t.id === values.tags).name
    newValues.value = allTags.find((t) => t.id === values.tags).name
    newValues.active = false
    newValues.id = values.tags
    newValues.key = 'tag'
    delete newValues.tags
    props.onComplete(newValues)
  }

  function closeDialog() {
    props.onCancel()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => console.log(values)}>
      {(formikProps) => (
        <Form>
          <Box padding={2}>
            <FlexRow justifyContent="space-between">
              <FormFieldSearch
                options={allTags.map((tag) => ({
                  id: tag.id,
                  label: tag.name
                }))}
                name={'tags'}
                label={'Tags'}
                placeholder={props.rule.value || 'Search for tags'}></FormFieldSearch>

              <IconButton
                id="basic-button"
                size="large"
                onClick={closeDialog}
                color={'error'}
                sx={{
                  backgroundColor: '#fdebec',
                  borderRadius: '0px',
                  margin: '0 8px'
                }}>
                <CloseIcon />
              </IconButton>
              <IconButton
                id="basic-button"
                size="large"
                onClick={() => submitForm(formikProps.values)}
                color={'primary'}
                sx={{
                  backgroundColor: '#e5f2ff',
                  borderRadius: '0px',
                  margin: '0 8px'
                }}>
                <CheckIcon />
              </IconButton>
            </FlexRow>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
