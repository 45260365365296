import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApiGet } from '@papertrail/web3-utils'
import './ContactsManager.css'

import { Contact } from 'src/types'
import CreateDialogue from './CreateDialogue'
import EditDialogue from './EditDialogue'

import {
  Box,
  ButtonPrimary,
  ContentHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FlexRow,
  ContentNonScrolling,
  ContentWrapper,
  TableSortLabel,
  NoData,
  SettingsTeamIcon,
  SearchInput
} from '@papertrail/styleguide'
import LongMenu from '@papertrail/styleguide/src/menu/LongMenu'
import DeleteDialogue from './DeleteDialogue'

type Params = {
  accountid: string
}

const contactMapper = (data) => {
  const contact = data.data.map((babyContact) => {
    return {
      id: babyContact.id,
      name: babyContact.name,
      type: babyContact.type.data.value,
      type_id: babyContact.type.data.id,
      email: babyContact.email,
      mobile: babyContact.mobile,
      phone: babyContact.phone
    }
  })
  return contact
}

const ContactsManager = () => {
  const { accountid } = useParams() as Params
  const [contactState, setContacts] = useState(null)
  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [contactsState, getContacts] = useApiGet<Contact[]>(`/accounts/${accountid}/contacts`, contactMapper)
  const [nameSortOrder, setNameSortOrder] = useState<'asc' | 'desc'>('asc')
  const [emailSortOrder, setEmailSortOrder] = useState<'asc' | 'desc'>('asc')
  const [sortColumn, setSortColumn] = useState<string>('name')
  const searchStyle = {
    color: '#8F95B2',
    backgroundColor: 'white',
    borderBottom: 'none',
    paddingX: '8px',
    height: '40px',
    marginX: '8px',
    borderRadius: '4px',
    border: 'solid 1px #A6AFBB'
  }

  useEffect(() => {
    if (accountid) {
      getContacts({ include: 'type' })
    }
  }, [])

  const handleSort = (column: string) => {
    setSortColumn(column)
    let sortDirection: string
    if (sortColumn === 'name') {
      setNameSortOrder(nameSortOrder === 'asc' ? 'desc' : 'asc')
      sortDirection = nameSortOrder === 'desc' ? '-' : ''
    }
    if (sortColumn === 'email') {
      setEmailSortOrder(emailSortOrder === 'asc' ? 'desc' : 'asc')
      sortDirection = emailSortOrder === 'desc' ? '-' : ''
    }
    getContacts({ include: 'type', sort: sortDirection + sortColumn })
  }

  const openCreateDialogue = () => {
    setShowCreate(true)
  }

  const openEditDialogue = (contact: Contact) => {
    setContacts(contact)
    setShowEdit(true)
  }

  const openDeleteDialogue = (contact: Contact) => {
    setShowDelete(true)
  }

  const onCreateCompleted = () => {
    setShowCreate(false)
    getContacts({ include: 'type' })
  }

  const onEditCompleted = () => {
    setShowEdit(false)
    getContacts({ include: 'type' })
  }

  const onDeleteCompleted = () => {
    setShowDelete(false)
    getContacts({ include: 'type' })
  }

  const handleInput = (value: string) => {
    if (value === 'Delete') {
      openDeleteDialogue(contactState)
      setShowDelete(true)
    }
  }

  const search = (input: string) => {
    getContacts({ include: 'type', filter: `name~=${input}` })
  }

  return (
    <ContentWrapper>
      <ContentHeader>{'Contacts Manager'}</ContentHeader>
      <ContentNonScrolling>
        <FlexRow justifyContent={'space-between'} sx={{ marginBottom: '20px' }}>
          <h3>Account Contacts</h3>
          <ButtonPrimary onClick={() => openCreateDialogue()}>+ Add Contact</ButtonPrimary>
        </FlexRow>
        <FlexRow justifyContent={'flex-start'} sx={{ marginBottom: '20px' }}>
          <SearchInput
            search={search}
            initialSearch=""
            placeholder="Search for contacts"
            styles={searchStyle}
            iconColor="#A6AFBB"
          />
        </FlexRow>

        {contactsState.isLoaded && contactsState.data.length > 0 && (
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel active direction={nameSortOrder} onClick={() => handleSort('name')}>
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>
                    <TableSortLabel active direction={emailSortOrder} onClick={() => handleSort('email')}>
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactsState.isLoaded &&
                  contactsState.data.map((contact) => {
                    return (
                      <TableRow
                        key={contact.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => setContacts(contact)}>
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{contact.type}</TableCell>
                        <TableCell>{contact.email}</TableCell>
                        <TableCell>{contact.mobile}</TableCell>
                        <TableCell>{contact.phone}</TableCell>
                        <TableCell>
                          <div className="actions">
                            <ButtonPrimary onClick={() => openEditDialogue(contact)}>Edit</ButtonPrimary>
                            <LongMenu options={['Delete']} valueChangeCallback={handleInput} mode="ellipsis"></LongMenu>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Box>
        )}
      </ContentNonScrolling>
      {contactsState.isLoaded && contactsState.data.length === 0 && (
        <NoData icon={SettingsTeamIcon} text="There are no contacts in this account" />
      )}
      {contactState && (
        <EditDialogue
          show={showEdit}
          accountid={accountid}
          onCancel={() => setShowEdit(false)}
          onComplete={() => onEditCompleted()}
          contact={contactState}
        />
      )}
      {contactState && (
        <DeleteDialogue
          show={showDelete}
          accountid={accountid}
          onCancel={() => setShowDelete(false)}
          onComplete={() => onDeleteCompleted()}
          contact={contactState}
        />
      )}
      <CreateDialogue
        show={showCreate}
        accountid={accountid}
        onCancel={() => setShowCreate(false)}
        onComplete={() => onCreateCompleted()}
      />
    </ContentWrapper>
  )
}

export default ContactsManager
