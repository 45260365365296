import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { IconButton } from '@papertrail/styleguide'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type Props = {
  menuList1
  menuList2
  valueChangeCallback: (value: any) => void
  sectionData
}

const NestedDropdownMenu = (props: Props) => {
  const { menuList1, menuList2, valueChangeCallback, sectionData } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null)
  const [action, setAction] = useState<string>(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (location?) => {
    if (location && typeof location === 'string') {
      valueChangeCallback({ action: action, location: location })
    }
    setAnchorEl(null)
    setSubMenuAnchorEl(null)
    setAction(null)
  }

  const handleSubMenuClick = (event, option) => {
    setAction(option)
    if (option === 'Move to' || option === 'Copy to') {
      setSubMenuAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
      setSubMenuAnchorEl(null)
      valueChangeCallback({ action: option })
    }
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        onClick={handleClick}
        data-testid={
          sectionData.type === 'sections'
            ? `${sectionData.type}-${sectionData.index}-actions`
            : `sections-${sectionData.currentSection}-${sectionData.type}-${sectionData.index}-actions`
        }>
        <MoreVertIcon fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'nested-menu'
        }}>
        {menuList1 &&
          menuList1.map((item1, index) => (
            <MenuItem
              key={index}
              sx={{ fontSize: '14px' }}
              onClick={(event) => {
                handleSubMenuClick(event, item1)
              }}>
              {item1}
            </MenuItem>
          ))}
        <Menu anchorEl={subMenuAnchorEl} open={Boolean(subMenuAnchorEl)} onClose={handleClose}>
          {menuList2 &&
            menuList2.map((item2, index) => (
              <MenuItem key={index} sx={{ fontSize: '14px' }} onClick={() => handleClose(item2)} data-testid={item2}>
                {item2}
              </MenuItem>
            ))}
        </Menu>
      </Menu>
    </>
  )
}

export default NestedDropdownMenu
