import React, { useState, useEffect } from 'react'
import { useApiPost } from '@papertrail/web3-utils'
import { RRule, rrulestr } from 'rrule'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import './AddNewFrequency.css'

import {
  AlertWarning,
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  Formik,
  Box,
  FlexRow,
  CloseIcon,
  IconButton,
  FormFieldGroup,
  Divider,
  FormFieldSearch,
  InfoIcon,
  ToolTip,
  FormFieldSelectFormik,
  FormFieldTextFormik,
  AlertError
} from '@papertrail/styleguide'
import FrequencyPreview from './Preview'
import ConfirmDialog from './ConfirmDialog'
import { frequencyConstructionSteps, notifyInfo, inspectionDates } from './TooltipText'
import { Frequency } from 'src/types'

type Props = {
  show: boolean
  accountid: string
  frequency: Frequency[]
  onCancel: () => void
  onComplete: () => void
}

const AddNewFrequency = (props: Props) => {
  const [frequencyState, createNewFrequency] = useApiPost(`/accounts/${props.accountid}/frequencies`, (data) => data)
  const [isFrequencyRuleSelected, setIsFrequencyRuleSelected] = useState(false)
  const [ruleSelectedMessage, setRuleSelectedMessage] = useState('')
  const [showPreview, setShowPreview] = useState(false)
  const [earlyInspection, setEarlyInspection] = useState('')
  const [lateInspection, setLateInspection] = useState('')
  const [dueInspection, setDueInspection] = useState('')
  const [formChanged, setFormChanged] = useState(false)
  const [showExitConfirm, setShowExitConfirm] = useState(false)
  const [isFrequencyNameComplete, setIsFrequencyNameComplete] = useState(false)
  const [notify, setNotify] = useState(false)
  const [notifyDate, setNotifyDate] = useState('')
  const [isError, setIsError] = useState(false)

  const { t } = useTranslation(['tools', 'global'])

  const initialValues = {
    frequencyName: '',
    frequencyRule: '',
    notifyDate: '',
    notify: '',
    interval: '',
    count: '',
    byday: '',
    earlyInspection: 'inspection_date',
    lateInspection: 'next_check_at',
    dueInspection: 'next_check_at'
  }

  const validationSchema = yup.object({
    frequencyName: yup.string().required('Frequency name is required'),
    frequencyRule: yup.string().required('Frequency rule is required'),
    earlyInspection: yup.string().required('Select an option'),
    lateInspection: yup.string().required('Select an option'),
    dueInspection: yup.string().required('Select an option'),
    notify: yup.string().required('Notify field is required'),
    notifyDate: yup.string().required('Select field is required')
  })

  const [suggestedRules, setSuggestedRules] = useState([
    { id: 'FREQ=DAILY', label: 'Every day' },
    { id: 'FREQ=WEEKLY', label: 'Every week' },
    { id: 'FREQ=MONTHLY', label: 'Every month' },
    { id: 'FREQ=YEARLY', label: 'Every year' },
    { id: 'FREQ=MONTHLY;INTERVAL=3', label: 'Quarterly' },
    { id: 'FREQ=YEARLY;INTERVAL=2', label: 'Every 2 years' },
    { id: 'FREQ=YEARLY;INTERVAL=3', label: 'Every 3 years' },
    { id: 'FREQ=WEEKLY;INTERVAL=2;BYDAY=FR', label: 'Every 2 weeks on Friday' },
    { id: 'FREQ', label: '' }
  ])

  const clearPreview = () => {
    setIsFrequencyRuleSelected(false)
    setSuggestedRules([
      { id: 'FREQ=DAILY', label: 'Every day' },
      { id: 'FREQ=WEEKLY', label: 'Every week' },
      { id: 'FREQ=MONTHLY', label: 'Every month' },
      { id: 'FREQ=YEARLY', label: 'Every year' },
      { id: 'FREQ=MONTHLY;INTERVAL=3', label: 'Quarterly' },
      { id: 'FREQ=YEARLY;INTERVAL=2', label: 'Every 2 years' },
      { id: 'FREQ=YEARLY;INTERVAL=3', label: 'Every 3 years' },
      { id: 'FREQ=WEEKLY;INTERVAL=2;BYDAY=FR', label: 'Every 2 weeks on Friday' },
      { id: 'FREQ', label: '' }
    ])
  }

  const closeDialog = () => {
    if (formChanged) {
      // Show confirm dialog before canceling
      setShowExitConfirm(true)
    } else {
      props.onCancel()
      setIsError(false)
      clearPreview()
      setShowPreview(false)
      setEarlyInspection('')
      setLateInspection('')
      setDueInspection('')
      setIsFrequencyNameComplete(false)
      setFrequencyRule('')
    }
  }

  function cancelOption() {
    setShowExitConfirm(false)
    props.onCancel()
    setIsError(false)
    clearPreview()
    setFormChanged(false)
    setShowPreview(false)
    setIsFrequencyNameComplete(false)
    setFrequencyRule('')
  }

  useEffect(() => {
    if (frequencyState.isLoaded) {
      props.onComplete()
    }
  }, [frequencyState])

  const mapValueToBoolean = (selectedValue, options) => {
    const result = {}
    for (const option of options) {
      result[option] = option === selectedValue
    }
    return result
  }

  const isFrequencyNameUnique = (newName) => {
    if (newName.trim() === '') {
      return false
    }

    if (Array.isArray(props.frequency)) {
      return !props.frequency.some((existingObject) => existingObject.name === newName)
    }

    return false
  }

  const submitForm = (values) => {
    const newName = values.frequencyName.trim()
    if (isFrequencyNameUnique(newName)) {
      try {
        const rruleString = values.frequencyRule.trim()
        const ruleComponents = rruleString.split(';').map((component) => component.trim())

        let freq = ''
        let interval = ''
        let count = ''
        let byday = ''
        let bymonthday = ''

        ruleComponents.forEach((component) => {
          const [key, value] = component.split('=')
          if (key === 'FREQ') {
            freq = value
          } else if (key === 'INTERVAL') {
            interval = value
          } else if (key === 'COUNT') {
            count = value
          } else if (key === 'BYDAY') {
            byday = value
          } else if (key === 'BYMONTHDAY') {
            bymonthday = value
          }
        })

        const availableOptions = ['inspection_date', 'due_date', 'next_check_at']

        // Map earlyInspection, lateInspection, and dueInspection to objects
        const earlyInspectionValue = mapValueToBoolean(values.earlyInspection, availableOptions)
        const lateInspectionValue = mapValueToBoolean(values.lateInspection, availableOptions)
        const dueInspectionValue = mapValueToBoolean(values.dueInspection, availableOptions)

        createNewFrequency({
          name: values.frequencyName,
          rule: `FREQ=${freq}${interval ? `;INTERVAL=${interval}` : ''}${count ? `;COUNT=${count}` : ''}${
            byday ? `;BYDAY=${byday}` : ''
          }${bymonthday ? `;BYMONTHDAY=${bymonthday}` : ''}`,
          notify: 'P' + values.notify + values.notifyDate,
          date_start_offset: null,
          options: {
            early: earlyInspectionValue,
            due: dueInspectionValue,
            late: lateInspectionValue
          }
        })

        clearPreview()
        setShowPreview(false)
        setEarlyInspection('')
        setLateInspection('')
        setDueInspection('')
        setRuleSelectedMessage('')
      } catch (error) {
        console.error('Invalid frequency rule:', error)
      }
    } else {
      setRuleSelectedMessage('All frequencies must have a unique name')
    }
  }

  const setFrequencyRule = (value, formikProps?) => {
    if (value === 'x' || value === null) {
      clearPreview()
      return
    }

    setIsFrequencyRuleSelected(false)

    if (value === 'FREQ') {
      const tempR = suggestedRules.find((rule) => rule.id === value)

      if (tempR && tempR.label && tempR.label.trim() !== '') {
        let rule = null

        try {
          rule = RRule.fromText(tempR.label.trim())
        } catch (error) {
          setRuleSelectedMessage('Invalid frequency rule')
          return
        }

        if (rule) {
          const ruleString = rule.toString()
          const cleanRulestring = ruleString.slice(ruleString.indexOf(':') + 1)

          if (formikProps && cleanRulestring && cleanRulestring.length > 0) {
            formikProps.setFieldValue('frequencyRule', cleanRulestring)
            const nextRules = suggestedRules.map((rule) => {
              if (rule.id === 'FREQ') {
                return { ...rule, id: cleanRulestring }
              } else {
                return rule
              }
            })
            setSuggestedRules(nextRules)

            setIsFrequencyRuleSelected(true)
            setRuleSelectedMessage('')
          } else {
            setRuleSelectedMessage('Invalid frequency rule')
          }
        } else {
          setRuleSelectedMessage('Invalid frequency rule')
        }
      } else {
        setRuleSelectedMessage('Invalid frequency rule')
      }

      return
    }
    const tempRule = value.trim()
    if (tempRule !== '' && tempRule !== 'x') {
      const rruleString = tempRule
      const rule = rrulestr(rruleString)

      const limitedDates = []

      let currentDate = new Date()

      for (let i = 1; i < 4; i++) {
        currentDate = rule.after(currentDate)
        if (currentDate) {
          limitedDates.push(currentDate)
        } else {
          break
        }
      }
      setIsFrequencyRuleSelected(true)
      setRuleSelectedMessage('')
    } else {
      setIsFrequencyRuleSelected(false)
      setRuleSelectedMessage('')
    }
  }

  const handleFieldChange = (value) => {
    if (value === 'x') {
      clearPreview()
      setRuleSelectedMessage('')
      return
    }
    const nextRules = suggestedRules.map((rule) => {
      if (rule.id === 'FREQ') {
        return { ...rule, label: value }
      } else {
        return rule
      }
    })
    setSuggestedRules(nextRules)
  }

  const backButton = () => {
    setShowPreview(false)
    setIsFrequencyNameComplete(true)
  }

  const openPreview = (event) => {
    event.preventDefault()

    if (isFrequencyRuleSelected) {
      setShowPreview(true)
      setFormChanged(true)
    } else {
      setRuleSelectedMessage('Please select a frequency rule')
    }
  }

  useEffect(() => {
    if (frequencyState.isError) {
      setIsError(true)
    } else {
      setIsError(false)
      setRuleSelectedMessage('')
    }
  }, [frequencyState])

  return (
    <>
      <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            submitForm(values)
          }}>
          {(formikProps) => {
            const handleFrequencyNameChange = (event) => {
              const { value } = event.target
              setIsFrequencyNameComplete(value.trim() !== '')
              formikProps.handleChange(event)
            }
            const handleNotifyChange = (event) => {
              const { value } = event.target
              setNotify(value.trim() !== '')
              formikProps.handleChange(event)
            }
            const handleSelectChange = (value) => {
              setNotifyDate(value)
              formikProps.setFieldValue('notifyDate', value)
            }
            return (
              <Form>
                <DialogTitle sx={{ position: '-webkit-sticky' }} id="alert-dialog-title">
                  <FlexRow justifyContent="space-between">
                    <Box> {'Create new frequency'}</Box>

                    <IconButton id="basic-button" size="large" onClick={closeDialog}>
                      <CloseIcon />
                    </IconButton>
                  </FlexRow>
                </DialogTitle>

                {isError && (
                  <AlertWarning>
                    Sorry there was an error creating the frequency. Please note all frequencies must have a unique
                    name.
                  </AlertWarning>
                )}
                {ruleSelectedMessage && <AlertError>{ruleSelectedMessage}</AlertError>}
                <Divider></Divider>
                {showPreview ? (
                  isFrequencyRuleSelected && (
                    <>
                      <Divider></Divider>
                      <DialogContent sx={{ width: '550px', height: '60vh', overflow: 'auto' }}>
                        <DialogContentText id="alert-dialog-description" style={{ width: '300px' }}>
                          <FrequencyPreview
                            accountid={props.accountid}
                            frequencyRule={formikProps.values.frequencyRule}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <Divider></Divider>
                      <DialogActions>
                        <ButtonSecondary onClick={backButton}> Back </ButtonSecondary>
                        <ButtonPrimary
                          data-testid="save_changes"
                          isSubmit
                          fixedWidth={false}
                          loading={frequencyState.isLoading}>
                          Save changes
                        </ButtonPrimary>
                      </DialogActions>
                    </>
                  )
                ) : (
                  <>
                    <DialogContent sx={{ width: '550px', height: '60vh', overflow: 'auto' }}>
                      <DialogContentText id="alert-dialog-description">
                        <Box style={{ width: '300px' }}>
                          <FormFieldTextFormik
                            label={'Frequency name'}
                            placeholder={'Frequency name'}
                            name="frequencyName"
                            mandatory={true}
                            onChange={handleFrequencyNameChange}
                          />
                          <FlexRow justifyContent={'center'}>
                            <FormFieldSearch
                              data-testid="frequencyRule"
                              options={suggestedRules}
                              name="frequencyRule"
                              mandatory={true}
                              label={'Frequency'}
                              placeholder="Type here (eg Every Monday)"
                              onFieldChange={(value) => handleFieldChange(value)}
                              onValueChange={(value) => setFrequencyRule(value, formikProps)}
                            />
                            <div style={{ display: 'flex', marginLeft: '7px', alignItems: 'center' }}>
                              <ToolTip title={frequencyConstructionSteps.steps}>
                                <InfoIcon />
                              </ToolTip>
                            </div>
                          </FlexRow>
                          <FormFieldGroup>
                            <Box flex={1} paddingTop={'18px'}>
                              <FormFieldTextFormik
                                label={'Notify period'}
                                placeholder={'Enter number'}
                                name="notify"
                                type="number"
                                mandatory={true}
                                onChange={handleNotifyChange}
                              />
                            </Box>

                            <Box flex={1} paddingTop={'18px'}>
                              <FlexRow justifyContent={''}>
                                <FormFieldSelectFormik
                                  options={[
                                    { label: 'Day(s)', value: 'D' },
                                    { label: 'Week(s)', value: 'W' },
                                    { label: 'Month(s)', value: 'M' },
                                    { label: 'Year(s)', value: 'Y' }
                                  ]}
                                  name={'notifyDate'}
                                  placeholder="Select Period"
                                  label={'  '}
                                  mandatory={true}
                                  value={notifyDate}
                                  onChange={(event) => {
                                    handleSelectChange(event.target.value)
                                  }}
                                />
                                <ToolTip title={notifyInfo.notify}>
                                  <InfoIcon />
                                </ToolTip>
                              </FlexRow>
                            </Box>
                          </FormFieldGroup>
                        </Box>

                        <Divider></Divider>
                        <Box
                          sx={{
                            display: 'flex',
                            paddingLeft: '0px',
                            paddingBottom: '5px',
                            paddingTop: '15px',
                            fontStyle: 'bold',
                            alignItems: 'center',
                            fontSize: 'Medium',
                            color: 'black'
                          }}>
                          {' '}
                          Inspection dates
                        </Box>
                        <Box sx={{ width: '500px' }}>
                          You can use these options to decide when the next inspection date should be calculated from,
                          depending on whether the inspection was early, late, or on time. These options are set as
                          defaults.
                        </Box>
                        <FormFieldGroup>
                          <Box flex={1} paddingTop={'18px'}>
                            <FlexRow justifyContent={''}>
                              <FormFieldSelectFormik
                                options={[
                                  { label: 'Inspection Date', value: 'inspection_date' },
                                  { label: 'Due Date', value: 'due_date' },
                                  { label: 'Next check date', value: 'next_check_at' }
                                ]}
                                name={'earlyInspection'}
                                placeholder="Select option"
                                label={'Early Inspection Date'}
                                mandatory={true}
                                value={earlyInspection}
                                width={'275px'}
                                onChange={(event) => {
                                  formikProps.handleChange(event)
                                }}
                              />
                              <ToolTip title={inspectionDates.early}>
                                <InfoIcon />
                              </ToolTip>
                            </FlexRow>
                          </Box>
                        </FormFieldGroup>
                        <FormFieldGroup>
                          <Box flex={1} paddingTop={'18px'}>
                            <FlexRow justifyContent={''}>
                              <FormFieldSelectFormik
                                options={[
                                  { label: 'Inspection Date', value: 'inspection_date' },
                                  { label: 'Due Date', value: 'due_date' },
                                  { label: 'Next check date', value: 'next_check_at' }
                                ]}
                                name={'lateInspection'}
                                placeholder="Select option"
                                label={'Late Inspection Date'}
                                mandatory={true}
                                value={lateInspection}
                                width={'275px'}
                                onChange={(event) => {
                                  formikProps.handleChange(event)
                                }}
                              />
                              <ToolTip title={inspectionDates.late}>
                                <InfoIcon />
                              </ToolTip>
                            </FlexRow>
                          </Box>
                        </FormFieldGroup>
                        <FormFieldGroup>
                          <Box flex={1} paddingTop={'18px'}>
                            <FlexRow justifyContent={''}>
                              <FormFieldSelectFormik
                                options={[
                                  { label: 'Inspection Date', value: 'inspection_date' },
                                  { label: 'Due Date', value: 'due_date' },
                                  { label: 'Next check date', value: 'next_check_at' }
                                ]}
                                name={'dueInspection'}
                                placeholder="Select option"
                                label={'Inspect on time'}
                                mandatory={true}
                                value={dueInspection}
                                width={'275px'}
                                onChange={(event) => {
                                  formikProps.handleChange(event)
                                }}
                              />
                              <ToolTip title={inspectionDates.due}>
                                <InfoIcon />
                              </ToolTip>
                            </FlexRow>
                          </Box>
                        </FormFieldGroup>
                      </DialogContentText>
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions sx={{ position: '-webkit-sticky' }}>
                      <Box sx={{ marginRight: '8px' }}>
                        {' '}
                        <ButtonSecondary onClick={() => closeDialog()}>{t('global:cancel')}</ButtonSecondary>{' '}
                      </Box>
                      <ButtonPrimary
                        data-testid="next"
                        onClick={() => openPreview(event)}
                        disabled={!isFrequencyRuleSelected || !isFrequencyNameComplete || !notify || !notifyDate}>
                        Next
                      </ButtonPrimary>
                    </DialogActions>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </Dialog>
      {showExitConfirm && (
        <ConfirmDialog onCancel={() => setShowExitConfirm(false)} onConfirm={cancelOption}></ConfirmDialog>
      )}
    </>
  )
}

export default AddNewFrequency
