import {
  Alert,
  Box,
  ButtonPrimary,
  ContentHeader,
  ContentNonScrolling,
  ContentWrapper,
  FlexRow,
  NoData,
  SettingsTeamIcon,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery
} from '@papertrail/styleguide'
import { useApiGet } from '@papertrail/web3-utils'
import { useSessionUser } from '@papertrail/web3-session'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EditChecklist from './EditChecklist'
import CreateChecklist from './CreateChecklist'
import PublicIcon from '@mui/icons-material/Public'
import ConfirmDialog from './ConfirmDialog'

type Params = {
  accountid: string
}

const checklistMapper = (data) => {
  const checklists = data.data.map((n) => {
    return {
      id: n.id,
      name: n.name,
      global: n.global,
      description: n.description,
      sections: n.sections,
      meta: n.meta,
      global_checklist: null
    }
  })
  return checklists
}

const ChecklistEditor = () => {
  const { accountid } = useParams() as Params
  const user = useSessionUser()

  const [globalChecklistState, loadGlobalChecklists] = useApiGet<any>(`/checklists`, checklistMapper)

  const hasGlobal = (checklist) => {
    if (checklist.meta && !checklist.meta.customised && checklist.meta.cloned_from) {
      const foundGlobalChecklist = globalChecklistState.data.filter((item) => item.id === checklist.meta.cloned_from)
      if (foundGlobalChecklist.length > 0) {
        return foundGlobalChecklist[0]
      }
    }

    return null
  }

  const checklistGlobalMapper = (data) => {
    console.log(data)
    const checklists = checklistMapper(data)
    checklists.forEach((item) => {
      item.global_checklist = hasGlobal(item)
      console.log(item)
    })
    console.log(checklists)
    return checklists
  }

  const [checklistState, loadChecklists] = useApiGet<any>(`/accounts/${accountid}/checklists`, checklistGlobalMapper)
  const [availableChecklists, setAvailableChecklists] = useState<boolean>(false)
  const [checklist, setChecklist] = useState(undefined)
  const [showEdit, setShowEdit] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showGlobalConfirm, setShowGlobalConfirm] = useState(false)
  const { t } = useTranslation(['tools', 'global'])
  const [showSnackbar, setShowSnackbar] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)')
  const addNewChecklist = () => {
    setShowCreate(true)
  }

  const onCreateCompleted = () => {
    setShowCreate(false)
    setChecklist(undefined)
    loadGlobalChecklists({ limit: 1000 })
  }

  useEffect(() => {
    if (accountid) {
      loadGlobalChecklists({ limit: 1000 })
    }
  }, [accountid])

  useEffect(() => {
    loadChecklists({ limit: 1000 })
  }, [globalChecklistState])

  const openEditDialogue = (checklist) => {
    if (!checklist.global) {
      const isPapertrailStaff = user && user.isPapertrailEmployee
      if (isPapertrailStaff) {
        const hasGlobalChecklist = hasGlobal(checklist)
        if (hasGlobalChecklist) {
          checklist.global_checklist = hasGlobalChecklist
          // This gets the Global Variation of this Uncustomised Checklist
          setChecklist(checklist)
          setShowGlobalConfirm(true)
          return
        } else {
          setChecklist(checklist)
        }
      } else {
        setChecklist(checklist)
      }
    } else {
      setChecklist(checklist)
    }

    setShowEdit(true)
  }

  const onEditCompleted = () => {
    setShowSnackbar(true)
    setShowEdit(false)
    setChecklist(undefined)
    loadChecklists({ limit: 1000 })
  }

  return (
    <ContentWrapper>
      <ContentHeader>
        {'Settings > Checklists'}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setShowSnackbar(false)
          }}>
          <Alert data-testid="success-message" severity="success">
            Your checklist has been successfully saved!
          </Alert>
        </Snackbar>
      </ContentHeader>
      <ContentNonScrolling>
        <FlexRow justifyContent={'space-between'} sx={isMobile ? { paddingRight: '0px' } : { paddingRight: '120px' }}>
          <h3>{t('Checklists')}</h3>
          {!availableChecklists && (
            <ButtonPrimary onClick={() => addNewChecklist()}>
              {isMobile ? '+ Checklist' : 'Add New Checklist'}
            </ButtonPrimary>
          )}
          {user && user.isPapertrailEmployee && availableChecklists && (
            <ButtonPrimary onClick={() => addNewChecklist()} icon={<PublicIcon />}>
              {isMobile ? '+ Global Checklist' : 'Add New Global Checklist'}
            </ButtonPrimary>
          )}
        </FlexRow>
        <p>
          {t(
            !availableChecklists
              ? 'You can manage and customise your own checklists for this account.'
              : 'You can manage and customise all your global checklists across all accounts and subscriptions.'
          )}
        </p>
        <Box overflow="auto" sx={{ borderTop: 'solid 1px #E4E7EB' }}>
          <Table stickyHeader>
            <TableHead sx={{ borderBottom: 'solid 2px #E4E7EB' }}>
              <TableRow>
                <TableCell width={1}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '-17px' }}>
                    <Box
                      onClick={() => {
                        if (user && user.isPapertrailEmployee) {
                          setAvailableChecklists(!availableChecklists)
                        }
                      }}
                      style={{
                        paddingRight: '16px',
                        paddingLeft: '8px',
                        paddingBottom: '20px',
                        paddingTop: '16px',
                        cursor: 'pointer'
                      }}
                      sx={
                        !availableChecklists
                          ? { fontWeight: 'bold', borderBottom: 'solid 2px #007AFF' }
                          : { fontWeight: 'normal' }
                      }>
                      {t('Added Checklists')}
                      {checklistState && checklistState.isLoaded && ` (${checklistState.data.length})`}
                    </Box>
                    {user && user.isPapertrailEmployee && (
                      <Box
                        onClick={() => setAvailableChecklists(!availableChecklists)}
                        style={{ paddingBottom: '20px', paddingLeft: '8px', paddingTop: '16px', cursor: 'pointer' }}
                        sx={
                          availableChecklists
                            ? { fontWeight: 'bold', borderBottom: 'solid 2px #007AFF' }
                            : { fontWeight: 'normal' }
                        }>
                        {t('Global Checklists')}
                        {globalChecklistState &&
                          globalChecklistState.isLoaded &&
                          ` (${globalChecklistState.data.length})`}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!availableChecklists &&
                checklistState.isLoaded &&
                checklistState.data.map((checklist) => {
                  return (
                    <TableRow key={checklist.id}>
                      <TableCell width="100%">
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ paddingBottom: '8px', fontWeight: 'bold' }}>{checklist.name}</Box>
                          <Box>{checklist.description || ''}</Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ paddingRight: '120px' }}>
                        <FlexRow justifyContent="left">
                          <ButtonPrimary
                            data-testid={checklist.name}
                            onClick={() => openEditDialogue(checklist)}
                            icon={
                              <>{user && user.isPapertrailEmployee && checklist.global_checklist && <PublicIcon />}</>
                            }>
                            Edit
                          </ButtonPrimary>
                        </FlexRow>
                      </TableCell>
                    </TableRow>
                  )
                })}

              {user &&
                user.isPapertrailEmployee &&
                availableChecklists &&
                globalChecklistState.isLoaded &&
                globalChecklistState.data.map((checklist) => {
                  return (
                    <TableRow key={checklist.id}>
                      <TableCell width="100%">
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ paddingBottom: '8px', fontWeight: 'bold' }}>{checklist.name}</Box>
                          <Box>{checklist.description || ''}</Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ paddingRight: '120px' }}>
                        <FlexRow justifyContent="left">
                          <ButtonPrimary
                            data-testid={checklist.name}
                            onClick={() => openEditDialogue(checklist)}
                            icon={<PublicIcon />}>
                            Edit
                          </ButtonPrimary>
                        </FlexRow>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Box>
      </ContentNonScrolling>
      {checklistState.isLoaded && checklistState.data.length === 0 && (
        <NoData icon={SettingsTeamIcon} text="There are no available checklists in this account" />
      )}

      {checklist && (
        <EditChecklist
          show={showEdit}
          accountid={accountid}
          checklist={checklist}
          onCancel={() => setShowEdit(false)}
          onComplete={() => onEditCompleted()}
        />
      )}
      {showGlobalConfirm && (
        <ConfirmDialog
          type={'global'}
          onCancel={() => {
            setShowGlobalConfirm(false)
            setShowEdit(true)
          }}
          onConfirm={() => {
            setChecklist(checklist.global_checklist)
            setShowGlobalConfirm(false)
            setShowEdit(true)
          }}></ConfirmDialog>
      )}
      <CreateChecklist
        show={showCreate}
        accountid={accountid}
        global={availableChecklists}
        onCancel={() => setShowCreate(false)}
        onComplete={() => onCreateCompleted()}
      />
    </ContentWrapper>
  )
}

export default ChecklistEditor
