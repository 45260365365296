import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonPrimary,
  ButtonSecondary
} from '@papertrail/styleguide'

type Props = {
  onCancel: () => void
  onConfirm: () => void
}

export default function ConfirmDialog(props: Props) {
  const { onCancel, onConfirm } = props
  return (
    <Dialog open={true}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>Are you sure you want to exit this filter? All changes will be lost.</DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
        <ButtonPrimary onClick={onConfirm} data-testid="confirm">
          Confirm
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}
