import React from 'react'
import { Box } from '@papertrail/styleguide'
import { Filter, Rule, State, Tag, Blueprint, Field } from '../../types'
import InspectionRule from './InspectionRule'
import StateRule from './StateRule'
import TagRule from './TagRule'
import ComparisonFilter from './ComparisonFilter'
import FieldFilter from './FieldFilter'
import TaskRule from './TaskRule'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  rule: Rule
  blueprint: Blueprint
  field: Field[]
  allTags: Tag[]
  allStates: State[]

  onCancel: () => void
  onComplete: (value) => void
}

export default function EditRule(props: Props) {
  const { rule, allStates, filter, allTags, field, onCancel, onComplete } = props

  function renderRule() {
    switch (rule.key) {
      case 'inspection': {
        return (
          <InspectionRule
            show={true}
            accountid={filter.id}
            filter={filter}
            rule={rule}
            onCancel={onCancel}
            onComplete={(value) => onComplete(value)}
          />
        )
      }

      case 'task_active': {
        return (
          <TaskRule
            show={true}
            accountid={filter.id}
            filter={filter}
            rule={rule}
            onCancel={onCancel}
            onComplete={(value) => onComplete(value)}
          />
        )
      }

      case 'state': {
        return (
          <StateRule
            show={rule.active}
            accountid={filter.id}
            filter={filter}
            rule={rule}
            onCancel={onCancel}
            onComplete={(value) => onComplete(value)}
            allStates={allStates}
          />
        )
      }
      case 'tag': {
        return (
          <TagRule
            show={rule.active}
            accountid={filter.id}
            filter={filter}
            rule={rule}
            allTags={allTags}
            onCancel={onCancel}
            onComplete={(value) => onComplete(value)}
          />
        )
      }
      case 'field_filter':
      case 'field-filter': {
        return (
          <FieldFilter
            show={rule.active}
            accountid={filter.id}
            filter={filter}
            rule={rule}
            blueprint={props.blueprint}
            field={field}
            allStates={allStates}
            onCancel={onCancel}
            onComplete={(value) => onComplete(value)}
          />
        )
      }
      case 'filter': {
        return (
          <ComparisonFilter
            show={rule.active}
            accountid={filter.id}
            filter={filter}
            rule={rule}
            blueprint={props.blueprint}
            field={field}
            allStates={allStates}
            onCancel={onCancel}
            onComplete={(value) => onComplete(value)}
          />
        )
      }

      default: {
        break
      }
    }
  }

  return (
    <div>
      <Box padding={2}>{rule.active && renderRule()}</Box>
    </div>
  )
}
