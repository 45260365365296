import React, { useEffect, useState } from 'react'
import { Filter, Rule, State, Blueprint, Field } from '../../types'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'

import {
  Formik,
  Box,
  CloseIcon,
  CheckIcon,
  IconButton,
  FormFieldSelectFormik,
  FormFieldText,
  FlexRow
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  rule: Rule
  blueprint: Blueprint
  field: Field[]
  allStates: State[]
  onCancel: () => void
  onComplete: (values) => void
}

export default function ComparisonFilter(props: Props) {
  const { field, onCancel } = props
  const [operArr, setOperArr] = useState([])
  const [selectedDateType, setSelectedDateType] = useState('')
  const [selectedtextType, setSelectedtextType] = useState('')
  const [selectedNumberType, setSelectedNumberType] = useState('')

  function getDateTypeFromString(index: number) {
    const matches = props.rule.value.match(/[DWMY]/g)
    if (matches && matches[index]) {
      return matches[index] || null
    } else {
      return null
    }
  }

  function getFirstFieldFromRules() {
    const firstValue = props.rule.value.split(/[@=<>]/g).find((op) => op.length > 0)
    return firstValue
  }

  function getSecondFieldFromRules() {
    const nextValue = props.rule.value.split(/[@=<>]/g).pop()
    if (nextValue.includes('P')) {
      const numbersArray = nextValue.match(/\d+/g)
      if (numbersArray && numbersArray.length > 0) {
        return parseInt(numbersArray[0], 10)
      }
    } else {
      return nextValue
    }
  }

  function getThirdFieldFromRules() {
    const thirdValue = props.rule.value.split(/[@<>=]/g).pop()

    const match = thirdValue.match(/\/P(\d+)/)

    if (match && match[1]) {
      return parseInt(match[1], 10)
    }
  }

  const initialValues = {
    id: props.rule.id || uuid(),
    key: props.rule.key || 'filter',
    active: props.rule.active || false,
    fieldFilterFirst: getFirstFieldFromRules() || '',
    fieldFilterSecond: getSecondFieldFromRules(),
    fieldFilterThird: getThirdFieldFromRules() || '',
    operator: getOperatorFromString() || '',
    dateTypeOne: getDateTypeFromString(0) || '',
    dateTypeTwo: getDateTypeFromString(1) || ''
  }

  function extractNumericValue(value) {
    const numericValue = parseFloat(value)
    return isNaN(numericValue) ? null : numericValue
  }

  function convertToDays(value, unitOfMeasurement) {
    const daysInWeek = 7
    const daysInMonth = 30
    const daysInYear = 365

    switch (unitOfMeasurement) {
      case 'W':
        return value * daysInWeek
      case 'M':
        return value * daysInMonth
      case 'Y':
        return value * daysInYear
      default:
        return value
    }
  }

  function getFieldTypeByKey(type: string) {
    const fieldObj = props.field.find((el) => el.type === 'date')
    return fieldObj ? fieldObj.type : ''
  }

  const validationSchema = yup.object({
    fieldFilterFirst: yup.string().required('Field is required'),
    fieldFilterSecond: yup.string().required('Field is required'),
    operator: yup
      .string()
      .required('Operator is required')
      .matches(/^(=|<|>|>=|<=|<>|~=)$/, 'Please enter a valid operator'),
    fieldFilterThird: yup.string().when(['operator', 'fieldFilterFirst'], {
      is: (operator, fieldFilterFirst) => operator === '<>',
      then: yup
        .string()
        .required('Field is required')
        .test('is-greater-than-first', 'Second field must be greater than the first field', function (value) {
          const { fieldFilterSecond, operator } = this.parent // Destructure operator from parent object
          const fieldType = getFieldTypeByKey(fieldFilterSecond)
          if (operator === '<>' && fieldType === 'date') {
            // Check if fieldType is 'date'
            const { fieldFilterSecond, dateTypeOne, dateTypeTwo } = this.parent
            const firstNumericValue = extractNumericValue(fieldFilterSecond)
            const secondNumericValue = extractNumericValue(value)

            // Convert the values to a common unit of measurement, e.g., days
            const convertedFirstValue = convertToDays(firstNumericValue, dateTypeOne)
            const convertedSecondValue = convertToDays(secondNumericValue, dateTypeTwo)

            return convertedSecondValue > convertedFirstValue
          }
          return true
        })
    })
  })

  const operators = [
    { name: 'equal to', icon: '=', type: ['identifier', 'rfid', 'barcode', 'string', 'integer', 'money', 'date'] },
    { name: 'fuzzy match', icon: '~=', type: ['identifier', 'rfid', 'barcode', 'string'] },
    { name: 'greater than', icon: '>', type: ['integer', 'money', 'date'] },
    { name: 'greater or equal to', icon: '>=', type: ['integer', 'money', 'date'] },
    { name: 'less than', icon: '<', type: ['integer', 'money', 'date'] },
    { name: 'less or equal to', icon: '<=', type: ['integer', 'money', 'date'] },
    { name: 'between', icon: '<>', type: ['date', 'integer'] }
  ]

  function getOperatorFromString() {
    const myOperators = ['=', '>', '<', '~=', '<=', '<>']
    let myOperator = null
    myOperators.map((oper) => {
      if (props.rule.value.includes(oper)) {
        myOperator = oper
      }
      if (props.rule.value.includes('~=') && oper.includes('~=')) {
        myOperator = oper
      }
    })
    return myOperator
  }

  function setField(value) {
    setSelectedDateType('')
    setSelectedNumberType('')
    setSelectedtextType('')
    const myField = field.find((el) => el.key === value)

    if (myField) {
      const filteredOperators = operators.filter((operator) => operator.type.includes(myField.type))
      const operatorSelectValues = filteredOperators.map((operator) => {
        return {
          label: `${operator.icon} (${operator.name}) `,
          value: operator.icon
        }
      })

      setOperArr(operatorSelectValues)

      if (myField.type === 'date') {
        setSelectedDateType('date')
      }
      if (
        myField.type === 'string' ||
        myField.type === 'identifier' ||
        myField.type === 'barcode' ||
        myField.type === 'rfid'
      ) {
        setSelectedtextType('text')
      } else {
        setSelectedtextType('')
      }
      if (myField.type === 'integer' || myField.type === 'money') {
        setSelectedNumberType('integer')
      }
    }
  }

  function submitForm(values) {
    const newValues = values
    newValues.operator = values.operator
    newValues.dateTypeOne = values.dateTypeOne
    newValues.dateTypeTwo = values.dateTypeTwo
    newValues.fieldFilterFirst = values.fieldFilterFirst
    newValues.fieldFilterSecond = values.fieldFilterSecond
    newValues.fieldFilterThird = values.fieldFilterThird
    newValues.active = false
    newValues.label = 'Field Comparison Filter: '

    if (selectedDateType && selectedDateType === 'date' && values.operator === '<>') {
      newValues.label +=
        values.fieldFilterFirst +
        values.operator +
        'P' +
        values.fieldFilterSecond +
        values.dateTypeOne +
        '/P' +
        values.fieldFilterThird +
        values.dateTypeTwo
      newValues.value =
        values.fieldFilterFirst +
        values.operator +
        'P' +
        values.fieldFilterSecond +
        values.dateTypeOne +
        '/P' +
        values.fieldFilterThird +
        values.dateTypeTwo
    } else if (selectedDateType && selectedDateType === 'date') {
      newValues.label += values.fieldFilterFirst + values.operator + 'P' + values.fieldFilterSecond + values.dateTypeOne
      newValues.value = values.fieldFilterFirst + values.operator + 'P' + values.fieldFilterSecond + values.dateTypeOne
    } else if (selectedNumberType && selectedNumberType === 'integer' && values.operator === '<>') {
      newValues.label +=
        values.fieldFilterFirst + values.operator + values.fieldFilterSecond + '/' + values.fieldFilterThird
      newValues.value =
        values.fieldFilterFirst + values.operator + values.fieldFilterSecond + '/' + values.fieldFilterThird
    } else {
      newValues.label += values.fieldFilterFirst + values.operator + values.fieldFilterSecond
      newValues.value = values.fieldFilterFirst + values.operator + values.fieldFilterSecond
    }
    props.onComplete(newValues)
  }

  function closeDialog() {
    onCancel()
  }

  useEffect(() => {
    if (selectedDateType === 'date') {
    }

    if (selectedtextType === 'text') {
    }

    if (selectedNumberType === 'integer') {
    }
  }, [selectedDateType, selectedtextType, selectedNumberType])

  useEffect(() => {
    setField(initialValues.fieldFilterFirst)
  }, [initialValues.fieldFilterFirst])

  const resetDropdowns = (formikProps) => {
    formikProps.setFieldValue('operator', '')
    formikProps.setFieldValue('fieldFilterSecond', '')
    formikProps.setFieldValue('fieldFilterThird', '')
    formikProps.setFieldValue('dateTypeOne', '')
    formikProps.setFieldValue('dateTypeTwo', '')
  }

  const checkValue = (value) => {
    const type = typeof value
    switch (type) {
      case 'number':
        return value >= 0
      case 'string':
        return value !== ''
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => console.log(values, 'onsubmit values')}>
      {(formikProps) => (
        <Box>
          <div>
            <Box>
              <FormFieldSelectFormik
                options={field
                  .filter((field, index, self) => self.findIndex((f) => f.key === field.key) === index)
                  .map((field) => ({
                    label: field.label,
                    value: field.key
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                name={'fieldFilterFirst'}
                label={''}
                placeholder={'Select field *'}
                value={formikProps.values.fieldFilterFirst}
                onChange={(event) => {
                  formikProps.handleChange(event)
                  setField(event.target.value)
                  resetDropdowns(formikProps)
                }}
              />
            </Box>

            <>
              {operArr && operArr.length > 0 && (
                <Box>
                  <FormFieldSelectFormik
                    options={operArr}
                    name={'operator'}
                    label={''}
                    placeholder={'Select operator *'}
                    value={formikProps.values.operator}
                    onChange={(event) => {
                      formikProps.handleChange(event)
                    }}
                  />
                </Box>
              )}
            </>

            <FlexRow justifyContent={'space-between'}>
              {formikProps.values.operator && formikProps.values.operator !== '<>' && (
                <>
                  {selectedtextType === 'text' && (
                    <Box maxWidth={'200px'}>
                      <FormFieldText
                        label={''}
                        placeholder={`Enter ${formikProps.values.fieldFilterFirst} *`}
                        name="fieldFilterSecond"
                      />
                    </Box>
                  )}

                  {selectedDateType === 'date' && (
                    <>
                      <Box width="200px" marginRight={1}>
                        <FormFieldText
                          label={''}
                          placeholder={`Enter number *`}
                          name="fieldFilterSecond"
                          type="number"
                          min={0}
                          max={100}
                        />
                      </Box>

                      <FormFieldSelectFormik
                        options={[
                          { label: 'Day(s)', value: 'D' },
                          { label: 'Week(s)', value: 'W' },
                          { label: 'Month(s)', value: 'M' },
                          { label: 'Year(s)', value: 'Y' }
                        ]}
                        name={'dateTypeOne'}
                        label={''}
                        placeholder={'Select date type *'}
                        value={formikProps.values.dateTypeOne}
                        onChange={(event) => {
                          formikProps.handleChange(event)
                        }}
                      />
                    </>
                  )}

                  {selectedNumberType === 'integer' && (
                    <Box>
                      <FormFieldText
                        label={''}
                        placeholder={`Enter ${formikProps.values.fieldFilterFirst} *`}
                        name="fieldFilterSecond"
                      />
                    </Box>
                  )}

                  <Box
                    visibility={checkValue(formikProps.values.fieldFilterSecond) ? 'visible' : 'hidden'}
                    marginBottom={2}
                    display={'flex'}>
                    <IconButton
                      id="basic-button"
                      size="large"
                      onClick={closeDialog}
                      sx={{
                        backgroundColor: '#fdebec',
                        color: '#eb3745',
                        borderRadius: '0px',
                        margin: '0 8px'
                      }}>
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      id="basic-button"
                      size="large"
                      onClick={() => submitForm(formikProps.values)}
                      color={'primary'}
                      sx={{
                        backgroundColor: '#e5f2ff',
                        color: '#007aff',
                        borderRadius: '0px',
                        margin: '0 8px'
                      }}>
                      <CheckIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </FlexRow>

            {formikProps.values.operator && formikProps.values.operator === '<>' && (
              <>
                {selectedDateType === 'date' && (
                  <>
                    <FlexRow justifyContent={'flex-start'}>
                      <>
                        <Box width="125px" marginRight={1}>
                          <FormFieldText
                            label={'From'}
                            placeholder={'Enter number'}
                            name="fieldFilterSecond"
                            type="number"
                            min={0}
                            max={100}
                          />
                        </Box>
                        <Box marginTop={'18px'}>
                          <FormFieldSelectFormik
                            options={[
                              { label: 'Day(s)', value: 'D' },
                              { label: 'Week(s)', value: 'W' },
                              { label: 'Month(s)', value: 'M' },
                              { label: 'Year(s)', value: 'Y' }
                            ]}
                            name={'dateTypeOne'}
                            label={''}
                            placeholder={'Select date type *'}
                            value={formikProps.values.dateTypeOne}
                            onChange={(event) => {
                              formikProps.handleChange(event)
                            }}
                          />
                        </Box>
                      </>
                    </FlexRow>

                    <FlexRow justifyContent={'space-between'} sx={{ marginBottom: '40px' }}>
                      <Box display={'flex'}>
                        <Box width="125px" marginRight={1}>
                          <FormFieldText
                            label={'To'}
                            placeholder={'Enter number *'}
                            name="fieldFilterThird"
                            type="number"
                            min={0}
                            max={100}
                          />
                        </Box>
                        <Box marginTop={'18px'}>
                          <FormFieldSelectFormik
                            options={[
                              { label: 'Day(s)', value: 'D' },
                              { label: 'Week(s)', value: 'W' },
                              { label: 'Month(s)', value: 'M' },
                              { label: 'Year(s)', value: 'Y' }
                            ]}
                            name={'dateTypeTwo'}
                            label={''}
                            placeholder={'Select date type *'}
                            value={formikProps.values.dateTypeTwo}
                            onChange={(event) => {
                              formikProps.handleChange(event)
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        visibility={checkValue(formikProps.values.fieldFilterSecond) ? 'visible' : 'hidden'}
                        marginBottom={2}
                        display={'flex'}>
                        <IconButton
                          id="basic-button"
                          size="large"
                          onClick={closeDialog}
                          sx={{
                            backgroundColor: '#fdebec',
                            color: '#eb3745',
                            borderRadius: '0px',
                            margin: '0 8px'
                          }}>
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          id="basic-button"
                          size="large"
                          onClick={() => submitForm(formikProps.values)}
                          color={'primary'}
                          sx={{
                            backgroundColor: '#e5f2ff',
                            color: '#007aff',
                            borderRadius: '0px',
                            margin: '0 8px'
                          }}>
                          <CheckIcon />
                        </IconButton>
                      </Box>
                    </FlexRow>
                  </>
                )}
              </>
            )}

            {selectedNumberType === 'integer' && formikProps.values.operator === '<>' && (
              <>
                <FlexRow justifyContent="space-between">
                  <Box display="flex">
                    <Box width="125px" paddingRight={2}>
                      <FormFieldText
                        label={'From'}
                        placeholder={'Enter number *'}
                        name="fieldFilterSecond"
                        type="number"
                        min={0}
                        max={100}
                      />
                    </Box>
                    <Box width="125px">
                      <FormFieldText
                        label={'To'}
                        placeholder={'Enter number *'}
                        name="fieldFilterThird"
                        type="number"
                        min={0}
                        max={100}
                      />
                    </Box>
                  </Box>
                  <Box
                    visibility={checkValue(formikProps.values.fieldFilterSecond) ? 'visible' : 'hidden'}
                    display={'flex'}>
                    <IconButton
                      id="basic-button"
                      size="large"
                      onClick={closeDialog}
                      sx={{
                        backgroundColor: '#fdebec',
                        color: '#eb3745',
                        borderRadius: '0px',
                        margin: '0 8px'
                      }}>
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      id="basic-button"
                      size="large"
                      onClick={() => submitForm(formikProps.values)}
                      color={'primary'}
                      sx={{
                        backgroundColor: '#e5f2ff',
                        color: '#007aff',
                        borderRadius: '0px',
                        margin: '0 8px'
                      }}>
                      <CheckIcon />
                    </IconButton>
                  </Box>
                </FlexRow>
              </>
            )}
          </div>
        </Box>
      )}
    </Formik>
  )
}
