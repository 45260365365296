import React, { useEffect, useState } from 'react'
import {
  AlertWarning,
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Form,
  FormFieldText,
  Formik,
  Box,
  FieldArray,
  CloseIcon,
  FlexRow,
  Divider,
  Select,
  MenuItem,
  useMediaQuery
} from '@papertrail/styleguide'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '@papertrail/web3-utils'
import * as yup from 'yup'
import EditSection from './EditSection'
import { ScrollToFieldError } from './ScrolltoError'
import ConfirmDialog from './ConfirmDialog'
import PublicIcon from '@mui/icons-material/Public'

type Props = {
  show: boolean
  accountid: string
  global: boolean
  onCancel: () => void
  onComplete: () => void
}

const CreateChecklist = (props: Props) => {
  const url = props.global ? '/checklists' : `/accounts/${props.accountid}/checklists`
  const [newState, newChecklist] = useApiPost(url, (data) => data)
  const [newSection, setSection] = useState<any>(null)
  const [activeSection, setActiveSection] = useState<string>('')
  const [newSectionCounter, setNewSectionCounter] = useState<number>(0)
  const [formikErrors, setFormikErrors] = useState(null)
  const [errorSection, setErrorSection] = useState<any>(null)
  const [showExitConfirm, setShowExitConfirm] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width:600px)')

  const { t } = useTranslation(['tools', 'global'])
  const styles = {
    select: {
      width: '200px',
      height: '45px'
    }
  }
  const initialValues = {
    name: '',
    description: '',
    sections: [
      {
        id: `New Section`,
        label: '',
        description: '',
        fields: [],
        position: '1'
      }
    ]
  }

  const closeDialog = () => {
    reset()
    props.onCancel()
  }

  const reset = () => {
    setSection(null)
    setActiveSection('')
    setErrorSection(null)
    setNewSectionCounter(0)
  }

  useEffect(() => {
    if (newState.isLoaded) {
      reset()
      props.onComplete()
    }
  }, [newState])

  const getErrorText = () => {
    if (newState.isError) {
      return 'There has been an error saving your checklist, please try again.'
    }
  }

  const validationSchema = yup.object({
    name: yup.string().required(t('error Checklist Name Required')),
    description: yup.string().nullable(),
    sections: yup.array().of(
      yup.object({
        label: yup.string().required(t('error Section Name Required')),
        description: yup.string().nullable(),
        fields: yup.array().of(
          yup.object({
            label: yup.string().required(t('error Field Name Required')),
            description: yup.string().nullable(),
            options: yup
              .array()
              .of(
                yup.object({
                  value: yup.string().required('error Value Required'),
                  sentiment: yup.string().required('error Sentiment Required')
                })
              )
              .nullable(),
            sentiment: yup.string().nullable(),
            type: yup.string().required('error Type required')
          })
        )
      })
    )
  })

  const addSection = (position) => {
    setSection(null)
    let currentCounter = newSectionCounter
    currentCounter++
    setNewSectionCounter(currentCounter)
    const section = {
      id: `New Section (${currentCounter})`,
      label: '',
      description: '',
      fields: [],
      position: position.toString()
    }
    setSection(section)
    setActiveSection(section.id)
  }

  const onSelectChange = (id: string) => {
    setSection(null)
    setActiveSection(id)
  }

  const submit = (values) => {
    let sectionPosition = 1
    const newSections = values.sections.map((section) => {
      if (section.id.includes('New Section')) {
        return {
          ...section,
          id: '',
          position: sectionPosition++
        }
      } else {
        return {
          ...section,
          position: sectionPosition++
        }
      }
    })
    let currentPosition = 1
    newSections.map((section) => {
      section.fields.map((field, index) => {
        field.position = currentPosition
        currentPosition++
      })
    })
    const newValues = {
      ...values,
      sections: newSections
    }
    newChecklist(newValues)
  }

  const confirmExit = () => {
    setShowExitConfirm(false)
    closeDialog()
  }

  useEffect(() => {
    if (formikErrors) {
      const firstError = formikErrors[0].split('.')
      setErrorSection(firstError)
    } else {
      setErrorSection(null)
    }
  }, [formikErrors])

  return (
    <>
      <Dialog
        open={props.show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={isMobile}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          validateOnChange={false}>
          {(formikProps) => (
            <Form>
              <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: '20px', position: '-webkit-sticky' }}>
                <FlexRow justifyContent="space-between" alignItems="center">
                  <FlexRow justifyContent="space-between" alignItems="left" sx={{ flexDirection: 'column' }}>
                    <Box>{t(props.global ? 'Global Checklist Creator' : 'Checklist Creator')}</Box>
                    <Box sx={{ fontSize: '13px', fontWeight: '400', color: '#69707d', textTransform: 'none' }}>
                      {t('You can manage and edit checklists')}
                    </Box>
                  </FlexRow>

                  <CloseIcon fontSize="large" onClick={() => setShowExitConfirm(true)} sx={{ cursor: 'pointer' }} />
                </FlexRow>
              </DialogTitle>
              <Divider />
              <DialogContent sx={{ height: '60vh', overflow: 'auto' }}>
                {newState.isError && !newState.isLoading && (
                  <Box paddingBottom={1}>
                    <AlertWarning>{getErrorText()}</AlertWarning>
                  </Box>
                )}
                {errorSection !== null && !newState.isLoading && (
                  <Box paddingBottom={1}>
                    <AlertWarning>{'There has been a validation error.'}</AlertWarning>
                  </Box>
                )}

                <ScrollToFieldError onComplete={(errors) => setFormikErrors(errors)} />
                <FormFieldText
                  label={t('Checklist Name')}
                  placeholder={t('Add Checklist Name here')}
                  name="name"
                  data-testid="checklist-name"
                />
                <FormFieldText
                  label={t('Description')}
                  placeholder={t('Add Description here')}
                  name="description"
                  data-testid="checklist-description"
                />
                {formikProps.values && formikProps.values.sections && formikProps.values.sections.length > 1 && (
                  <div
                    style={{
                      position: 'sticky',
                      top: '-20px',
                      background: 'white',
                      zIndex: 1000,
                      paddingTop: '8px',
                      paddingBottom: '8px'
                    }}>
                    <FlexRow justifyContent="left" sx={{ flexDirection: 'column', alignItems: 'left' }}>
                      <Box sx={{ paddingBottom: '8px', fontSize: '13px', color: '#69707d' }}>Select Section</Box>
                      <Select
                        data-testid="section-select"
                        onChange={(e) => onSelectChange(e.target.value)}
                        sx={styles.select}
                        value={activeSection}
                        displayEmpty>
                        <MenuItem value="" disabled>
                          Select Section
                        </MenuItem>
                        {formikProps.values.sections.map((section, index) => (
                          <MenuItem key={section.id} value={section.id}>
                            {section.label || 'Section ' + section.position}
                          </MenuItem>
                        ))}
                      </Select>
                    </FlexRow>
                  </div>
                )}

                <div
                  style={
                    isMobile ? { marginTop: '4px', marginBottom: '4px' } : { marginTop: '28px', marginBottom: '28px' }
                  }></div>

                <FieldArray name={'sections'}>
                  {(arrayHelpers) => {
                    return (
                      <>
                        <EditSection
                          sectionArrayHelpers={arrayHelpers}
                          activeSection={activeSection}
                          selectedSection={newSection}
                          error={errorSection}></EditSection>
                      </>
                    )
                  }}
                </FieldArray>
              </DialogContent>
              <DialogActions sx={{ position: '-webkit-sticky' }}>
                <div style={isMobile ? { marginRight: '0px' } : { marginRight: '220px' }}>
                  <ButtonPrimary
                    data-testid="save_changes"
                    onClick={() => addSection(formikProps.values.sections.length + 1)}
                    loading={newState.isLoading}>
                    {t('Add New Section')}
                  </ButtonPrimary>
                </div>
                <ButtonSecondary onClick={() => setShowExitConfirm(true)}>{t('global:cancel')}</ButtonSecondary>
                {!props.global && (
                  <ButtonPrimary data-testid="save_changes" isSubmit loading={newState.isLoading}>
                    {isMobile ? 'Save' : 'Save Checklist'}
                  </ButtonPrimary>
                )}{' '}
                {props.global && (
                  <ButtonPrimary data-testid="save_changes" isSubmit loading={newState.isLoading} icon={<PublicIcon />}>
                    {isMobile ? 'Save Global' : 'Save Global Checklist'}
                  </ButtonPrimary>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      {showExitConfirm && (
        <ConfirmDialog
          type={'checklist'}
          onCancel={() => setShowExitConfirm(false)}
          onConfirm={confirmExit}></ConfirmDialog>
      )}
    </>
  )
}

export default CreateChecklist
