import { DragIndicator as DragIcon } from '@mui/icons-material'
import { ChildrenProps } from '@papertrail/styleguide/src/types'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'

type Props = ChildrenProps & {
  id: string
  index: number
  type: string
}

export const Drag = (props: Props) => {
  const { id, index, type } = props
  const [dragDisabled, setDragDisabled] = useState<boolean>(false)

  useEffect(() => {
    const handleExpandedPanel = (e: CustomEvent) => {
      if (e.detail.index === index && e.detail.expanded === 'expanded' && e.detail.type === type) {
        setDragDisabled(true)
      }
      if (e.detail.index === index && e.detail.expanded === 'closed' && e.detail.type === type) {
        setDragDisabled(false)
      }
    }
    window.addEventListener('expandedPanel', handleExpandedPanel)

    return () => {
      window.removeEventListener('expandedPanel', handleExpandedPanel)
    }
  }, [index, type])

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={dragDisabled}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                paddingLeft: '2px',
                width: '100%'
              }}>
              {!dragDisabled && (
                <div
                  {...provided.dragHandleProps}
                  style={{
                    flex: '0.05',
                    background: '#E5F2FF',
                    height: '52px',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRight: 'none',
                    alignItems: 'center'
                  }}>
                  <DragIcon />
                </div>
              )}
              {dragDisabled && <div {...provided.dragHandleProps}></div>}

              <div style={{ flex: '1', width: '90%' }}>{props.children}</div>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}
