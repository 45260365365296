import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApiGet, useApiPost, useTitle, arrayMove } from '@papertrail/web3-utils'

import './FrequencyManager.css'
import {
  AlertWarning,
  Box,
  ButtonPrimary,
  ContentHeader,
  Loading,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FlexRow,
  ContentNonScrolling,
  ContentWrapper,
  DragIcon,
  Label,
  LongMenu,
  ToolTip,
  Alert,
  Snackbar
} from '@papertrail/styleguide'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { useTranslation } from 'react-i18next'
import { Frequency } from 'src/types'
import AddNewFrequency from './AddNewFrequency'
import EditFrequency from './EditFrequency'
import DeleteDialogue from './DeleteDialogue'
import SetDefaultDialogue from './SetDefaultDialogue'

type Params = {
  accountid: string
}

const frequenciesMapper = (data) => {
  const frequencies = data.data.map((frequency) => {
    return {
      id: frequency.id,
      name: frequency.name,
      rule: frequency.rule,
      notify: frequency.notify,
      default: frequency.default,
      order: frequency.order,
      dateStartOffset: frequency.date_start_offset,
      options: frequency.options,
      defaultOptions: frequency.default_options
    }
  })
  frequencies.sort((a, b) => a.order - b.order)
  return frequencies
}

const FrequencyManager = () => {
  const { accountid } = useParams() as Params
  const [frequencies, setFrequencies] = useState(null)
  const [dragId, setDragId] = useState(undefined)
  const [sortedStates, setSortedStates] = useState([])
  const [openNewFrequency, setOpenNewFrequency] = useState(false)
  const [openEditFrequency, setOpenEditFrequency] = useState(false)
  const [selectedFrequency, setSelectedFrequency] = useState<Frequency>(null)
  const [showDelete, setShowDelete] = useState(false)
  const [showDefault, setShowDefault] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)

  const [frequencyState, loadFrequencies] = useApiGet<Frequency[]>(`/accounts/${accountid}/frequencies`, (data) => {
    const frequencies = frequenciesMapper(data)
    setFrequencies(frequencies)
    return frequencies
  })

  const { t } = useTranslation(['tools', 'global'])
  useTitle(t('frequencyManagerTitle'))

  useEffect(() => {
    loadFrequencies({ limit: 1000 })
  }, [])

  useEffect(() => {
    if (frequencyState.isLoaded) {
      const sortedFrequencies = frequencyState.data.sort((a, b) => a.order - b.order)
      setSortedStates(sortedFrequencies)
    }
  }, [frequencyState])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [moveState, postMove] = useApiPost(`/accounts/${accountid}/frequencies/${dragId}/actions/move`, (data) => data)

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const updatedStates = arrayMove(sortedStates, result.source.index, result.destination.index)
    setSortedStates(updatedStates)
    const updatedFrequencies = updatedStates.map((frequency, index) => ({
      ...frequency,
      order: index + 1
    }))
    const payload = {
      updatedFrequencies,
      position: result.destination.index + 1
    }
    postMove(payload)
  }

  const handleDragStart = (result) => {
    setDragId(result.draggableId)
  }

  const handleEscapeKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenNewFrequency(false)
      setOpenEditFrequency(false)
      setShowDelete(false)
    }
  }, [])

  useEffect(() => {
    if (openNewFrequency || openEditFrequency || showDelete) {
      document.addEventListener('keydown', handleEscapeKeyPress)
    } else {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [openNewFrequency, openEditFrequency, showDelete, handleEscapeKeyPress])

  const openNewfrequencyDialog = () => {
    setOpenNewFrequency(true)
  }

  const openEditDialogue = (frequency) => {
    setSelectedFrequency(frequency)
    setOpenEditFrequency(true)
  }

  const handleSetAsDefault = (frequency) => {
    if (frequency.default === true) {
      return
    }

    setSelectedFrequency(frequency || null)
    setShowDefault(true)
  }

  const handleDelete = (frequency) => {
    if (frequency.name === 'None' || frequency.default === true) {
      return
    }
    setSelectedFrequency(frequency || null)
    setShowDelete(true)
  }

  const handleInput = (value: string, frequency) => {
    switch (value) {
      case 'Delete':
        handleDelete(frequency)
        break
      case 'Set as default':
        handleSetAsDefault(frequency)
        break
    }
  }

  const closeDeleteDialog = () => {
    setShowDelete(false)
    loadFrequencies({ limit: 1000 })
  }

  const closeDefaultDialog = () => {
    setShowDefault(false)
    loadFrequencies({ limit: 1000 })
  }

  const onEditComplete = () => {
    setShowSnackbar(true)
    setOpenEditFrequency(false)
    setSelectedFrequency(null)
    loadFrequencies({ limit: 1000 })
  }

  const onAddNewComplete = () => {
    setShowSnackbar(true)
    setOpenNewFrequency(false)
    loadFrequencies({ limit: 1000 })
  }

  return (
    <ContentWrapper>
      <ContentHeader>Frequency Manager</ContentHeader>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowSnackbar(false)
        }}>
        <Alert data-testid="success-message" severity="success">
          Your frequency has been successfully saved!
        </Alert>
      </Snackbar>
      <ContentNonScrolling>
        <FlexRow justifyContent={'space-between'}>
          <h3>Frequency Manager</h3>
          <ButtonPrimary onClick={openNewfrequencyDialog}>Add frequency</ButtonPrimary>
        </FlexRow>
        <p>You can add and manage frequencies on your account. Only administrators can edit the frequencies.</p>
        {frequencyState.isLoading && <Loading />}
        {frequencyState.isError && <AlertWarning>Sorry there was an error loading the frequencies</AlertWarning>}
        <Box overflow={'auto'}>
          <Table stickyHeader sx={{ minWidth: 650, marginBottom: '50px' }} aria-label={'frequency'}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t('frequency')}</TableCell>
                {/* <TableCell>{t('recordCount')}</TableCell> */}
                <TableCell align="right" style={{ paddingRight: '39px' }}>
                  {t('actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <Droppable droppableId="frequencies">
                {(provided) => (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                    {sortedStates.map((frequency, index) => (
                      <Draggable key={frequency.id} draggableId={frequency.id} index={index}>
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? '#f8f8f8' : 'inherit',
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}
                            key={frequency.id}>
                            <TableCell width={'5%'}>
                              <DragIcon />
                            </TableCell>

                            <TableCell>
                              {frequency.name}
                              <Box sx={{ width: '60px' }}>
                                {frequency.default && <Label data-testid="default" text="Default" variant="warning" />}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <div className="actions">
                                <ToolTip title={frequency.name === 'None' ? 'You cannot edit this frequency' : ''}>
                                  <span>
                                    <ButtonPrimary
                                      data-testid="edit"
                                      disabled={frequency.name === 'None'}
                                      onClick={() => openEditDialogue(frequency)}>
                                      Edit
                                    </ButtonPrimary>
                                  </span>
                                </ToolTip>
                                <ToolTip
                                  title={
                                    frequency.name === 'None' || frequency.default === true
                                      ? 'You cannot delete this frequency'
                                      : ''
                                  }>
                                  <span>
                                    <LongMenu
                                      data-testid="long-menu"
                                      options={['Delete', 'Set as default']}
                                      valueChangeCallback={(value) => handleInput(value, frequency)}
                                      mode="ellipsis"></LongMenu>
                                  </span>
                                </ToolTip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </Box>
      </ContentNonScrolling>
      {frequencies && frequencyState.isLoaded && (
        <AddNewFrequency
          show={openNewFrequency}
          accountid={accountid}
          frequency={frequencyState.data}
          onCancel={() => setOpenNewFrequency(false)}
          onComplete={() => onAddNewComplete()}
        />
      )}

      <EditFrequency
        show={openEditFrequency}
        accountid={accountid}
        frequency={selectedFrequency}
        onCancel={() => {
          setOpenEditFrequency(false)
          setSelectedFrequency(null)
        }}
        onComplete={() => onEditComplete()}
      />
      {frequencyState && (
        <DeleteDialogue
          show={showDelete}
          accountid={accountid}
          frequency={selectedFrequency}
          onCancel={() => setShowDelete(false)}
          onComplete={() => closeDeleteDialog()}
        />
      )}
      {frequencyState && (
        <SetDefaultDialogue
          show={showDefault}
          accountid={accountid}
          frequency={selectedFrequency}
          onCancel={() => setShowDefault(false)}
          onComplete={() => closeDefaultDialog()}
        />
      )}
    </ContentWrapper>
  )
}

export default FrequencyManager
