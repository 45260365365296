import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonPrimary,
  ButtonSecondary
} from '@papertrail/styleguide'
import PublicIcon from '@mui/icons-material/Public'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  type: string
}

export default function ConfirmDialog(props: Props) {
  const { onCancel, onConfirm, type } = props
  return (
    <Dialog open={true}>
      <DialogTitle>Confirm</DialogTitle>

      {type === 'global' && (
        <DialogContent>This Checklist is Linked to a Global Checklist, which would you like to edit?</DialogContent>
      )}
      {type !== 'checklist' && type !== 'global' && (
        <DialogContent>Are you sure you want to delete this {type}?</DialogContent>
      )}
      {type === 'checklist' && (
        <DialogContent>Are you sure you want to exit this {type}? All changes will be lost.</DialogContent>
      )}
      <DialogActions>
        {type !== 'global' && (
          <>
            <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
            <ButtonPrimary onClick={onConfirm} data-testid="confirm">
              Confirm
            </ButtonPrimary>
          </>
        )}
        {type === 'global' && (
          <>
            <ButtonSecondary onClick={onCancel}>Edit Local Checklist</ButtonSecondary>
            <ButtonPrimary onClick={onConfirm} data-testid="confirm" icon={<PublicIcon />}>
              Edit Global Checklist
            </ButtonPrimary>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
