import React, { useEffect } from 'react'
import { Tag } from '../../types'
import { useApiPatch } from '@papertrail/web3-utils'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import {
  AlertWarning,
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Form,
  FormFieldText,
  Formik,
  Box
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  tag: Tag
  onCancel: () => void
  onComplete: () => void
}

export default function EditDialogue(props: Props) {
  const [editState, editTag, reset] = useApiPatch(`/accounts/${props.accountid}/tags/replace`, (data) => data)
  const { t } = useTranslation(['tools', 'global'])

  function closeDialog() {
    reset()
    props.onCancel()
  }

  useEffect(() => {
    if (editState.isLoaded) {
      props.onComplete()
    }
  }, [editState])

  function getErrorText() {
    if (editState.isError) {
      if (editState.error.errorCode === 'tag_missing') {
        return t('errorEditTagExists')
      } else {
        return t('errorEditTag')
      }
    }
  }

  const validationSchema = yup.object({
    tagName: yup.string().required(t('errorTagRequired'))
  })

  const initialValues = { tagName: props.tag.name }

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          editTag({ replace_tags: [props.tag.name], with_tag: values.tagName })
        }}>
        <Form>
          <DialogTitle id="alert-dialog-title">{t('editTag')}</DialogTitle>
          <DialogContent sx={{ width: '300px' }}>
            {editState.isError && (
              <Box paddingBottom={1}>
                <AlertWarning>{getErrorText()}</AlertWarning>
              </Box>
            )}
            <DialogContentText id="alert-dialog-description">
              <FormFieldText label={t('tagName')} placeholder={t('tagName')} name="tagName" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonSecondary onClick={closeDialog}>{t('global:cancel')}</ButtonSecondary>
            <ButtonPrimary data-testid="save_changes" isSubmit loading={editState.isLoading}>
              {t('global:saveChanges')}
            </ButtonPrimary>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}
