import React, { useState } from 'react'
import {
  ButtonDanger,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@papertrail/styleguide'
import { Filter } from '../../types'
import { useTranslation } from 'react-i18next'

type Props = {
  show: boolean
  accountid: string
  filter: Filter
  ruleId: string
  onDeleteRule: (ruleId: string) => void
  onCancel: () => void
  onComplete: () => void
}
export default function DeleteRuleDialogue(props: Props) {
  const { show, onCancel } = props
  const [, setSelectedRuleId] = useState(props.ruleId)

  function handleDelete() {
    setSelectedRuleId(props.ruleId)
    props.onDeleteRule(props.ruleId) // Call the onDeleteRule callback function and pass the rule ID
  }

  const { t } = useTranslation(['tools', 'global'])

  return (
    <Dialog open={show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{'Delete rule'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {'Are you sure you want to delete this rule?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={onCancel}>{t('global:cancel')}</ButtonSecondary>
        <ButtonDanger data-testid="confirm_delete" onClick={handleDelete}>
          {t('global:confirmDelete')}
        </ButtonDanger>
      </DialogActions>
    </Dialog>
  )
}
