import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useApiGet, useApiPost, useTitle, arrayMove } from '@papertrail/web3-utils'
import { Filter, State, Tag, Blueprint } from '../../types'
import FilterActions from './FilterActions'
import EditDialogue from './EditDialogue'
import AddNewFilter from './AddNewFilter'
import { useTranslation } from 'react-i18next'

import {
  ContentWrapper,
  ContentHeader,
  ContentNonScrolling,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  ButtonPrimary,
  Loading,
  AlertWarning,
  FlexRow,
  Label,
  ToolTip,
  DragIcon
} from '@papertrail/styleguide'
import { ruleMapper } from './helpers'
import './EditDialogue.css'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd'

type Params = {
  accountid: string
}

function stateMapper(data) {
  const state = data.data.map((state) => {
    return { id: state.id, name: state.name, icon: state.profile, isPositive: state.is_positive }
  })

  return state
}
function tagMapper(data) {
  const tag = data.data.map((tag) => {
    return { id: tag.id, name: tag.name }
  })
  return tag
}

export default function FilterManager() {
  const { accountid } = useParams() as Params
  const [filterState, loadFilters] = useApiGet<Filter[]>(`/accounts/${accountid}/filters`, filterMapper)
  const [dataState, loadState] = useApiGet<State[]>(`/accounts/${accountid}/states`, stateMapper)
  const [tagState, loadTags] = useApiGet<Tag[]>(`/accounts/${accountid}/tags`, tagMapper)
  const [blueprintState, loadFields] = useApiGet<Blueprint>(`/accounts/${accountid}`, blueprintMapper)
  const [showEdit, setShowEdit] = useState(false)
  const [filter, setFilter] = useState(undefined)
  const [blueprint] = useState()
  const [addNewFilter, setAddNewFilter] = useState(false)
  const [dragId, setDragId] = useState(undefined)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [moveFilter, postMove] = useApiPost(
    `/accounts/${accountid}/filters/${dragId}/actions/reposition`,
    (data) => data
  )
  const [sortedFilters, setSortedFilters] = useState<Filter[]>([])
  const { t } = useTranslation(['tools', 'global'])
  useTitle(t('filterManager'))

  useEffect(() => {
    loadState({ limit: 1000 })
    loadTags({ limit: 1000 })
    loadFields({
      include:
        'folder,folder.definitions,states,frequencies,filters,subscription,subscription.reseller,flags,blueprint,addresses'
    })
  }, [])

  useEffect(() => {
    if (filterState.isLoaded) {
      setSortedFilters(filterState.data.sort((a, b) => a.order - b.order))
    }
  }, [filterState])

  useEffect(() => {
    if (dataState.isLoaded) {
      loadFilters({ include: 'count', limit: 1000 })
    }
  }, [dataState.isLoaded])

  function blueprintMapper(data) {
    const blueprint = data.data.blueprint
    return {
      id: blueprint.data.id,
      fields: getFieldsAsArray(blueprint.data.sections)
    }
  }

  function getFieldsAsArray(sections) {
    const array = []
    sections.data.forEach((section) => {
      section.fields.data.forEach((field) => {
        array.push({
          id: field.id,
          type: field.type,
          label: field.label,
          key: field.key,
          required: field.required,
          position: field.position
        })
      })
    })

    return array
  }

  function filterMapper(data) {
    const filterArray = data.data.map((filter) => ({
      id: filter.id,
      name: filter.name,
      recordCount: filter.count,
      profile: filter.profile,
      showCount: filter.meta.data.length > 0 && filter.meta.data[0].value,
      rules: ruleMapper(filter.rules.data, dataState),
      default: filter.default
    }))
    return filterArray
  }

  const handleEscapeKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowEdit(false)
      setAddNewFilter(false)
    }
  }, [])

  useEffect(() => {
    if (showEdit || addNewFilter) {
      document.addEventListener('keydown', handleEscapeKeyPress)
    } else {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [showEdit, addNewFilter, handleEscapeKeyPress])

  function openEditDialogue(filter) {
    setShowEdit(true)
    setFilter(filter)
    setAddNewFilter(false)
  }

  function openAddNewDialogue(filter) {
    setAddNewFilter(true)
    setFilter(filter)
    setShowEdit(false)
  }

  function reloadFilters() {
    loadFilters({ include: 'count', limit: 1000 })
  }

  const onComplete = () => {
    setShowEdit(false)
    setAddNewFilter(false)
    reloadFilters()
  }

  function handleDragEnd(result) {
    setSortedFilters(arrayMove(sortedFilters, result.source.index, result.destination.index))
    postMove({ position: result.destination.index + 1 })
  }

  function handleDragStart(result) {
    setDragId(result.draggableId)
  }

  return (
    <ContentWrapper>
      <ContentHeader>{t('filterManager')}</ContentHeader>
      <ContentNonScrolling>
        <FlexRow justifyContent="space-between">
          <h3>{t('filterManager')}</h3>
          <ButtonPrimary onClick={() => openAddNewDialogue(filter)}>Add a new filter</ButtonPrimary>
        </FlexRow>
        <p>{t('filterInfoText')}</p>
        {filterState.isLoading && <Loading />}
        {filterState.isError && <AlertWarning>{'Sorry there was an error loading the filters.'}</AlertWarning>}
        <Box overflow={'auto'}>
          <Table stickyHeader sx={{ minWidth: 650, marginBottom: '50px' }} aria-label={'filterTitle'}>
            <TableHead>
              <TableRow>
                <TableCell width={'5%'}></TableCell>
                <TableCell width={'40%'}>{t('filters')}</TableCell>
                <TableCell width={'40%'}>{t('recordCount')}</TableCell>
                <TableCell width={'15%'} align="right">
                  {t('actions')}
                </TableCell>
              </TableRow>
            </TableHead>

            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                    {filterState.isLoaded &&
                      sortedFilters.map((filter, index) => (
                        <Draggable key={filter.id} draggableId={filter.id} index={index}>
                          {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                            return (
                              <TableRow
                                key={filter.id}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}>
                                <TableCell width={'5%'}>
                                  <ToolTip title={'Drag'}>
                                    <div {...draggableProvided.dragHandleProps}>
                                      <DragIcon />
                                    </div>
                                  </ToolTip>
                                </TableCell>
                                <TableCell component="th" scope="row" width={'40%'}>
                                  {filter.name}
                                  <Box width="60px">{filter.default && <Label text="Default" variant="default" />}</Box>
                                </TableCell>
                                <TableCell width={'40%'}>{filter.recordCount}</TableCell>
                                <TableCell align="right">
                                  <ButtonPrimary
                                    disabled={filter.name === 'All Records'}
                                    onClick={() => openEditDialogue(filter)}>
                                    Edit
                                  </ButtonPrimary>
                                </TableCell>
                                <TableCell width={'15%'}>
                                  <FilterActions filter={filter} accountid={accountid} onComplete={reloadFilters} />
                                </TableCell>
                              </TableRow>
                            )
                          }}
                        </Draggable>
                      ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </Box>
      </ContentNonScrolling>

      {filter && dataState.isLoaded && (
        <EditDialogue
          show={showEdit}
          accountid={accountid}
          filter={filter}
          blueprint={blueprint}
          field={blueprintState.data.fields}
          allTags={tagState.data}
          allStates={dataState.data}
          onCancel={() => setShowEdit(false)}
          onComplete={onComplete}
        />
      )}
      {addNewFilter && dataState.isLoaded && (
        <AddNewFilter
          show={addNewFilter}
          accountid={accountid}
          filter={filter}
          blueprint={blueprint}
          field={blueprintState.data.fields}
          allTags={tagState.data}
          allStates={dataState.data}
          onCancel={() => setAddNewFilter(false)}
          onComplete={onComplete}
        />
      )}
    </ContentWrapper>
  )
}
