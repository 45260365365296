import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ChildrenProps } from '@papertrail/styleguide/src/types'
import { FlexRow } from '@papertrail/styleguide/src/layout/Flex'
import { useEffect, useState } from 'react'
import { Box, IconButton, useMediaQuery } from '@papertrail/styleguide'
import NestedDropdownMenu from './NestedDropdown'

type Props = ChildrenProps & {
  sectionData
  expandOnLoad?: boolean
  Icon?
  onValueChange?: (value: any) => void
}

export default function Accordions(props: Props) {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const { sectionData, Icon, children, expandOnLoad } = props
  const [sectionNames, setSectionNames] = useState(null)
  const isMobile = useMediaQuery('(max-width:600px)')

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (expandOnLoad) {
      setExpanded(true)
    }
  }, [expandOnLoad])

  useEffect(() => {
    if (sectionData.sectionNames) {
      const newSectionNames = sectionData.sectionNames.map((section, index) => {
        if (index !== sectionData.currentSection) return section.label
      })
      setSectionNames(newSectionNames)
    } else {
      setSectionNames(null)
    }
  }, [sectionData])

  useEffect(() => {
    let expandedPanel
    if (expanded) {
      expandedPanel = new CustomEvent('expandedPanel', {
        detail: {
          expanded: 'expanded',
          index: sectionData.index,
          type: sectionData.type
        }
      })
      window.dispatchEvent(expandedPanel)
    } else {
      expandedPanel = new CustomEvent('expandedPanel', {
        detail: {
          expanded: 'closed',
          index: sectionData.index,
          type: sectionData.type
        }
      })
      window.dispatchEvent(expandedPanel)
    }
  }, [expanded])

  const handleSelect = (value) => {
    const action: any = { type: sectionData.type, index: sectionData.index, action: value.action }
    if (value.location) {
      action.location = value.location
    }
    props.onValueChange(action)
  }

  return (
    <div>
      <Accordion
        expanded={expanded}
        style={{
          marginTop: '8px',
          marginBottom: '8px',
          borderLeft: 'none',
          borderBottomLeftRadius: '0px',
          borderTopLeftRadius: '0px',
          boxShadow: 'none'
        }}
        data-testid={
          sectionData.type === 'sections'
            ? `${sectionData.type}-${sectionData.index}`
            : `sections-${sectionData.currentSection}-${sectionData.type}-${sectionData.index}`
        }>
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            background: '#E5F2FF',
            height: '52px',
            paddingRight: '40px'
          }}>
          <Typography
            sx={{
              flexShrink: 0,
              textTransform: 'uppercase',
              color: '#1A1C21',
              fontSize: '14px',
              fontWeight: '600px',
              maxWidth: '70%'
            }}>
            <FlexRow justifyContent={'space-between'} alignItems="center">
              <Box display={'flex'} alignItems={'center'} onClick={handleExpandClick}>
                {Icon && (
                  <div
                    style={{
                      borderRadius: '50%',
                      padding: '8px',
                      background: '#E4E7EB',
                      marginRight: '8px',
                      display: 'flex',
                      alignContent: 'center'
                    }}>
                    <Icon />
                  </div>
                )}{' '}
                {sectionData.type === 'sections' && (
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: isMobile ? '140px' : '400px'
                    }}>
                    {sectionData.label}
                  </div>
                )}
                {sectionData.type === 'fields' && (
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: isMobile ? '100px' : '350px'
                    }}>
                    {sectionData.label}
                  </div>
                )}
              </Box>

              <Box display={'flex'}>
                <IconButton onClick={handleExpandClick}>
                  <ExpandMoreIcon style={{ ...(expanded && { transform: 'rotate(180deg)' }) }} />
                </IconButton>
                <NestedDropdownMenu
                  menuList1={
                    sectionData.type === 'fields'
                      ? ['Clone', 'Move to', 'Copy to', 'Delete Question']
                      : ['Clone', 'Delete Section']
                  }
                  menuList2={sectionNames}
                  valueChangeCallback={(value) => handleSelect(value)}
                  sectionData={sectionData}
                />
              </Box>
            </FlexRow>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{children}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
