import React, { useCallback, useEffect, useState } from 'react'
import {
  CheckIcon,
  Chip,
  ContentHeader,
  Label,
  MissingStateIcon,
  MonitorStateIcon,
  QuarantineStateIcon,
  RetiredStateIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  FlexRow,
  ButtonPrimary,
  DragIcon,
  ContentWrapper,
  ContentNonScrolling,
  InfoIcon,
  ToolTip
} from '@papertrail/styleguide'
import { useParams } from 'react-router-dom'
import { State } from 'src/types'
import { useTitle, arrayMove, useApiGet, useApiPost } from '@papertrail/web3-utils'
import StateActions from './StateActions'
import AddNewStateDialogue from './AddNewStateDialogue'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd'

type Params = {
  accountid: string
}
function stateMapper(data) {
  const state = data.data.map((n) => {
    return {
      id: n.id,
      name: n.name,
      colour: n.colour,
      order: n.order,
      isPositive: n.is_positive,
      profile: n.profile,
      shouldArchive: n.should_archive,
      recordCount: n.record_count.data.record_count,
      default: n.default,
      deleted: n.deleted_at != null,
      deletable: n.deletable,
      editable: n.editable
    }
  })

  return state
}

export default function StateManager() {
  const { accountid } = useParams() as Params
  const [showNewState, setShowNewState] = useState(false)
  const [dragId, setDragId] = useState(undefined)
  const [sortedStates, setSortedStates] = useState<State[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [moveState, postMove] = useApiPost(`/accounts/${accountid}/states/${dragId}/actions/move`, (data) => data)
  const [dataState, loadState] = useApiGet<State[]>(`/accounts/${accountid}/states`, stateMapper)

  useEffect(() => {
    loadState({ include: 'record_count,deleted', limit: 1000 })
  }, [])

  useEffect(() => {
    if (dataState.isLoaded) {
      setSortedStates(
        dataState.data
          .sort((a, b) => a.order - b.order)
          .filter((state) => state.deleted === false || state.recordCount > 0)
      )
    }
  }, [dataState])

  const handleEscapeKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowNewState(false)
    }
  }, [])

  useEffect(() => {
    if (showNewState) {
      document.addEventListener('keydown', handleEscapeKeyPress)
    } else {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [showNewState, handleEscapeKeyPress])

  useTitle('State Manager')

  function getIcon(state) {
    if (state.profile === 'good') {
      return <CheckIcon />
    } else if (state.profile === 'monitor') {
      return <MonitorStateIcon />
    } else if (state.profile === 'quarantine') {
      return <QuarantineStateIcon />
    } else if (state.profile === 'missing') {
      return <MissingStateIcon />
    } else if (state.profile === 'retired') {
      return <RetiredStateIcon />
    }
  }

  function openNewStateDialogue() {
    setShowNewState(true)
  }

  function reloadState() {
    loadState({ include: 'record_count,deleted', limit: 1000 })
  }
  function onAddState() {
    setShowNewState(false)
    reloadState()
  }

  function handleDragEnd(result) {
    setSortedStates(arrayMove(sortedStates, result.source.index, result.destination.index))
    postMove({ position: result.destination.index + 1 })
  }
  function handleDragStart(result) {
    setDragId(result.draggableId)
  }

  return (
    <ContentWrapper>
      <ContentHeader>State Manager</ContentHeader>
      <ContentNonScrolling>
        <FlexRow justifyContent={'space-between'}>
          <h3>State Manager</h3>
          <ButtonPrimary onClick={() => openNewStateDialogue()}>New state</ButtonPrimary>
        </FlexRow>

        <p>You can add, edit and delete states here. </p>

        <Box overflow={'auto'}>
          <Table stickyHeader sx={{ minWidth: 650, marginBottom: '50px' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>State</TableCell>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>Is positive</div>
                    <div style={{ marginLeft: '5px' }}>
                      <ToolTip
                        title={
                          "Indicates if the state is positive or negative. For example 'Checked - good' is a positive state"
                        }>
                        <InfoIcon />
                      </ToolTip>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>Will archive</div>
                    <div style={{ marginLeft: '5px' }}>
                      <ToolTip
                        title={
                          'Records given this state will be archived and hidden from view. They will no longer be flagged for inspection'
                        }>
                        <InfoIcon />
                      </ToolTip>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>Record count</div>
                    <div style={{ marginLeft: '5px' }}>
                      <ToolTip title={'The record count includes deleted records'}>
                        <InfoIcon />
                      </ToolTip>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                    {dataState.isLoaded &&
                      sortedStates.map((state, index) => (
                        <Draggable key={state.id} draggableId={state.id} index={index}>
                          {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                            return (
                              <TableRow
                                key={state.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}>
                                <TableCell width={'2%'}>
                                  <ToolTip title={'Drag'}>
                                    <div {...draggableProvided.dragHandleProps}>
                                      <DragIcon />
                                    </div>
                                  </ToolTip>
                                </TableCell>

                                <TableCell scope="row" width={'30%'}>
                                  <Box display="flex" justifyContent="flex-start" alignItems={'center'}>
                                    <Chip
                                      label={state.name}
                                      icon={getIcon(state)}
                                      color="primary"
                                      sx={{ backgroundColor: `rgb(${state.colour})` }}
                                    />
                                    <Box width="60px" marginLeft={1}>
                                      {state.default && <Label text="Default" variant="warning" />}
                                      {state.deleted && <Label text="Deleted" variant="default" />}
                                    </Box>
                                  </Box>
                                </TableCell>

                                <TableCell width={'15%'}>{state.isPositive ? <div>Yes</div> : <div>No</div>}</TableCell>
                                <TableCell width={'15%'}>
                                  {state.shouldArchive ? <div>Yes</div> : <div>No</div>}
                                </TableCell>
                                <TableCell width={'20%'}>{state.recordCount}</TableCell>
                                <TableCell width={'5%'} align="right">
                                  {state.deleted === false && (
                                    <StateActions state={state} accountid={accountid} onComplete={reloadState} />
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          }}
                        </Draggable>
                      ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </Box>
      </ContentNonScrolling>

      <AddNewStateDialogue
        show={showNewState}
        accountid={accountid}
        onCancel={() => setShowNewState(false)}
        onComplete={() => onAddState()}
      />
    </ContentWrapper>
  )
}
