import React, { useState } from 'react'
import {
  ButtonPrimary,
  FormFieldText,
  Box,
  FormFieldRadio,
  useFormikContext,
  ButtonSecondary,
  FlexRow
} from '@papertrail/styleguide'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from './ConfirmDialog'
import { ChildrenProps } from '@papertrail/styleguide/src/types'

type Props = ChildrenProps & {
  index: number
  fieldIndex: number
  optionArrayHelpers
}

const EditOption = (props: Props) => {
  const { index, fieldIndex, optionArrayHelpers } = props
  const { values }: any = useFormikContext()
  const { t } = useTranslation(['tools', 'global'])
  const [showDeleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState<number>(null)

  const deleteOption = () => {
    optionArrayHelpers.remove(deleteIndex)
    setDeleteConfirm(false)
  }

  const openDeleteDialogue = (index) => {
    setDeleteIndex(index)
    setDeleteConfirm(true)
  }

  const addOption = () => {
    optionArrayHelpers.push({ value: '', sentiment: '0' })
  }

  return (
    <div>
      {values.sections[index].fields[fieldIndex].options &&
        values.sections[index].fields[fieldIndex].options.length > 0 && (
          <Box sx={{ color: '#69707d', fontWeight: '600', marginBottom: '8px' }}>Responses</Box>
        )}
      {values.sections[index].fields[fieldIndex].options &&
        values.sections[index].fields[fieldIndex].options.map((option, optIndex) => {
          return (
            <div key={optIndex}>
              <Box sx={{ marginLeft: '4px' }}>
                <FormFieldText
                  name={`sections[${index}].fields[${fieldIndex}].options[${optIndex}].value`}
                  label="Response"
                  placeholder={'Add Response Here'}
                  data-testid={`sections-${index}-fields-${fieldIndex}-options-${optIndex}-value`}
                />
                <FormFieldRadio
                  name={`sections[${index}].fields[${fieldIndex}].options[${optIndex}].sentiment`}
                  label={'Sentiment'}
                  data-testid={`sections-${index}-fields-${fieldIndex}-options-${optIndex}-sentiment`}
                />
              </Box>
              <Box sx={{ marginBottom: '16px' }}>
                {values.sections[index].fields[fieldIndex].options.length > 2 && (
                  <FlexRow justifyContent={'right'}>
                    <ButtonSecondary data-testid="delete_option" onClick={() => openDeleteDialogue(optIndex)}>
                      {t('Delete Response')}
                    </ButtonSecondary>
                  </FlexRow>
                )}
              </Box>
            </div>
          )
        })}
      {showDeleteConfirm && (
        <ConfirmDialog
          type={'option'}
          onCancel={() => setDeleteConfirm(false)}
          onConfirm={deleteOption}></ConfirmDialog>
      )}
      {values.sections[index].fields[fieldIndex].options &&
        values.sections[index].fields[fieldIndex].options.length > 0 && (
          <ButtonPrimary data-testid="add_option" onClick={() => addOption()}>
            {t('Add New Response')}
          </ButtonPrimary>
        )}
    </div>
  )
}

export default EditOption
