import React, { useEffect, useState } from 'react'
import { Filter, Rule, State, Blueprint, Field } from '../../types'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'

import { Formik, Box, CloseIcon, CheckIcon, IconButton, FormFieldSelectFormik, FlexRow } from '@papertrail/styleguide'

type Props = {
  accountid: string
  show: boolean
  filter: Filter
  rule: Rule
  blueprint: Blueprint
  field: Field[]
  allStates: State[]
  onCancel: () => void
  onComplete: (values) => void
}

export default function AddNewFieldFilter(props: Props) {
  const { field, onCancel } = props
  const [secondFields, setSecondFields] = useState([])
  const [operArr, setoperArr] = useState([])

  function getOperatorFromString() {
    const myOperators = ['=', '>', '<', '~=', '<=']
    let myOperator = ''
    myOperators.map((oper) => {
      if (props.rule.value.includes(oper)) {
        myOperator = oper
      }
      if (props.rule.value.includes('~=') && oper.includes('~=')) {
        myOperator = oper
      }
    })
    return myOperator
  }

  function getFirstFieldFromRules() {
    const firstValue = props.rule.value.split(/[@=<>]/g).find((op) => op.length > 0) || ''
    return firstValue
  }

  function getSecondFieldFromRules() {
    const nextValue = props.rule.value.split(/[@=<>]/g).pop() || ''
    return nextValue
  }

  const initialValues = {
    id: props.rule.id || uuid(),
    key: props.rule.key || 'field-filter',
    active: props.rule.active || false,
    fieldFilterFirst: getFirstFieldFromRules(),
    fieldFilterSecond: getSecondFieldFromRules(),
    operator: getOperatorFromString()
  }

  const validationSchema = yup.object({
    fieldFilterFirst: yup.string().required('Field is required'),
    fieldFilterSecond: yup.string().required('Field is required'),
    operator: yup
      .string()
      .required('Operator is required')
      .matches(/^(=|<|>|>=|<=|<>|~=)$/, 'Please enter a valid operator')
  })

  const operators = [
    { name: 'equal to', icon: '=', type: ['identifier', 'rfid', 'barcode', 'string', 'integer', 'money', 'date'] },
    { name: 'fuzzy match', icon: '~=', type: ['identifier', 'rfid', 'barcode', 'string'] },
    { name: 'greater than', icon: '>', type: ['integer', 'money', 'date'] },
    { name: 'greater or equal to', icon: '>=', type: ['integer', 'money', 'date'] },
    { name: 'less than', icon: '<', type: ['integer', 'money', 'date'] },
    { name: 'less or equal to', icon: '<=', type: ['integer', 'money', 'date'] }
  ]

  function submitForm(values) {
    const newValues = values
    newValues.label = 'Field Filter: @' + values.fieldFilterFirst + values.operator + '@' + values.fieldFilterSecond
    newValues.value = '@' + values.fieldFilterFirst + values.operator + '@' + values.fieldFilterSecond
    newValues.operator = values.operator
    newValues.active = false
    props.onComplete(newValues)
  }

  function closeDialog() {
    onCancel()
  }

  function setField(value) {
    let comparisonFields
    const myField = field.find((el) => el.key === value)

    if (myField && myField.type) {
      const filteredOperators = operators.filter((operator) => operator.type.includes(myField.type))
      const operatorSelectValues = filteredOperators.map((operator) => {
        return {
          label: `${operator.icon} (${operator.name}) `,
          value: operator.icon
        }
      })

      if (myField.type === 'integer' || myField.type === 'date' || myField.type === 'money') {
        comparisonFields = field.filter((el) => el.type === myField.type)
      } else {
        comparisonFields = field.filter(
          (el) => el.type === 'string' || el.type === 'identifier' || el.type === 'barcode' || el.type === 'rfid'
        )
      }
      setSecondFields(comparisonFields)
      setoperArr(operatorSelectValues)
    }
  }

  useEffect(() => {
    setField(initialValues.fieldFilterFirst)
  }, [])

  const resetDropdowns = (formikProps) => {
    formikProps.setFieldValue('operator', '')
    formikProps.setFieldValue('fieldFilterSecond', '')
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => console.log(values)}>
      {(formikProps) => (
        <Box>
          <Box paddingRight={1}>
            <FormFieldSelectFormik
              options={field
                .filter(
                  (field, index, self) =>
                    field.key !== 'certifications' &&
                    field.key !== 'purchase_price' &&
                    field.key !== 'stock_quantity' &&
                    self.findIndex((f) => f.key === field.key) === index
                )
                .map((field) => ({
                  label: field.label,
                  value: field.key
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              name={'fieldFilterFirst'}
              label={''}
              placeholder={'Select field *'}
              value={formikProps.values.fieldFilterFirst}
              onChange={(event) => {
                formikProps.handleChange(event)
                setField(event.target.value)
                resetDropdowns(formikProps)
              }}
            />
          </Box>

          {operArr && operArr.length > 0 && (
            <>
              <Box paddingRight={1}>
                <FormFieldSelectFormik
                  options={operArr}
                  name={'operator'}
                  label={''}
                  placeholder={'Select operator *'}
                  onChange={(event) => {
                    formikProps.handleChange(event)
                  }}
                />
              </Box>
              <FlexRow justifyContent={'space-between'}>
                <FormFieldSelectFormik
                  options={secondFields
                    .filter((field) => field.key !== formikProps.values.fieldFilterFirst)
                    .map((field) => ({
                      label: field.label,
                      value: field.key
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  name={'fieldFilterSecond'}
                  label={''}
                  placeholder={'Select field *'}
                  onChange={(event) => {
                    formikProps.handleChange(event)
                  }}
                />
                <Box
                  display={'flex'}
                  visibility={formikProps.values.fieldFilterSecond !== 'null' ? 'visible' : 'hidden'}
                  marginBottom={'16px'}>
                  <IconButton
                    id="basic-button"
                    size="large"
                    onClick={closeDialog}
                    sx={{
                      backgroundColor: '#fdebec',
                      color: '#eb3745',
                      borderRadius: '0px',
                      margin: '0 8px'
                    }}>
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    size="large"
                    onClick={() => submitForm(formikProps.values)}
                    color={'primary'}
                    sx={{
                      backgroundColor: '#e5f2ff',
                      color: '#007aff',
                      borderRadius: '0px',
                      margin: '0 8px'
                    }}>
                    <CheckIcon />
                  </IconButton>
                </Box>
              </FlexRow>
            </>
          )}
        </Box>
      )}
    </Formik>
  )
}
