import React, { useEffect, useState } from 'react'
import { FormFieldText, Box, useFormikContext, FieldArray } from '@papertrail/styleguide'
import EditField from './EditField'
import ConfirmDialog from './ConfirmDialog'
import { DragDropContext as DragAndDrop } from 'react-beautiful-dnd'
import { Drop } from './Drop'
import Accordions from './Accordions'
import { Drag } from './Drag'
import { ChildrenProps } from '@papertrail/styleguide/src/types'

type Props = ChildrenProps & {
  sectionArrayHelpers
  selectedSection
  activeSection
  error
}

const EditSection = (props: Props) => {
  const { sectionArrayHelpers, selectedSection, activeSection, error } = props
  const { values }: any = useFormikContext()
  const [showDeleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState(null)

  const handleDeleteSection = () => {
    if (deleteIndex !== null) {
      sectionArrayHelpers.remove(deleteIndex)
      setDeleteConfirm(false)
      setDeleteIndex(null)
    }
  }

  useEffect(() => {
    if (selectedSection) {
      sectionArrayHelpers.push(selectedSection)
      if (activeSection) {
        scrolltoId(activeSection)
      }
    }
  }, [selectedSection])

  useEffect(() => {
    if (activeSection) {
      scrolltoId(activeSection)
    }
  }, [activeSection])

  useEffect(() => {
    if (error) {
      const index = error[1]

      scrolltoId(values.sections[index].id)
    }
  }, [error])

  const scrolltoId = (id: string) => {
    let access
    setTimeout(() => {
      access = document.getElementById(id)
      access.scrollIntoView({ behavior: 'smooth' })
    }, 1000)
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const { source, destination } = result

    if (source.droppableId === 'sections') {
      sectionArrayHelpers.move(source.index, destination.index)
    }
  }

  const handleSectionAction = (value, sectionIndex) => {
    switch (value.action) {
      case 'Clone':
        const newSection = {
          position: getPosition(),
          id: `New Section (${values.sections.length + 1})`,
          description: values.sections[sectionIndex].description,
          label: values.sections[sectionIndex].label,
          fields: values.sections[sectionIndex].fields
        }
        newSection.fields.map((field) => {
          field.id = ''
        })
        sectionArrayHelpers.push(newSection)
        scrolltoId('Bottom')
        break
      case 'Delete Section':
        setDeleteIndex(sectionIndex)
        setDeleteConfirm(true)
        break
    }
  }

  const getPosition = () => {
    const position = values.sections.length + 1
    return position
  }

  return (
    <Box>
      <div id="Top"></div>
      <DragAndDrop onDragEnd={handleDragEnd}>
        <Drop id={'sections'} type={'section'}>
          {values.sections.map((section, index) => {
            return (
              <div key={index}>
                <Drag key={section.id} id={section.id} index={index} type="sections">
                  <Accordions
                    sectionData={{
                      label: section.label || 'SECTION TITLE',
                      id: section.id,
                      index: index,
                      type: 'sections'
                    }}
                    key={section.id}
                    expandOnLoad={
                      (activeSection && section.id === activeSection) ||
                      values.sections.length === 1 ||
                      (error && error[1] == index) ||
                      false
                    }
                    onValueChange={(value) => handleSectionAction(value, index)}>
                    <div id={section.id}>
                      <FormFieldText
                        name={`sections[${index}].label`}
                        label="Section Title"
                        placeholder={'Add Section Title Here'}
                        data-testid={`sections-${index}-label`}
                      />
                      <FormFieldText
                        name={`sections[${index}].description`}
                        label="Section Description"
                        placeholder={'Add Description Here'}
                        data-testid={`sections-${index}-description`}
                      />
                    </div>
                    <FieldArray name={`sections[${index}].fields`}>
                      {(arrayHelpers) => {
                        return (
                          <div>
                            <EditField
                              index={index}
                              fieldArrayHelpers={arrayHelpers}
                              fieldError={error && error.length > 2 ? error : null}></EditField>
                          </div>
                        )
                      }}
                    </FieldArray>
                  </Accordions>
                </Drag>
              </div>
            )
          })}
        </Drop>
      </DragAndDrop>
      {showDeleteConfirm && (
        <ConfirmDialog
          type={'section'}
          onCancel={() => setDeleteConfirm(false)}
          onConfirm={handleDeleteSection}></ConfirmDialog>
      )}

      <div id="Bottom"></div>
    </Box>
  )
}

export default EditSection
