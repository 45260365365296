import React, { useEffect } from 'react'
import {
  AlertWarning,
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@papertrail/styleguide'
import { Contact } from '../../types'
import { useApiDelete } from '@papertrail/web3-utils'
import { useTranslation } from 'react-i18next'

type Props = {
  show: boolean
  accountid: string
  contact: Contact
  onCancel: () => void
  onComplete: () => void
}
export default function DeleteDialogue(props: Props) {
  const [deleteState, deleteContact] = useApiDelete(
    `/accounts/${props.accountid}/contacts/${props.contact.id}`,
    (data) => data
  )
  useEffect(() => {
    if (deleteState.isLoaded) {
      props.onComplete()
    }
  }, [deleteState])

  const { t } = useTranslation(['tools', 'global'])
  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t('Delete Account Contact')}</DialogTitle>
      <DialogContent sx={{ width: '400px', height: '50px' }}>
        {deleteState.isError && <AlertWarning>{t('errorDeleteContact')}</AlertWarning>}
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this contact from the contacts list?{' '}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={() => props.onCancel()}>{t('global:cancel')}</ButtonSecondary>
        <ButtonPrimary data-testid="confirm_delete" onClick={() => deleteContact({})} loading={deleteState.isLoading}>
          {t('global:confirmDelete')}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}
