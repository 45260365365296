export function ruleMapper(rules, dataState) {
  const newRules = rules.map((rule) => {
    const mappedRule = {
      id: rule.id,
      label: 'unmapped rule ' + rule.key,
      key: rule.key,
      value: rule.value,
      sentiment: rule.value
    }

    switch (rule.key) {
      case 'inspection': {
        mappedRule.label = 'Inspection: ' + rule.value
        mappedRule.key = 'inspection'
        mappedRule.value = rule.value
        break
      }

      case 'starred': {
        mappedRule.label = 'Starred only'
        mappedRule.value = rule.value === 'true' ? true : false
        break
      }

      case 'state': {
        if (typeof rule.value === 'string' && rule.value === '@is_positive=true') {
          mappedRule.label = 'State is positive '
          mappedRule.sentiment = 'positive'
        } else if (typeof rule.value === 'string' && rule.value === '@is_positive=false') {
          mappedRule.label = 'State is negative'
          mappedRule.sentiment = 'negative'
        } else {
          mappedRule.label =
            'State: ' + (dataState.isLoaded ? dataState.data.find((s) => s.id === rule.value).name : 'empty')
        }
        mappedRule.key = 'state'
        break
      }

      case 'tag': {
        mappedRule.label = 'Tag: ' + rule.value
        mappedRule.key = 'tag'
        break
      }

      case 'task_active': {
        mappedRule.label = rule.value === true ? 'Tasks: active tasks' : 'Tasks: no active tasks'
        mappedRule.key = 'task_active'
        break
      }

      case 'field_filter':
      case 'field-filter': {
        mappedRule.label = 'Field Filter: ' + rule.value
        mappedRule.key = 'field-filter'
        break
      }

      case 'filter': {
        mappedRule.label = 'Field Comparison Filter: ' + rule.value
        mappedRule.key = 'filter'
        break
      }

      case 'include': {
        mappedRule.label = 'Archived'
        mappedRule.key = 'include'
        break
      }
    }
    return mappedRule
  })
  return newRules
}
