import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  CheckIcon,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FlexRow,
  Form,
  FormFieldCheckbox,
  FormFieldSelect,
  FormFieldText,
  Formik,
  IconButton,
  MissingStateIcon,
  MonitorStateIcon,
  QuarantineStateIcon,
  RetiredStateIcon,
  ToolTip
} from '@papertrail/styleguide'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useApiPost } from '@papertrail/web3-utils'

type Props = {
  show: boolean
  accountid: string
  onCancel: () => void
  onComplete: () => void
}

export default function AddNewStateDialogue(props: Props) {
  const [addState, newState] = useApiPost<any>(`/accounts/${props.accountid}/states`, (data) => data)
  const initialValues = { is_positive: false, should_archive: false, profile: 'null', colour: 'null', name: '' }
  const validationSchema = yup.object({
    name: yup.string().required('State name is required'),
    profile: yup.string().notOneOf(['null'], 'State icon is required'),
    colour: yup.string().notOneOf(['null'], 'Colour is required')
  })

  function submitForm(values) {
    // useApiPost hook
    newState({
      name: values.name,
      profile: values.profile,
      colour: values.colour,
      is_positive: values.is_positive,
      should_archive: values.should_archive
    })
  }
  useEffect(() => {
    if (addState.isLoaded) {
      props.onComplete()
    }
  }, [addState])

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
        <Form>
          <FlexRow justifyContent="space-between">
            <DialogTitle id="alert-dialog-title">{'New state'}</DialogTitle>
            <IconButton id="basic-button" size="large" onClick={() => props.onCancel()}>
              <CloseIcon />
            </IconButton>
          </FlexRow>

          <DialogContent sx={{ width: '500px' }}>
            <DialogContentText id="alert-dialog-description">
              <FormFieldText label="State name" placeholder="State name" name="name" mandatory={true} />
              <FormFieldSelect
                options={[
                  { value: 'good', label: 'Tick', icon: <CheckIcon /> },
                  { value: 'monitor', label: 'Magnifying glass', icon: <MonitorStateIcon /> },
                  { value: 'quarantine', label: 'Lock', icon: <QuarantineStateIcon /> },
                  { value: 'missing', label: 'Question Mark', icon: <MissingStateIcon /> },
                  { value: 'retired', label: 'Bin - Archive', icon: <RetiredStateIcon /> }
                ]}
                name={'profile'}
                label={'State Icon'}
                placeholder={'Please select the icon'}
                mandatory={true}
              />
              <FormFieldSelect
                options={[
                  { value: '24,166,120', label: 'Green' },
                  { value: '240,173,78', label: 'Yellow' },
                  { value: '235,55,69', label: 'Red' }
                ]}
                name={'colour'}
                label={'State colour'}
                placeholder={'Please select the colour'}
                mandatory={true}
              />
              <Box display={'flex'} align-items={'left'}>
                <ToolTip
                  title={
                    "Indicates if the state is positive or negative. For example 'Checked - good' is a positive state"
                  }>
                  <span>
                    <FormFieldCheckbox name={'is_positive'} label={'Is positive?'} />
                  </span>
                </ToolTip>
              </Box>
              <Box display={'flex'} align-items={'left'}>
                <ToolTip
                  title={
                    'Records given this state will be archived and hidden from view. They will no longer be flagged for inspection'
                  }>
                  <span>
                    <FormFieldCheckbox name={'should_archive'} label={'Will archive'} />
                  </span>
                </ToolTip>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonSecondary onClick={() => props.onCancel()}>Cancel</ButtonSecondary>
            <ButtonPrimary data-testid="create_state" isSubmit loading={addState.isLoading}>
              Create
            </ButtonPrimary>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}
