import React, { useEffect, useState } from 'react'
import {
  FormFieldText,
  useFormikContext,
  BooleanIcon,
  DateIcon,
  FileUploadIcon,
  MultiChoiceIcon,
  SignedIcon,
  TextIcon,
  FieldArray,
  LongMenu
} from '@papertrail/styleguide'
import EditOption from './EditOption'
import ConfirmDialog from './ConfirmDialog'
import Accordions from './Accordions'
import { Drag } from './Drag'
import { DragDropContext as DragAndDrop } from 'react-beautiful-dnd'
import { Drop } from './Drop'
import { ChildrenProps } from '@papertrail/styleguide/src/types'

type Props = ChildrenProps & {
  index: number
  fieldArrayHelpers
  fieldError
}

const EditField = (props: Props) => {
  const { index, fieldArrayHelpers, fieldError } = props
  const { values, setFieldValue }: any = useFormikContext()
  const allFields = ['True/False', 'Multiple Choice', 'Date', 'Text', 'General File', 'Image File', 'Signature']
  const [showDeleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState(null)

  useEffect(() => {
    if (fieldError && fieldError.length > 3) {
      const fieldIndex = Number(fieldError[3])
      const fieldId =
        (values.sections[index].fields[fieldIndex] && values.sections[index].fields[fieldIndex].id) || fieldIndex
      if (fieldId) {
        scrolltoId(fieldId)
      }
    }
  }, [fieldError])

  const selectIcon = (field) => {
    switch (field.type) {
      case 'boolean':
        return BooleanIcon
      case 'date':
        return DateIcon
      case 'string':
        return MultiChoiceIcon
      case 'textarea':
        return TextIcon
      case 'imgfile':
      case 'genfile':
        return FileUploadIcon
      case 'sigfile':
        return SignedIcon
    }
  }

  const getPosition = () => {
    const position = values.sections[index].fields.length + 1
    return position
  }

  const handleInput = (value) => {
    const newField = {
      position: 0,
      type: '',
      label: '',
      description: '',
      options: []
    }
    switch (value) {
      case 'True/False':
        newField.type = 'boolean'
        break
      case 'Multiple Choice':
        newField.type = 'string'
        newField.options = [
          { value: '', sentiment: '0' },
          { value: '', sentiment: '0' },
          { value: '', sentiment: '0' }
        ]
        break
      case 'Date':
        newField.type = 'date'
        break
      case 'Text':
        newField.type = 'textarea'
        break
      case 'General File':
        newField.type = 'genfile'
        break
      case 'Image File':
        newField.type = 'imgfile'
        break
      case 'Signature':
        newField.type = 'sigfile'
        break
    }
    newField.position = getPosition()
    fieldArrayHelpers.push(newField)
    const newIndex = values.sections[index].fields.length
    const id = values.sections[index].label + '-' + newIndex.toString()
    scrolltoId(id)
  }

  const deleteField = () => {
    setTimeout(() => {
      fieldArrayHelpers.remove(deleteIndex)
    }, 1000)

    setDeleteConfirm(false)
    setDeleteIndex(null)
  }

  const scrolltoId = (id: string) => {
    let access
    setTimeout(() => {
      if (id !== null) {
        access = document.getElementById(id)
        if (access) {
          access.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, 1500)
  }

  const handleDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    reorder(source.index, destination.index)
  }

  const reorder = (startIndex, endIndex) => {
    fieldArrayHelpers.move(startIndex, endIndex)
  }

  const handleFieldAction = (value) => {
    let section
    switch (value.action) {
      case 'Clone':
        const newField = {
          position: getPosition(),
          type: values.sections[index].fields[value.index].type,
          id: '',
          description: values.sections[index].fields[value.index].description,
          label: values.sections[index].fields[value.index].label,
          options: values.sections[index].fields[value.index].options || []
        }
        fieldArrayHelpers.push(newField)
        const newIndex = values.sections[index].fields.length
        const id = values.sections[index].label + '-' + newIndex.toString()
        scrolltoId(id)
        break
      case 'Delete Question':
        setDeleteIndex(value.index)
        setDeleteConfirm(true)
        break
      case 'Move to':
        setDeleteIndex(value.index)
        section = values.sections.findIndex((section) => section.label === value.location)
        moveFieldToSection(value.index, section, 'move')
        break
      case 'Copy to':
        section = values.sections.findIndex((section) => section.label === value.location)
        moveFieldToSection(value.index, section)
        break
    }
  }

  const moveFieldToSection = (fieldIndex, sectionIndex, move?) => {
    const field = values.sections[index].fields[fieldIndex]
    field.id = ''
    const newFields = [...values.sections[sectionIndex].fields, field]

    setFieldValue(`sections.${sectionIndex}.fields`, newFields)
    if (move) {
      deleteField()
    }
  }

  return (
    <div>
      <DragAndDrop onDragEnd={handleDragEnd}>
        <Drop id={'fields'} type={'fields'}>
          {values.sections[index].fields &&
            values.sections[index].fields.length > 0 &&
            values.sections[index].fields.map((field, fieldIndex) => {
              return (
                <>
                  <Drag
                    key={(field.id !== '0' && field.id) || values.sections[index].label + '-' + fieldIndex.toString()}
                    id={(field.id !== '0' && field.id) || values.sections[index].label + '-' + fieldIndex.toString()}
                    index={fieldIndex}
                    type="fields">
                    <Accordions
                      sectionData={{
                        label: field.label || 'QUESTION',
                        id: field.id,
                        index: fieldIndex,
                        type: 'fields',
                        sectionNames: values.sections,
                        currentSection: index
                      }}
                      Icon={selectIcon(field)}
                      key={field.id || fieldIndex}
                      expandOnLoad={!field.id || (fieldError && fieldError[3] == fieldIndex)}
                      onValueChange={handleFieldAction}>
                      <div
                        key={
                          (field.id !== '0' && field.id) || values.sections[index].label + '-' + fieldIndex.toString()
                        }
                        id={
                          (field.id !== '0' && field.id) || values.sections[index].label + '-' + fieldIndex.toString()
                        }>
                        <div
                          style={{
                            position: 'sticky',
                            top: '50px',
                            background: 'white',
                            zIndex: 999,
                            paddingTop: '16px',
                            paddingBottom: '16px'
                          }}>
                          <FormFieldText
                            name={`sections[${index}].fields[${fieldIndex}].label`}
                            label="Question"
                            placeholder={'Add Question Here'}
                            data-testid={`sections-${index}-fields-${fieldIndex}-label`}
                          />
                        </div>
                        <FormFieldText
                          name={`sections[${index}].fields[${fieldIndex}].description`}
                          label="Question Description"
                          placeholder={'Add Description Here'}
                          data-testid={`sections-${index}-fields-${fieldIndex}-description`}
                        />
                        <FieldArray name={`sections[${index}].fields[${fieldIndex}].options`}>
                          {(arrayHelpers) => {
                            return (
                              <>
                                <EditOption
                                  index={index}
                                  fieldIndex={fieldIndex}
                                  optionArrayHelpers={arrayHelpers}></EditOption>
                              </>
                            )
                          }}
                        </FieldArray>
                      </div>
                      <div id="bottom"></div>
                    </Accordions>
                  </Drag>
                </>
              )
            })}
        </Drop>
      </DragAndDrop>
      {showDeleteConfirm && (
        <ConfirmDialog type={'field'} onCancel={() => setDeleteConfirm(false)} onConfirm={deleteField}></ConfirmDialog>
      )}
      <LongMenu options={allFields} valueChangeCallback={handleInput} mode="button" text="Add New Question"></LongMenu>
    </div>
  )
}

export default EditField
