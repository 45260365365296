import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ButtonBase } from '@mui/material'
import { ChildrenProps } from '../types'

type Props = ChildrenProps & {
  options
  valueChangeCallback
  mode?
  text?
  sx?
  disabled?: boolean
}

const ITEM_HEIGHT = 48

export default function LongMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const { options, mode, text } = props
  const styles: any = {
    paddingLeft: 20
  }

  return (
    <div>
      {mode === 'ellipsis' && (
        <div style={props.sx || styles}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            disabled={props.disabled}>
            <MoreVertIcon fontSize="large" />
          </IconButton>
        </div>
      )}
      {mode === 'button' && (
        <ButtonBase
          data-testid="save_changes"
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            fontSize: '14px',
            color: '#fff',
            backgroundColor: '#007aff',
            padding: '6px 16px',
            borderRadius: '4px',
            lineHeight: '1.75'
          }}>
          {text}
        </ButtonBase>
      )}
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: '20ch'
          }
        }}>
        {options.map((option) => (
          <MenuItem
            key={option}
            sx={{ fontSize: '14px' }}
            onClick={() => {
              handleClose()
              props.valueChangeCallback(option)
              handleClose()
            }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      {props.children}
    </div>
  )
}
