import React, { useEffect } from 'react'
import { Frequency } from 'src/types'
import { useApiPatch } from '@papertrail/web3-utils'
import {
  ButtonPrimary,
  ButtonSecondary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  frequency: Frequency
  onCancel: () => void
  onComplete: () => void
}
export default function SetDefaultDialogue(props: Props) {
  const [defaultState, updateDefaultFrequency] = useApiPatch(
    `/accounts/${props.accountid}/frequencies/${props.frequency && props.frequency.id}`,
    (data) => data
  )
  useEffect(() => {
    if (defaultState.isLoaded) {
      props.onComplete()
    }
  }, [defaultState])

  const setDefault = () => {
    const payload = {
      // ...props.frequency,
      id: props.frequency.id,
      default: true
    }

    updateDefaultFrequency(payload)
  }

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-dialog">{'Set Default Frequency'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to set this as the default frequency?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={() => props.onCancel()}>Cancel</ButtonSecondary>
        <ButtonPrimary
          data-testid="confirm_default"
          onClick={() => setDefault()}
          isSubmit
          loading={defaultState.isLoading}>
          Yes, set default frequency
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}
