import React, { Suspense, useEffect } from 'react'
import './App.css'
import { BrowserRouter, Route, Switch, useHistory, useParams } from 'react-router-dom'
import TagsManager from './routes/tags/TagsManager'
import FilterManager from './routes/filters/FilterManager'
import { Loading, theme, ThemeProvider, ContentWrapper, ContentHeader, Box } from '@papertrail/styleguide'
import { I18nextProvider } from 'react-i18next'
import { useSessionMember } from '@papertrail/web3-session'
import ChecklistEditor from './routes/checklist-editor/ChecklistEditor'
import ContactsManager from './routes/contact/ContactsManager'
import FrequencyManager from './routes/frequencies/FrequencyManager'
import StateManager from './routes/state/StateManager'

type Params = {
  accountid: string
}

function Routes() {
  const { accountid } = useParams() as Params
  const history = useHistory()

  const member = useSessionMember()

  useEffect(() => {
    if (member && !member.isAdmin) {
      history.replace(`/accounts/${accountid}`)
    }
  }, [member])

  if (member)
    return (
      <Switch>
        <Route path="/accounts/:accountid/tools/tags" component={TagsManager} />
        <Route path="/accounts/:accountid/tools/checklist-editor" component={ChecklistEditor} />
        <Route path="/accounts/:accountid/tools/filters" component={FilterManager} />
        <Route path="/accounts/:accountid/tools/contact" component={ContactsManager} />
        <Route path="/accounts/:accountid/tools/frequencies" component={FrequencyManager} />
        <Route path="/accounts/:accountid/tools/state" component={StateManager} />
      </Switch>
    )
  else {
    return (
      <ContentWrapper>
        <ContentHeader></ContentHeader>
        <Box height={'100%'} display={'flex'} alignItems={'center'}>
          <Loading />
        </Box>
      </ContentWrapper>
    )
  }
}

type Props = {
  i18n: any
}

export default function Root(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={props.i18n}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Route path="/accounts/:accountid" component={Routes} />
          </Suspense>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  )
}
