import React from 'react'
import { Box } from '@mui/material'
import { ChildrenProps } from '../types'

type Props = ChildrenProps & {
  justifyContent: string
  alignItems?: string
  padding?: number
  onClick?: (any) => void
  sx?: any
}

export function FlexRow(props: Props) {
  const { justifyContent, alignItems = 'center', ...rest } = props

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={justifyContent} alignItems={alignItems} {...rest}>
      {props.children}
    </Box>
  )
}
