import React, { useEffect, useState } from 'react'
import { Frequency } from 'src/types'
import { useApiDelete } from '@papertrail/web3-utils'
import {
  AlertWarning,
  ButtonSecondary,
  ButtonDanger,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CloseIcon,
  FlexRow,
  IconButton
} from '@papertrail/styleguide'

type Props = {
  show: boolean
  accountid: string
  frequency: Frequency
  onCancel: () => void
  onComplete: () => void
}
export default function DeleteDialogue(props: Props) {
  const [deleteFrequency, getDeletefrequency] = useApiDelete<any>(
    `/accounts/${props.accountid}/frequencies/${props.frequency && props.frequency.id}`,
    (data) => data
  )
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (deleteFrequency.isLoaded) {
      props.onComplete()
    }
  }, [deleteFrequency])

  useEffect(() => {
    if (deleteFrequency.isError) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }, [deleteFrequency])

  const closeDialog = () => {
    props.onCancel()
    setIsError(false)
  }

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <FlexRow justifyContent="space-between">
        <DialogTitle id="alert-dialog-title">{'Delete Frequency'}</DialogTitle>
        <IconButton id="basic-button" size="large" onClick={() => props.onCancel()}>
          <CloseIcon />
        </IconButton>
      </FlexRow>
      <DialogContent>
        {isError && (
          <AlertWarning>
            {deleteFrequency.error && deleteFrequency.error.errorCode === 'frequency_is_in_use'
              ? 'You cannot delete a frequency that is used on a record.'
              : 'Sorry, there was an error deleting the frequency.'}
          </AlertWarning>
        )}
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this frequency?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={() => closeDialog()}>Cancel</ButtonSecondary>
        <ButtonDanger
          data-testid="confirm_delete"
          onClick={() => getDeletefrequency({})}
          isSubmit
          loading={deleteFrequency.isLoading}>
          Yes, Delete
        </ButtonDanger>
      </DialogActions>
    </Dialog>
  )
}
