import React, { useEffect } from 'react'
import {
  AlertWarning,
  Box,
  ButtonPrimary,
  ButtonSecondary,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FlexRow,
  Form,
  FormFieldCheckbox,
  FormFieldSelect,
  FormFieldText,
  Formik,
  IconButton,
  ToolTip
} from '@papertrail/styleguide'
import { State } from '../../types'
import { useApiPatch } from '@papertrail/web3-utils'
import * as yup from 'yup'

type Props = {
  show: boolean
  accountid: string
  state: State
  onCancel: () => void
  onComplete: () => void
}
export default function EditDialogue(props: Props) {
  const [updateState, editState] = useApiPatch<any>(
    `/accounts/${props.accountid}/states/${props.state.id}`,
    (data) => data
  )
  useEffect(() => {
    if (updateState.isLoaded) {
      props.onComplete()
    }
  }, [updateState])

  const validationSchema = yup.object({
    name: yup.string().required('State name is required'),
    profile: yup.string().notOneOf(['null'], 'State icon is required'),
    colour: yup.string().notOneOf(['null'], 'Colour is required')
  })

  const initialValues = {
    name: props.state.name,
    profile: props.state.profile,
    colour: props.state.colour,
    is_positive: props.state.isPositive,
    should_archive: props.state.shouldArchive
  }

  function submitForm(values) {
    editState({
      name: values.name,
      profile: values.profile,
      colour: values.colour,
      is_positive: values.is_positive,
      should_archive: values.should_archive
    })
  }

  return (
    <Dialog open={props.show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
        <Form>
          <FlexRow justifyContent="space-between">
            <DialogTitle id="alert-dialog-title">{'Edit State'}</DialogTitle>
            <IconButton id="basic-button" size="large" onClick={() => props.onCancel()}>
              <CloseIcon />
            </IconButton>
          </FlexRow>
          <DialogContent sx={{ width: '500px' }}>
            {updateState.isError && props.state.default === true && (
              <AlertWarning>You cannot set a default state to archive</AlertWarning>
            )}
            {updateState.isError && props.state.default !== true && (
              <AlertWarning>Sorry, there was an error editing the state.</AlertWarning>
            )}
            <DialogContentText id="alert-dialog-description">
              <FormFieldText label="State name" placeholder="State name" name="name" />
              <FormFieldSelect
                options={[
                  { value: 'good', label: 'Tick' },
                  { value: 'monitor', label: 'Magnifying glass' },
                  { value: 'quarantine', label: 'Lock' },
                  { value: 'missing', label: 'Question Mark' },
                  { value: 'retired', label: 'Bin - Archive' }
                ]}
                name={'profile'}
                label={'State Icon'}
                placeholder={'Please select the icon'}
                mandatory={true}
              />
              <FormFieldSelect
                options={[
                  { value: '24,166,120', label: 'Green' },
                  { value: '240,173,78', label: 'Yellow' },
                  { value: '235,55,69', label: 'Red' }
                ]}
                name={'colour'}
                label={'State colour'}
                placeholder={'Please select the colour'}
                mandatory={true}
              />
              <Box display={'flex'} align-items={'left'}>
                <ToolTip
                  title={
                    "Indicates if the state is positive or negative. For example 'Checked - good' is a positive state"
                  }>
                  <span>
                    <FormFieldCheckbox name={'is_positive'} label={'Is positive?'} />
                  </span>
                </ToolTip>
              </Box>
              <Box display={'flex'} align-items={'left'}>
                <ToolTip
                  title={
                    'Records given this state will be archived and hidden from view. They will no longer be flagged for inspection'
                  }>
                  <span>
                    <FormFieldCheckbox name={'should_archive'} label={'Will archive'} />
                  </span>
                </ToolTip>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonSecondary onClick={() => props.onCancel()}>Cancel</ButtonSecondary>
            <ButtonPrimary data-testid="save_changes" isSubmit loading={updateState.isLoading}>
              Save changes
            </ButtonPrimary>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}
